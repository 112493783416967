import React, { useEffect, useState } from "react";
import "./index.css";
import { getProjectById } from "../../config/config";
import axiosHelper from "../../helpers/axios-helper.js";
import { getHours } from "@mui/lab/ClockPicker/shared";
import moment from "moment";
const ProjectInfo = ({ id }) => {
  const [tableData, setTableData] = useState(null);
  React.useEffect(() => {
    async function fetchProject() {
      const result = await axiosHelper(
        getProjectById,
        "POST",
        null,
        JSON.stringify({ id: id })
      );
      setTableData(result.data);
    }
    fetchProject();
  }, []);

  const roundOneDay = parseInt(
    (new Date(tableData?.data.idoDates.roundOneEndDate) -
      new Date(tableData?.data.idoDates.roundOneStartDate)) /
      (1000 * 60 * 60 * 24)
  );
  const roundOneHours = parseInt(
    (Math.abs(
      new Date(tableData?.data.idoDates.roundOneEndDate) -
        new Date(tableData?.data.idoDates.roundOneStartDate)
    ) /
      (1000 * 60 * 60)) %
      24
  );
  const roundOneMinutes = Math.round(
    (((new Date(tableData?.data.idoDates.roundOneEndDate) -
      new Date(tableData?.data.idoDates.roundOneStartDate)) %
      86400000) %
      3600000) /
      60000
  );
  const roundTwoDay = parseInt(
    (new Date(tableData?.data.idoDates.roundTwoEndDate) -
      new Date(tableData?.data.idoDates.roundTwoStartDate)) /
      (1000 * 60 * 60 * 24)
  );
  const roundTwoHours = parseInt(
    (Math.abs(
      new Date(tableData?.data.idoDates.roundTwoEndDate) -
        new Date(tableData?.data.idoDates.roundTwoStartDate)
    ) /
      (1000 * 60 * 60)) %
      24
  );
  const roundTwoMinutes = Math.round(
    (((new Date(tableData?.data.idoDates.roundTwoEndDate) -
      new Date(tableData?.data.idoDates.roundTwoStartDate)) %
      86400000) %
      3600000) /
      60000
  );
  return (
    <>
      <h4 className="text-center m-3">
        {`
        ${tableData && tableData.data.projectName}
        (${tableData && tableData.data.typeOfProject})`}
      </h4>
      <div class="card mt-3 mb-3">
        <div class="card-body">
          <div className="row">
            <div className="col-6">
              {/* <p>
              Project Name :
              <span>{tableData && tableData.data.projectName}</span>
            </p> */}
              <p>
                Project Description :
                <span>{tableData && tableData.data.projectDescription}</span>
              </p>
              <p>
                Status :<span>{tableData && tableData.data.projectStatus}</span>
              </p>
              {/* <p>
              Project Type :
              <span>{tableData && tableData.data.typeOfProject}</span>
            </p> */}
              <p>
                Project Id:<span>{tableData && tableData.data._id}</span>
              </p>
              <p>
                Token Name :
                <span>
                  {tableData && tableData.data.tokenDetails.tokenName}
                </span>
              </p>
              <p>
                Token Symbol :
                <span>
                  {tableData && tableData.data.tokenDetails.tokenSymbol}
                </span>
              </p>
              <p>
                Token Sale Date :
                <span>
                  {moment(
                    tableData && tableData.data.tokenDetails.tokenSaleDate
                  ).format(`YYYY-MM-DD HH:mm`)}
                  (IST)
                </span>
              </p>
              <p>
                Token Distribution Date :
                <span>
                  {moment(
                    tableData &&
                      tableData.data.tokenDetails.tokenDistributionDate
                  ).format(`YYYY-MM-DD HH:mm`)}
                  (IST)
                </span>
              </p>
              <p>
                Rate :
                <span>{tableData && tableData.data.tokenDetails.rate}</span>
              </p>

              <p>
                Initial Market Cap :
                <span>
                  {tableData && tableData.data.tokenDetails.initialMarketCap}
                </span>
              </p>
            </div>
            <div className="col-6">
              <p>
                Round 1 Time :{" "}
                <b>
                  {tableData &&
                    `${roundOneDay} Days :${roundOneHours} Hrs :${roundOneMinutes} mins`}
                </b>
              </p>
              <p>
                Round 2 Time :{" "}
                <b>
                  {tableData &&
                    `${roundTwoDay} Days :${roundTwoHours} Hrs :${roundTwoMinutes} Mins`}
                </b>
              </p>
              <p>
                Whitelist Start Date :
                <span>
                  {moment(
                    tableData && tableData.data.idoDates.whitelistStartDate
                  ).format(`YYYY-MM-DD HH:mm`)}
                  (IST)
                </span>
              </p>
              <p>
                Whitelist End Date :
                <span>
                  {moment(
                    tableData && tableData.data.idoDates.whitelistEndDate
                  ).format(`YYYY-MM-DD HH:mm`)}
                  (IST)
                </span>
              </p>
              <p>
                Supported Token :
                <span>{tableData && tableData.data.supportedToken}</span>
              </p>
              <p>
                Total Fundraise :
                <span>
                  {tableData && tableData.data.tokenDetails.totalFundraise}
                </span>
              </p>
              <p>
                Token Address :
                <span>
                  {tableData &&
                    tableData.data.tokenDetails.tokenContract.tokenAddress}
                </span>
              </p>
              <p>
                Token Contract Link :
                <span>
                  {tableData &&
                    tableData.data.tokenDetails.tokenContract.tokenContractLink}
                </span>
              </p>
              {/* <p>Approved :<span>{tableData.data.isApproved}</span></p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectInfo;
