import React, { useState, useEffect } from 'react';
import moment from 'moment';
import logo from '../../assets/images/logo.png';
import './index.css';
import axiosHelper from '../../helpers/axios-helper';
import { upload } from '../../config/config';
const FourPage = ({
  data,
  update,
  change,
  typeOfProject,
  projectLogoImageRef,
  networkImageRef,
  projectBannerImageRef,
  formatDates,
  id,
  isEditProject,
  // projectMediaURLRef,
  mediaNameData,
}) => {
  const [img, setImg] = useState(null);
  const [projectLogoImage, setProjectLogoImage] = useState(null);
  const [projectBannerImage, setProjectBannerImage] = useState(null);
  const [uploadData, setUploadData] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [mimetypeData, setmimetypeData] = useState();
  const [disabled, setDisable] = useState(false);
  console.log(data.noOfTier);
  const [mediaUploaded, setMediaUploaded] = useState('');
  const [uploadDataResponse, setUploadDataResponse] = useState('');
  const [name, setName] = useState('');

  let date = new Date();
  let minuts = moment(date).format(`YYYY-MM-DDTHH:mm:ss`);

  useEffect(() => {
    setProjectLogoImage(data?.projectLogoUrl);
    setProjectBannerImage(data?.projectBannerUrl);

    if (mediaNameData?.media?.length > 0) {
      setUploadDataResponse(mediaNameData?.media[0]?.url);
    } else {
      setUploadDataResponse('');
    }
  }, [data, mediaUploaded]);

  const imagePreviewLogo = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setProjectLogoImage(URL.createObjectURL(file));
  };

  const imagePreviewBanner = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setProjectBannerImage(URL.createObjectURL(file));
  };

  function uploadDataFn() {
    if (uploadData !== undefined) {
      update('finance', 'mediaURL', uploadData);
    }
  }
  useEffect(() => {
    uploadDataFn();
  }, [uploadData]);

  // const mimetypeArray = [
  //   'image/png',
  //   'image/jpeg',
  //   'image/jpg',
  //   'image/gif',
  //   'video',
  // ];

  function mimetypeFn() {
    if (mimetypeData !== undefined) {
      update('finance', 'mediaType', mimetypeData);
    }
    // console.log(mimetypeData);
  }
  useEffect(() => {
    mimetypeFn();
  }, [mimetypeData]);

  async function uploadFunction(file, Inputname) {
    setDisable(true);
    setName(Inputname);
    const formData = new FormData();
    formData.append('file', file);
    // console.log(formData);
    const result = await axiosHelper(upload, 'POST', formData);
    setName('');

    if (result.status === 200) {
      setMediaUploaded('Media Uploaded');
      setUploadDataResponse('');
    }

    setUploadData(result?.data?.data?.location);
    setDisable(false);

    // console.log(result);
    // console.log(mimetypeData);
    // console.log(result?.data?.data?.mimetype);

    if (result?.data?.data?.mimetype.includes('image/gif')) {
      setmimetypeData('GIF');
      return;
    } else if (
      result?.data?.data?.mimetype.includes('image/png') ||
      result?.data?.data?.mimetype.includes('image/jpeg') ||
      result?.data?.data?.mimetype.includes('image/jpg')
    ) {
      setmimetypeData('IMAGE');
      return;
    } else if (result?.data?.data?.mimetype.includes('audio')) {
      setmimetypeData('AUDIO');
      return;
    } else if (result?.data?.data?.mimetype.includes('video')) {
      setmimetypeData('VIDEO');
      return;
    }
    setmimetypeData(result?.data?.data?.mimetype);
  }

  const handelFileInput = (event) => {
    // console.log(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
  };
  useEffect(() => {
    if (typeOfProject === 'Community IDO') {
      update('finance', 'noOfTier', 1);
    } else {
      update('finance', 'noOfTier', data.noOfTier);
    }
  }, [typeOfProject]);

  const divStyles = {
    display: 'flex',
    margin: '0px',
  };
  const pStyles = {
    margin: '0px 0 0 10px',
  };

  return (
    <>
      <div className='mb-2 row'>
        <div className='col-6 '>
          <label htmlFor='whitelist-start-date' className='col-form-label'>
            Whitelist Start Date (IST) *:
          </label>
          <input
            required
            type='datetime-local'
            className='form-control'
            id='whitelist-start-date'
            name='whitelistStartDate'
            value={data.whitelistStartDate}
            onChange={(e) =>
              update('finance', 'whitelistStartDate', e.target.value)
            }
          />
          {!isEditProject && minuts > data.whitelistStartDate ? (
            <p className='validateField'>{`Should be greater then ${minuts} `}</p>
          ) : null}
          {data.whitelistStartDate === '' ? (
            <p className='validateField'>field not be empty</p>
          ) : null}
        </div>
        <div className='col-6'>
          <label htmlFor='whitelist-end-date' className='col-form-label'>
            Whitelist End Date (IST) *:
          </label>
          <input
            required
            type='datetime-local'
            className='form-control'
            id='whitelist-end-date'
            name='whitelistEndDate'
            value={data.whitelistEndDate}
            onChange={(e) =>
              update('finance', 'whitelistEndDate', e.target.value)
            }
          />
          {data.whitelistStartDate > data.whitelistEndDate ? (
            <p className='validateField'>{`Should be greater then ${data.whitelistStartDate}`}</p>
          ) : null}
          {data.whitelistEndDate === '' ? (
            <p className='validateField'>field not be empty</p>
          ) : null}
        </div>
      </div>

      {/*
      <div className="mb-2 row">
        <div className="col-6">
          <label htmlFor="token-sale-date" className="col-form-label">
            Token Sale Date (IST) *:
          </label>
          <input
            type="datetime-local"
            required
            className="form-control"
            id="token-sale-date"
            name="tokenSaleDate"
            value={data.tokenSaleDate}
            onChange={(e) => update("finance", "tokenSaleDate", e.target.value)}
          />
          {data.whitelistEndDate > data.tokenSaleDate ? (
            <p className="validateField">{`Should be greater then ${data.whitelistStartDate}`}</p>
          ) : null}
          {data.tokenSaleDate === "" ? (
            <p className="validateField">field not be empty</p>
          ) : null}
        </div>
        <div className="col-6">
          <label htmlFor="token-distribution-date" className="col-form-label">
            Token Distribution Date (IST) :
          </label>
          <input
            type="datetime-local"
            required
            className="form-control"
            id="token-distribution-date"
            name="tokenDistributionDate"
            value={data.tokenDistributionDate}
            onChange={(e) =>
              update("finance", "tokenDistributionDate", e.target.value)
            }
          />

          {data.tokenDistributionDate === "" ? (
            <p className="nonRequired">
              Date less than Round Two End. Shows TBA
            </p>
          ) : null}
        </div>
      </div>
      */}

      <div className='mb-2 row'>
        <div className='col-6'>
          <label htmlFor='round-one-start-date' className='col-form-label'>
            Round One Start Date (IST) *:
          </label>
          <input
            required
            type='datetime-local'
            className='form-control'
            id='round-one-start-date'
            name='roundOneStartDate'
            value={data.roundOneStartDate}
            onChange={(e) =>
              update('finance', 'roundOneStartDate', e.target.value)
            }
          />
          {data.tokenSaleDate > data.roundOneStartDate ? (
            <p className='validateField'>{`Should be greater then ${data.tokenSaleDate}`}</p>
          ) : null}
          {data.roundOneStartDate === '' ? (
            <p className='validateField'>field not be empty</p>
          ) : null}
        </div>
        <div className='col-6'>
          <label htmlFor='round-one-end-date' className='col-form-label'>
            Round One End Date (IST) *:
          </label>
          <input
            required
            type='datetime-local'
            className='form-control'
            id='round-one-end-date'
            name='roundOneEndDate'
            value={data.roundOneEndDate}
            onChange={(e) =>
              update('finance', 'roundOneEndDate', e.target.value)
            }
          />
          {data.roundOneStartDate > data.roundOneEndDate ? (
            <p className='validateField'>{`Should be greater then ${data.roundOneStartDate}`}</p>
          ) : null}
          {data.roundOneEndDate === '' ? (
            <p className='validateField'>field not be empty</p>
          ) : null}
        </div>
      </div>
      <div className='mb-2 row'>
        <div className='col-6'>
          <label htmlFor='round-two-start-date' className='col-form-label'>
            Round Two Start Date (IST) *:
          </label>
          <input
            required
            type='datetime-local'
            className='form-control'
            id='round-two-start-date'
            name='roundTwoStartDate'
            value={data.roundTwoStartDate}
            onChange={(e) =>
              update('finance', 'roundTwoStartDate', e.target.value)
            }
          />
          {data.roundOneEndDate > data.roundTwoStartDate ? (
            <p className='validateField'>{`Should be greater then ${data.roundOneEndDate}`}</p>
          ) : null}
          {data.roundTwoStartDate === '' ? (
            <p className='validateField'>field not be empty</p>
          ) : null}
        </div>
        <div className='col-6'>
          <label htmlFor='round-two-end-date' className='col-form-label'>
            Round Two End Date (IST) *:
          </label>
          <input
            required
            type='datetime-local'
            className='form-control'
            id='round-two-end-date'
            name='roundTwoEndDate'
            value={data.roundTwoEndDate}
            onChange={(e) =>
              update('finance', 'roundTwoEndDate', e.target.value)
            }
          />
          {data.roundTwoStartDate > data.roundTwoEndDate ? (
            <p className='validateField'>{`Should be greater then ${data.roundTwoStartDate}`}</p>
          ) : null}
          {data.roundTwoEndDate === '' ? (
            <p className='validateField'>field not be empty</p>
          ) : null}
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <label htmlFor='token-distribution-date' className='col-form-label'>
            Token Distribution Date (IST) :
          </label>
          <input
            type='datetime-local'
            required
            className='form-control'
            id='token-distribution-date'
            name='tokenDistributionDate'
            value={data.tokenDistributionDate}
            onChange={(e) =>
              update('finance', 'tokenDistributionDate', e.target.value)
            }
          />

          {data.tokenDistributionDate === '' ? (
            <p className='nonRequired'>
              Date less than Round Two End. Shows TBA
            </p>
          ) : null}
        </div>
        <div className='col'>
          <label htmlFor='projectType' className='col-form-label'>
            No Of Tier:
          </label>
          <select
            className='form-select'
            required
            defaultValue='Community IDO'
            id='projectType'
            name='noOfTier'
            value={data.noOfTier}
            onChange={(e) => update('finance', 'noOfTier', e.target.value)}
          >
            {typeOfProject === 'Community IDO' ? (
              <option value={1} selected>
                Community
              </option>
            ) : (
              <>
                <option value=''>Select No Of Tier</option>
                <option value={1}>Tier 1</option>
                <option value={2}>Tier 2</option>
                <option value={3}>Tier 3</option>
                <option value={4}>Tier 4</option>
                <option value={5}>Tier 5</option>
                <option value={6}>Tier 6</option>
                <option value={7}>Tier 7</option>
              </>
            )}
          </select>
          {data.typeOfProject === '' ? (
            <p className='validateField'>Please provide a valid data</p>
          ) : null}
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          {/* <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value={data.tba}
              onChange={() => update("finance", "tba", !data.tba)}
              id="flexCheckDefault"
            />
          </div> */}
          <div class='form-check form-switch'>
            <label
              class='form-check-label tbaLable'
              for='flexSwitchCheckDefault'
            >
              TBA
            </label>
            <input
              class='form-check-input btn-lg'
              type='checkbox'
              value={data.tba}
              defaultChecked={data.tba}
              onChange={() => update('finance', 'tba', !data.tba)}
              id='flexSwitchCheckDefault'
            />
          </div>
          <p className='nonRequired tbalable'>
            Turned on: Displays TBA for Sale Date and Timer
          </p>
        </div>
      </div>

      {!isEditProject && (
        <div className='mb-2 row'>
          {/* <div className="col">
            <label htmlFor="network-image" className="col-form-label">
              Network Image *:
            </label>

            <input
              type="file"
              required
              accept="image/*"
              ref={networkImageRef}
              className="form-control form-control-sm"
              id="network-image"
              // onClick={() => {
              // networkImageRef.current.click()
              //
            />
          </div> */}
          <div className='col'>
            <label htmlFor='project-logo-image' className='col-form-label'>
              Project Logo Image *:
            </label>

            <input
              type='file'
              required
              ref={projectLogoImageRef}
              className='form-control form-control-sm'
              id='project-logo-image'
              accept='image/*'
            />
          </div>
          <div className='col'>
            <label htmlFor='project-banner-image' className='col-form-label'>
              Project Banner Image *:
            </label>

            <input
              type='file'
              required
              ref={projectBannerImageRef}
              className='form-control form-control-sm'
              id='project-banner-image'
              accept='image/*'

              // onClick={() => {

              //   projectBannerImageRef.current.click();
              //
            />
          </div>
          <div className='col'>
            <label htmlFor='media-URL' className='col-form-label'>
              Project Media :
            </label>

            <input
              type='file'
              // required
              // ref={projectMediaURLRef}
              className='form-control form-control-sm'
              id='media-URL'
              accept='image/*,audio/*,gif/*,video/mp4,video/x-m4v,video/*'
              onChange={(event) => handelFileInput(event)}
            />
            <div style={divStyles}>
              <button
                onClick={() => uploadFunction(selectedFile, 'media-URL')}
                disabled={disabled}
              >
                upload
              </button>
              {name === 'media-URL' && <p style={pStyles}>Uploading</p>}
            </div>
            <div className='media-upload'>
              {mediaUploaded ? (
                <p
                  className='nonRequired'
                  style={{ fontSize: '15px', color: 'blue' }}
                >
                  Media Uploaded
                </p>
              ) : uploadDataResponse !== '' ? (
                <p
                  className='nonRequired'
                  style={{ fontSize: '15px', color: 'blueviolet' }}
                >
                  Media present
                </p>
              ) : (
                <p
                  className='nonRequired'
                  style={{ fontSize: '15px', color: 'red' }}
                >
                  Media Not Present
                </p>
              )}
            </div>
          </div>
          <div className='mb-2 row'>
            <div className='col'>
              <label htmlFor='twitter-link' className='col-form-label'>
                GoogleDocLink:
              </label>
              <input
                type='text'
                required
                className='form-control'
                id='twitter-link'
                name='twitter'
                value={data.GoogleDocLink}
                onChange={(e) =>
                  update('finance', 'GoogleDocLink', e.target.value)
                }
              />
            </div>
          </div>
        </div>
      )}

      {isEditProject && (
        <div className='mb-2 row'>
          <div className='col'>
            <label htmlFor='project-logo-image' className='col-form-label'>
              Project Logo Image :
            </label>

            <input
              type='file'
              ref={projectLogoImageRef}
              className='form-control form-control-sm'
              id='project-logo-image'
              accept='image/*'
              onChange={imagePreviewLogo}
            />
          </div>
          <div className='col'>
            <label htmlFor='project-banner-image' className='col-form-label'>
              Project Banner Image :
            </label>

            <input
              type='file'
              ref={projectBannerImageRef}
              className='form-control form-control-sm'
              id='project-banner-image'
              accept='image/*'
              onChange={imagePreviewBanner}
            />
          </div>

          <div className='col'>
            <label htmlFor='media-URL' className='col-form-label'>
              Project Media :
            </label>

            <input
              type='file'
              // required
              // ref={projectMediaURLRef}
              // value={data.mediaURL}
              className='form-control form-control-sm'
              id='media-URL'
              accept='image/*,audio/*,gif/*,video/mp4,video/x-m4v,video/*'
              onChange={(event) => handelFileInput(event)}
            />
            <div style={divStyles}>
              <button
                onClick={() => uploadFunction(selectedFile, 'media-URL')}
                disabled={disabled}
              >
                upload
              </button>
              {name === 'media-URL' && <p style={pStyles}>Uploading</p>}
            </div>
          </div>

          <div
            className='project-images'
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '10px',
              justifyContent: 'space-between',
            }}
          >
            <div className='project-logo'>
              <img
                src={projectLogoImage}
                alt='Project Logo'
                height='50px'
                width='50px'
              />
            </div>

            <div className='project-banner'>
              <img
                src={projectBannerImage}
                alt='Project Banner'
                height='50px'
                width='50px'
              />
            </div>

            <div className='media-upload'>
              {mediaUploaded ? (
                <p
                  className='nonRequired'
                  style={{ fontSize: '15px', color: 'blue' }}
                >
                  Media Uploaded
                </p>
              ) : uploadDataResponse !== '' ? (
                <p
                  className='nonRequired'
                  style={{ fontSize: '15px', color: 'blueviolet' }}
                >
                  Media present
                </p>
              ) : (
                <p
                  className='nonRequired'
                  style={{ fontSize: '15px', color: 'red' }}
                >
                  Media Not Present
                </p>
              )}
            </div>
          </div>
          <div className='mb-2 row'>
            <div className='col'>
              <label htmlFor='twitter-link' className='col-form-label'>
                GoogleDocLink:
              </label>
              <input
                type='text'
                required
                className='form-control'
                id='twitter-link'
                name='twitter'
                value={data?.GoogleDocLink}
                onChange={(e) =>
                  update('finance', 'GoogleDocLink', e.target.value)
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FourPage;
