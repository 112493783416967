import moment from 'moment';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllClaimById } from '../../config/config';
import axiosHelper from '../../helpers/axios-helper';
import './index.css';
const ViewClaim = ({ heading }) => {
  const { id } = useParams();
  const [claimLaunchIdo, setClaimLaunchIdo] = useState();
  const [newArray, setNewArray] = useState();
  async function fetchData() {
    const result = await axiosHelper(
      `${getAllClaimById}${id}`,
      'GET',
      null,
      JSON.stringify({ id: id })
    );
    const { data } = result.data;
    setClaimLaunchIdo(data[0]);
    console.log(data[0]);
  }
  React.useEffect(() => {
    fetchData();
  }, []);
  let items = claimLaunchIdo?.claimDates.map((id, index) => {
    return {
      dates: claimLaunchIdo?.claimDates[index],
      percentage: claimLaunchIdo?.claimPercentage[index],
    };
  });
  React.useEffect(() => {
    setNewArray(items);
  }, [claimLaunchIdo]);
  console.log(heading);
  return (
    <div>
      {!heading ? (
        <div className='text-center m-3'>
          <h2>
            View Claim({claimLaunchIdo?.projectData[0].projectName}{' '}
            {claimLaunchIdo?.projectData[0].typeOfProject})
          </h2>
        </div>
      ) : null}
      <div className='container-fluid mt-2 view-claim'>
        {newArray?.length ? (
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'> Token Release Date (IST)</th>
                <th scope='col'> Tokens Release ( % )</th>
                <th scope='col'> Token Released</th>
              </tr>
            </thead>
            <tbody>
              {newArray?.map((entry, id) => {
                return (
                  <>
                    <tr key={id + 1}>
                      <td>
                        {moment(parseInt(`${entry.dates}000`)).format(
                          'MMMM Do YYYY, h:mm:ss a'
                        )}
                      </td>
                      <td>{parseInt(entry.percentage / 1000)}</td>
                      <td>
                        {new Date(entry.dates * 1000) > new Date()
                          ? 'false'
                          : 'true'}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
};

export default ViewClaim;
