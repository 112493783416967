import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import countryCode from "./countryList";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({ update, data }) {
  const [country, setCountry] = useState();
  useEffect(() => {
    update("social", "countryRestricted", country);
    console.log(country);
  }, [country]);

  const handleCountry = (e, val) => {
    setCountry(val && val?.map((e) => e.code));
  };

  const res2 = countryCode.filter((array) => {
    for (var i = 0; i < data?.length; i++) {
      if (array.code == data[i]) {
        return data[i];
      }
    }
  });
  return (
    <Autocomplete
      multiple
      limitTags={2}
      id="multiple-limit-tags"
      options={countryCode}
      disableCloseOnSelect
      defaultValue={res2}
      onChange={handleCountry}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Please Provide Restricted Country"
          placeholder="Add More"
        />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const datas = [
  {
    code: "AFG",
    name: "Afghanistan",
  },
  {
    code: "ALA",
    name: "Aland Islands",
  },
  {
    code: "ALB",
    name: "Albania",
  },
];
