import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import axiosHelper from "../../helpers/axios-helper.js";
import {adminUsers, register} from '../../config/config';

const Manage = () => {
  const [tableData, setTableData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
 
  useEffect(() => {
    async function fetchData() {
      let result = await axiosHelper(
        `${adminUsers}?pageNo=1&noOfRecords=100`,
        "GET"
      );
      setTableData(result.data.data);
    }
    fetchData();
    
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const copyContent = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
  };

  console.log(tableData);
  
  return (
    <div className="projects">
      <button type="button" className="btn add-project-button">
        <Link to={`../add-user`}>Add User</Link>
      </button>
      <Paper sx={{ width: "100%" }}>
      {tableData && (
         <TableContainer className="allproject">
          <Table stickyHeader aria-label="sticky table">
          <TableHead>
                <TableRow>
                <TableCell>Edit User</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Email</TableCell>   
                <TableCell>Phone</TableCell>    
                <TableCell>Role</TableCell> 
                <TableCell>status</TableCell> 
                </TableRow>
              </TableHead>
              <TableBody>
              {tableData.map((entry, id) => {
                return ( 
                  <TableRow key={id}>
                    <TableCell><Link to={`/add-user/${entry._id}`}>Edit</Link></TableCell>
                    <TableCell>{entry.name}</TableCell>
                    <TableCell>{entry.email}</TableCell>   
                    <TableCell>{entry.phone}</TableCell>    
                    <TableCell>{entry.role}</TableCell> 
                    <TableCell>{entry.isActive.toString()}</TableCell>
                 </TableRow>
                    ); 
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          //   count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Paper>
    </div>
  );
};

export default Manage;

