import React from "react";
import CheckboxesTags from "./countrySelect";
const SecondPage = ({ data, update, projectType }) => {
  console.log(projectType);
  return (
    <>
      <div className="mb-2 row">
        <div className="col">
          <label htmlFor="twitter-link" className="col-form-label">
            Twitter Link:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="twitter-link"
            name="twitter"
            value={data.twitter}
            onChange={(e) => update("social", "twitter", e.target.value)}
          />
          {data.twitter === "" ? (
            <p className="nonRequired">Please provide a valid data</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="telegram-link" className="col-form-label">
            Telegram Link:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="telegram-link"
            name="telegram"
            value={data.telegram}
            onChange={(e) => update("social", "telegram", e.target.value)}
          />
          {data.telegram === "" ? (
            <p className="nonRequired">Please provide a valid data</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="medium-link" className="col-form-label">
            Medium Link:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="medium-link"
            name="medium"
            value={data.medium}
            onChange={(e) => update("social", "medium", e.target.value)}
          />
          {data.medium === "" ? (
            <p className="nonRequired">Please provide a valid data</p>
          ) : null}
        </div>
      </div>
      <div className="mb-2 row">
        <div className="col">
          <label htmlFor="website-link" className="col-form-label">
            Website Link:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="website-link"
            name="website"
            value={data.website}
            onChange={(e) => update("social", "website", e.target.value)}
          />
          {data.website === "" ? (
            <p className="nonRequired">Please provide a valid data</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="github-link" className="col-form-label">
            Github Link:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="github-link"
            name="github"
            value={data.github}
            onChange={(e) => update("social", "github", e.target.value)}
          />
          {data.github === "" ? (
            <p className="nonRequired">Please provide a valid data</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="onepager-link" className="col-form-label">
            OnePaper Link:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="onepager-link"
            name="onePager"
            value={data.onePager}
            onChange={(e) => update("social", "onePager", e.target.value)}
          />
          {data.onePager === "" ? (
            <p className="nonRequired">Please provide a valid data</p>
          ) : null}
        </div>
      </div>
      <div className="mb-2 row">
        <div className="col">
          <label htmlFor="whitepaper-link" className="col-form-label">
            Whitepaper Link:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="whitepaper-link"
            name="whitePaper"
            value={data.whitePaper}
            onChange={(e) => update("social", "whitePaper", e.target.value)}
          />
          {data.whitePaper === "" ? (
            <p className="nonRequired">Please provide a valid data</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="competition-winner" className="col-form-label">
            {projectType === "Community IDO"
              ? "Gleam Competition Link"
              : "Solana Wallet Form Link"}
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="competition-winner"
            name="competitonWinner"
            value={data.competitonWinner}
            onChange={(e) =>
              update("social", "competitonWinner", e.target.value)
            }
          />
          {data.competitonWinner === "" ? (
            <p className="nonRequired">
              {projectType === "Community IDO"
                ? "Enter Gleam URL"
                : "Format: DisplayText;ButtonName;Link"}
            </p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="country-restrictions" className="col-form-label">
            Country Restrictions *:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="country-restrictions"
            name="country"
            value={data.country}
            onChange={(e) => update("social", "country", e.target.value)}
          />
          {data.country === "" ? (
            <p className="validateField">String displayed in APP UI</p>
          ) : null}
        </div>
      </div>
      <div className="mb-2 row">
        <label htmlFor="country-restrictions" className="col-form-label">
          Country Restriction List:
        </label>
        <CheckboxesTags update={update} data={data.countryRestricted} />
      </div>
    </>
  );
};

export default SecondPage;
