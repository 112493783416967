import React from 'react'
import './index.css';
import { Modal, Button } from "react-bootstrap";
import { stakePool, networkUrl } from "../../config/config";
import axiosHelper from "../../helpers/axios-helper.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function StakePool() {
    const [stakePoolList, setStakePoolList] = React.useState([]);
    const [stakePoolListRaw, setStakePoolListRaw] = React.useState([]);
    const [networkList, setNetworkList] = React.useState([]);
    const [id, setId] = React.useState("");
    const [network, setNetwork] = React.useState("");
    const [tokenOrLp, setTokenOrLp] = React.useState("");
    const [contractAddress, setContractAddress] = React.useState("");
    const [lockingPeriod, setLockingPeriod] = React.useState(0);
    const [show, setShow] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [showDeletePopUp, setShowDeletePopUp] = React.useState(false);
    const [selectedStakePool, setSelectedStakePool] = React.useState({});
    const [selectedValue, setSelectedValue] = React.useState("");
    async function fetchData() {
        const result = await axiosHelper(
            stakePool,
            "GET",
            null,
            null
        );
        const { data } = result.data;
        setStakePoolList(data);
        setStakePoolListRaw(data);
    }
    React.useEffect(() => {
        fetchData();
        fetchNetworkData();
    }, []);
    async function fetchNetworkData() {
        console.log("network ", network);
        const result = await axiosHelper(networkUrl, "GET", null, null);
        const { data } = result.data;
        console.log("data ", data);
        setNetworkList(data);
    }
    const patchValueToForm = (data) => {
        console.log(data)
        setId(data._id)
        setNetwork(data.network);
        setTokenOrLp(data.tokenOrLp);
        setContractAddress(data.contractAddress);
        setLockingPeriod(data.lockingPeriod);
        setShow(true);
        setIsEdit(true);
    }

    const handleClose = () => {
        setShow(false);
        setNetwork("");
        setTokenOrLp("");
        setContractAddress("");
        setLockingPeriod(0);
        setId("");
        setIsEdit(true);
        setShowDeletePopUp(false);
        setSelectedStakePool({});
        setSelectedValue("");
    };
    const handleShow = () => {
        setShow(true);
        setIsEdit(false)
    };

    const callApi = async (url, method, obj) => {
        try {
            const result = await axiosHelper(
                url,
                method,
                null,
                obj
            );
            handleClose();
            toast.success(`Stake Pool successfully ${isEdit ? 'updated' : 'added'} ! `, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            fetchData()
        } catch (error) {
            handleClose();
            toast.error("Something went wrong!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }
    }
    const upsertEntry = async () => {
        console.log("network ", network)
        console.log("lockingPeriod ", lockingPeriod)
        console.log("contractAddress ", contractAddress)
        if (!network.length || !tokenOrLp.length || !contractAddress.length) {
            // alert('please fill all mandatory fields...');
            toast.success('Please fill all mandatory fields...', {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        let obj = {
            network: network?.toUpperCase(), lockingPeriod, tokenOrLp, contractAddress
        }
        if (isEdit) {
            await callApi(stakePool + '/' + id, "PUT", obj)
        } else {
            await callApi(stakePool, "POST", obj)


        }
    }
    const deleteEntry = async () => {
        try {
            const result = await axiosHelper(
                stakePool + '/' + selectedStakePool._id,
                'DELETE',
                null,
                null
            );
            handleClose();
            toast.success(`Stake Pool successfully delete! `, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            fetchData();
        } catch (error) {
            handleClose();
            toast.error("Something went wrong!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }
    }
    const showDeleteEntryPopup = async (stakePoolDate) => {
        setShowDeletePopUp(true);
        setSelectedStakePool(stakePoolDate);
    }
    const searchFromList = (searchStr) => {
        const arr = [];
        for (let index = 0; index < stakePoolListRaw.length; index++) {
            if (JSON.stringify(stakePoolListRaw[index]).toLocaleLowerCase().includes(searchStr.toLocaleLowerCase())) {
                arr.push(stakePoolListRaw[index])
            }
        }
        setStakePoolList(arr);
    }
    return (
        <>
            <ToastContainer />

            <div className='container-fluid'>
                <div className='row align-items-center shadow-sm p-3 bg-light rounded' >
                    <div className='col-8 bold'>Manage Stake Pool</div>
                    <div className='col-4 text-end'>
                        <div className="row align-items-center  text-end">

                            <div className="col align-items-center text-end">
                                <input type="text" className="form-control" id="search" placeholder="type to search" onChange={e => { searchFromList(e.target.value) }} />
                            </div>
                            <div className="col-auto  text-end">
                                <button className="btn btn-primary" onClick={handleShow}>Add Stake Pool</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Update' : 'Add'} Stake Pool</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className="col mb-3">
                            <label htmlFor="tokenOrLp" className="form-label">Token/LP Name *</label>
                            <input type="text" className="form-control" id="tokenOrLp" onChange={e => setTokenOrLp(e.target.value)} value={tokenOrLp} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col mb-3">
                            <label htmlFor="network" className="form-label">Network Name *</label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                value={network}
                                onChange={(e) => setNetwork(e.target.value)}
                            >
                                <option value="">Select Network Name</option>
                                {networkList?.map((entry, id) => {
                                    return <option value={entry.name}>{entry.name}</option>;
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col mb-3">
                            <label htmlFor="lockingPeriod" className="form-label">Locking Period *</label>
                            <input type="number" className="form-control" id="lockingPeriod" onChange={e => setLockingPeriod(e.target.value)} value={lockingPeriod} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col mb-3">
                            <label htmlFor="contractAddress" className="form-label">Contract Address *</label>
                            <input type="text" className="form-control" id="contractAddress" onChange={e => setContractAddress(e.target.value)} value={contractAddress} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={upsertEntry}>{isEdit ? 'Update' : 'Add'} Stake Pool</Button>
                </Modal.Footer>
            </Modal>
            {/* delete contact modal */}
            <Modal
                show={showDeletePopUp}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Contract</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className="col mb-3">
                            <span>Are you sure to delete <b> {`${selectedStakePool.network} - [ ${selectedStakePool.tokenOrLp} ]`} </b>? </span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={deleteEntry}>Delete Stake Pool</Button>
                </Modal.Footer>
            </Modal>
            {
                stakePoolList.length ?
                    <div className='container-fluid mt-2'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Token/LP Name</th>
                                    <th scope="col">Network</th>
                                    <th scope="col">Lockin Period</th>
                                    <th scope="col">Contract Address</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    stakePoolList?.map((entry, id) => {
                                        return (<tr key={id + 1}>
                                            <td>{id + 1}</td>
                                            <td>{entry.tokenOrLp}</td>
                                            <td>{entry.network}</td>
                                            <td>{entry.lockingPeriod}</td>
                                            <td>{entry.contractAddress}
                                                <CopyToClipboard
                                                    text={entry.contractAddress}
                                                    onCopy={() => setSelectedValue(entry.contractAddress)}
                                                >
                                                    <ContentCopyIcon
                                                        fontSize="small"
                                                        className="paddingFromLeft"
                                                        color={
                                                            entry.contractAddress === selectedValue
                                                                ? "success"
                                                                : "secondary"
                                                        }
                                                    />
                                                </CopyToClipboard></td>
                                            <td>
                                                <button className="btn btn-primary marginRight" onClick={() => patchValueToForm(entry)} >Update</button>
                                                <button className="btn btn-danger marginleft" onClick={() => showDeleteEntryPopup(entry)}>Delete</button>
                                            </td>
                                        </tr>)
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                    (
                        <div className='container-fluid mt-3'>
                            <div className='row text-center'>
                                <div className='col'>
                                    <h4>No Data Available!!!</h4>
                                </div>
                            </div>
                        </div>
                    )
            }

        </>
    )
}


