import React from "react";
import "./index.css";
import { Modal, Button } from "react-bootstrap";
import { contract, networkUrl } from "../../config/config";
import axiosHelper from "../../helpers/axios-helper.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
export default function Contracts() {
  const [contractList, setContractList] = React.useState([]);
  const [contractListRaw, setContractListRaw] = React.useState([]);
  const [networkList, setNetworkList] = React.useState([]);
  const [id, setId] = React.useState("");
  const [network, setNetwork] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [abi, setAbi] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [showDeletePopUp, setShowDeletePopUp] = React.useState(false);
  const [selectedContract, setSelectedContract] = React.useState({});
  async function fetchData() {
    const result = await axiosHelper(contract, "GET", null, null);
    const { data } = result.data;
    setContractList(data);
    setContractListRaw(data);
    console.log(data);
  }
  async function fetchNetworkData() {
    console.log("network ", network);
    const result = await axiosHelper(networkUrl, "GET", null, null);
    const { data } = result.data;
    console.log("data ", data);
    setNetworkList(data);
  }
  React.useEffect(() => {
    fetchData();
    fetchNetworkData();
  }, []);
  const patchValueToForm = (data) => {
    console.log(data);
    setId(data._id);
    setNetwork(data.network);
    setAddress(data.address);
    setCurrency(data.currency);
    setAbi(data.abi);
    setShow(true);
    setIsEdit(true);
  };

  const handleClose = () => {
    setShow(false);
    setNetwork("");
    setAddress("");
    setCurrency("");
    setId("");
    setAbi("");
    setIsEdit(true);
    setSelectedValue("");
    setShowDeletePopUp(false);
    setSelectedContract({});
  };
  const handleShow = () => {
    setShow(true);
    setIsEdit(false);
  };

  const callApi = async (url, method, obj) => {
    try {
      const result = await axiosHelper(url, method, null, obj);
      handleClose();
      toast.success(
        `Contract successfully ${isEdit ? "updated" : "added"} ! `,
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      fetchData();
    } catch (error) {
      handleClose();
      toast.error("Something went wrong!", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const upsertEntry = async () => {
    if (!network.length || !abi.length || !address.length || !currency.length) {
      toast.success("Please fill all mandatory fields...", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    let obj = {
      network,
      abi,
      address,
      currency,
    };
    if (isEdit) {
      await callApi(contract + "/" + id, "PUT", obj);
    } else {
      await callApi(contract, "POST", obj);
    }
  };
  const deleteEntry = async () => {
    try {
      const result = await axiosHelper(
        contract + "/" + selectedContract._id,
        "DELETE",
        null,
        null
      );
      handleClose();
      toast.success(`Contract successfully delete! `, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      fetchData();
    } catch (error) {
      handleClose();
      toast.error("Something went wrong!", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const showDeleteEntryPopup = async (selectedContract) => {
    setShowDeletePopUp(true);
    setSelectedContract(selectedContract);
  };
  const searchFromList = (searchStr) => {
    const arr = [];
    for (let index = 0; index < contractListRaw.length; index++) {
      if (
        JSON.stringify(contractListRaw[index])
          .toLocaleLowerCase()
          .includes(searchStr.toLocaleLowerCase())
      ) {
        arr.push(contractListRaw[index]);
      }
    }
    setContractList(arr);
  };
  return (
    <>
      <ToastContainer />

      <div className="container-fluid">
        <div className="row align-items-center shadow-sm p-3 bg-light rounded">
          <div className="col-8 bold">Manage Contracts</div>
          <div className="col-4 text-end">
            <div className="row align-items-center  text-end">
              <div className="col align-items-center text-end">
                <input
                  type="text"
                  className="form-control"
                  id="search"
                  placeholder="type to search"
                  onChange={(e) => {
                    searchFromList(e.target.value);
                  }}
                />
              </div>
              <div className="col-auto  text-end">
                <button className="btn btn-primary" onClick={handleShow}>
                  Add Contract
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Update" : "Add"} Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col mb-3">
              <label htmlFor="network" className="form-label">
                Network*
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={network}
                onChange={(e) => setNetwork(e.target.value)}
              >
                <option value="">Select Network Name</option>
                {networkList?.map((entry, id) => {
                  return <option value={entry.name}>{entry.name}</option>;
                })}
              </select>
            </div>
            <div className="col mb-3">
              <label htmlFor="currency" className="form-label">
                Currency*
              </label>
              <input
                type="text"
                className="form-control"
                id="currency"
                onChange={(e) => setCurrency(e.target.value)}
                value={currency}
              />
            </div>
          </div>
          <div className="row">
            <div className="col mb-3">
              <label htmlFor="address" className="form-label">
                Currency Contract Address*
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              />
            </div>
          </div>
          <div className="row">
            <div className="col mb-3">
              <label htmlFor="abi" className="form-label">
                Currency Contract ABI*
              </label>
              <textarea
                className="form-control"
                id="abi"
                rows="6"
                onChange={(e) => setAbi(e.target.value)}
                value={abi}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={upsertEntry}>
            {isEdit ? "Update" : "Add"} Contract
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete contact modal */}
      <Modal
        show={showDeletePopUp}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col mb-3">
              <span>
                Are you sure to delete{" "}
                <b>
                  {" "}
                  {`${selectedContract.network} - [ ${selectedContract.currency} ]`}{" "}
                </b>
                ?{" "}
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteEntry}>
            Delete Contract
          </Button>
        </Modal.Footer>
      </Modal>
      {contractList.length ? (
        <div className="container-fluid mt-2">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Network</th>
                <th scope="col">Currency</th>
                <th scope="col">Contract</th>
                <th scope="col">ABI</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {contractList.length &&
                contractList?.map((entry, id) => {
                  return (
                    <tr key={id + 1}>
                      <td>{id + 1}</td>
                      <td>{entry.network}</td>
                      <td>{entry.currency}</td>
                      <td>
                        {entry.address}
                        <CopyToClipboard
                          text={entry.address}
                          onCopy={() => setSelectedValue(entry.address)}
                          key={entry.address}
                        >
                          <ContentCopyIcon
                            fontSize="small"
                            className="paddingFromLeft"
                            color={
                              entry.address === selectedValue
                                ? "success"
                                : "secondary"
                            }
                          />
                        </CopyToClipboard>
                      </td>
                      <td>
                        {entry.abi.substring(0, 30)}
                        <CopyToClipboard
                          text={entry.abi}
                          onCopy={() => setSelectedValue(entry.abi)}
                        >
                          <ContentCopyIcon
                            fontSize="small"
                            className="paddingFromLeft"
                            color={
                              entry.abi === selectedValue
                                ? "success"
                                : "secondary"
                            }
                          />
                        </CopyToClipboard>
                      </td>
                      <td>
                        <button
                          className="btn btn-primary marginRight"
                          onClick={() => patchValueToForm(entry)}
                        >
                          Update
                        </button>
                        <button
                          className="btn btn-danger marginleft"
                          onClick={() => showDeleteEntryPopup(entry)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="container-fluid mt-3">
          <div className="row text-center">
            <div className="col">
              <h4>No Data Available!!!</h4>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
