import React, { Fragment, useState, useEffect } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import './index.css';
import axiosHelper from "../../helpers/axios-helper.js";
import {login} from '../../config/config';
export default function Login( authenticate) {
  let [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState('');
  const [userToken, setUserToken] = useState(null);
  const navigate = useNavigate();

  const onSubmit = async e => {
    e.preventDefault();
    setMessage("")
    try {
      let res = await axiosHelper(
        `${login}`,
        "POST",
        null,
        {email,password}
      );
      localStorage.setItem('userCtx',JSON.stringify(res?.data?.data?.user));
      localStorage.setItem('token',JSON.stringify(res?.data?.data?.token));
      
      setMessage(res.data.successMsg);
      window.location.reload();
      // navigate("/projects")
      authenticate()
      
    } catch (err) {
      console.log(err)
      if (err?.response?.status === 500) {
        setMessage('There was a problem with the server');
      } else if (err?.response?.status === 400) {
        setMessage(err?.response?.data?.errorMsg);
      } else {
        // setMessage('Something went wrong!');
        // 
      }
    }
  };
  
  console.log(message);
  return (
    <div id="card">
      <div id="card-content">
        <div id="card-title">
          <h4>LOGIN</h4>
          <div className="underline-title"></div>
        </div>
        <h6 className="text-center mt-2 mb-2" style={message ==="User successfully login!"?{color: '#42ba96'}:{color: 'orange'}}>{message}</h6>
        <form onSubmit={onSubmit} className="form">
          <label htmlFor="user-email" className="paddingTopOneRem">
            &nbsp;Email
          </label>
          <input id="user-email" className="form-content" type="email"

            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)} required />
          <div className="form-border"></div>
          <label htmlFor="user-password" className="paddingTopOneRem">&nbsp;Password
          </label>
          <input id="user-password" className="form-content" type={showPassword ? "text" : "password"}

            aria-describedby="basic-addon2"
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required />
          <div className="form-border"></div>
          <div className='showPassword'>
          <div onClick={()=>setShowPassword( showPassword = !showPassword)}>
          <input type="checkbox" id="vehicle1" defaultChecked={showPassword} />
          </div>
          <div className='showPassLabel'>
            Show Password!
          </div>
          </div>
          <input id="submit-btn" type="submit" name="submit" value="login" />
        </form>
      </div>
    </div>
  )
}
