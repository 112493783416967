import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';

export default function AlertDialogPublic({
  autoAllocation,
  handleOnChange,
  handleClickOpen,
  open,
  inputValues,
  handleClose,
  tierArray,
}) {
  console.log(tierArray.length);
  return (
    <div className='randomModal'>
      <Button variant='outlined' onClick={handleClickOpen}>
        Allocate Tier
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Count'}</DialogTitle>
        <CloseIcon className='closeBtn' onClick={handleClose} autoFocus />
        <DialogContent className='DialogContent'>
          <TextField
            type='number'
            id='outlined-basic'
            label='Tier 1 (Enter asva token)'
            variant='outlined'
            name='tier1'
            value={inputValues.tier1}
            onChange={handleOnChange}
          />
        </DialogContent>
        <DialogContent className='DialogContent'>
          <TextField
            type='number'
            id='outlined-basic'
            label='Tier 2 (Enter asva token)'
            variant='outlined'
            name='tier2'
            value={inputValues.tier2}
            onChange={handleOnChange}
          />
        </DialogContent>
        <DialogContent className='DialogContent'>
          <TextField
            type='number'
            id='outlined-basic'
            label='Tier 3 (Enter asva token)'
            variant='outlined'
            name='tier3'
            value={inputValues.tier3}
            onChange={handleOnChange}
          />
        </DialogContent>
        {tierArray.length >= 4 && (
          <DialogContent className='DialogContent'>
            <TextField
              type='number'
              id='outlined-basic'
              label='Tier 4 (Enter asva token)'
              variant='outlined'
              name='tier4'
              value={inputValues.tier4}
              onChange={handleOnChange}
            />
          </DialogContent>
        )}
        {tierArray.length >= 5 && (
          <DialogContent className='DialogContent'>
            <TextField
              type='number'
              id='outlined-basic'
              label='Tier 5 (Enter asva token)'
              variant='outlined'
              name='tier5'
              value={inputValues.tier5}
              onChange={handleOnChange}
            />
          </DialogContent>
        )}
        <DialogContent className='DialogContent'>
          <TextField
            type='number'
            id='outlined-basic'
            label='Asva Token Price ($)'
            variant='outlined'
            name='asvaToken'
            value={inputValues.asvaToken}
            onChange={handleOnChange}
          />
        </DialogContent>
        <DialogContent className='DialogContent'>
          <TextField
            type='number'
            id='outlined-basic'
            label='LP Token Price ($)'
            variant='outlined'
            name='LPToken'
            value={inputValues.LPToken}
            onChange={handleOnChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={autoAllocation} autoFocus>
            Allocate Tier
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
