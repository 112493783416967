import React, { useState } from "react";
import axiosHelper from "../../helpers/axios-helper.js";
import { updatePass } from "../../config/config.js";
const UpdatePass = () => {
    const [newPass, setNewPass] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit= async(e)=>{
        e.preventDefault();
        
        try {
            let res = await axiosHelper(
            `${updatePass}`,
            "PATCH",
            null,
            {newPassword:newPass,currentPassword:password}
          );
            localStorage.setItem('userCtx',JSON.stringify(res?.data?.data?.user));
            localStorage.setItem('token',JSON.stringify(res?.data?.data?.token));
          
        } catch (err) {
          console.log(err)
          if (err?.response?.status === 500) {
            console.log('There was a problem with the server');
          } else if (err?.response?.status === 400) {
            console.log(err?.response?.data?.errorMsg);
          } else {
            // setMessage('Something went wrong!');
            // 
          }
        } 
    }
    return (
    <div id="card">
      <div id="card-content">
        <div id="card-title">
          <h4>Password</h4>
          <div className="underline-title"></div>
        </div>
        <form onSubmit={handleSubmit}>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              Current Password
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputPassword1"
              onChange={(e)=>setPassword(e.target.value)}
            />
          </div>

          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              New Password
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputPassword1"
              onChange={(e)=>setNewPass(e.target.value)}
            />
          </div>
          <button type="submit" class="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdatePass;
