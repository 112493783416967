import React, { useState, useEffect, useContext } from "react";
import { contract } from "../../config/config";
import axiosHelper from "../../helpers/axios-helper.js";

const ThirdPage = ({
  data,
  update,
  NetworkType,
  SupportedToken,
  curencyabi,
}) => {
  const [contractResult, setContractResult] = useState();
  useEffect(() => {
    async function fetchResult() {
      const result = await axiosHelper(contract, "GET", null, null);
      const response = result.data.data;

      setContractResult(response);
    }
    fetchResult();
  }, []);

  const networkselectarray = contractResult?.filter((data) => {
    return data.network == NetworkType;
  });

  const supportedTokenarray = networkselectarray?.filter((e) => {
    return e.currency == SupportedToken;
  });
  console.log(supportedTokenarray);
  const contractabi = supportedTokenarray?.map((item) => {
    return item.abi;
  });

  const contractaddress = supportedTokenarray?.map((item) => {
    return item.address;
  });

  function takeData(contractabi, contractaddress) {
    update("finance", "currencyABI", contractabi);
    update("finance", "contractCurrencyAddress", contractaddress);
  }
  useEffect(() => {
    if (contractabi !== undefined && contractaddress !== undefined) {
      takeData(contractabi[0], contractaddress[0]);
    }
  }, [SupportedToken]);

  return (
    <>
      <div className="mb-2 row">
        <div className="col">
          <label htmlFor="token-name" className="col-form-label">
            Token Name *:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="token-name"
            name="tokenName"
            value={data.tokenName}
            onChange={(e) => update("finance", "tokenName", e.target.value)}
          />
          {data.tokenName === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="token-symbol" className="col-form-label">
            Token Symbol *:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="token-symbol"
            name="tokenSymbol"
            value={data.tokenSymbol}
            onChange={(e) => update("finance", "tokenSymbol", e.target.value)}
          />
          {data.tokenSymbol === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="token-price" className="col-form-label">
            Token Price *:
          </label>
          <input
            type="number"
            required
            className="form-control"
            id="token-price"
            name="tokenPrice"
            value={data.tokenPrice}
            onChange={(e) => update("finance", "tokenPrice", e.target.value)}
          />
          {data.tokenPrice === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
      </div>
      <div className="mb-2 row">
        <div className="col">
          <label htmlFor="token-rate" className="col-form-label">
            Token Rate *:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="token-rate"
            name="tokenRate"
            value={data.tokenRate}
            onChange={(e) => update("finance", "tokenRate", e.target.value)}
          />
          {data.tokenRate === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="vesting" className="col-form-label">
            Vesting *:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="vesting"
            name="vesting"
            value={data.vesting}
            onChange={(e) => update("finance", "vesting", e.target.value)}
          />
          {data.vesting === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="report-link" className="col-form-label">
            Audit Report Link:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="report-link"
            name="reportLink"
            value={data.reportLink}
            onChange={(e) => update("finance", "reportLink", e.target.value)}
          />
          {data.reportLink === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
      </div>
      <div className="mb-2 row">
        {/* <div className="col">
          <label htmlFor="token-contract-address" className="col-form-label">
            Token Contract Address:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="token-contract-address"
            name="tokenContractAddress"
            value={data.tokenContractAddress}
            onChange={(e) =>
              update("finance", "tokenContractAddress", e.target.value)
            }
          />
          {data.tokenContractAddress === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="token-contract-link" className="col-form-label">
            Token Contract
            <br /> Link:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="token-contract-link"
            name="tokenContractLink"
            value={data.tokenContractLink}
            onChange={(e) =>
              update("finance", "tokenContractLink", e.target.value)
            }
          />
          {data.tokenContractLink === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div> */}
        <div className="col">
          <label htmlFor="total-fund-raise" className="col-form-label">
            Total Fund Raise *:
          </label>
          <input
            type="number"
            required
            min={1}
            className="form-control"
            id="total-fund-raise"
            name="totalFundRaise"
            value={data.totalFundRaise}
            onChange={(e) =>
              update("finance", "totalFundRaise", e.target.value)
            }
          />
          {data.totalFundRaise === "" ? (
            <p className="nonRequired">Enter 0 for TBA</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="contract-token-address" className="col-form-label">
            Token Contract Address(UI):
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="contract-token-address"
            name="tokenContractAddressUI"
            value={data.tokenContractAddressUI}
            onChange={(e) =>
              update("finance", "tokenContractAddressUI", e.target.value)
            }
          />
          {data.tokenContractAddressUI === "" ? (
            <p className="nonRequired">Address for APP UI</p>
          ) : null}
        </div>
      </div>
      <div className="mb-2 row">
        <div className="col">
          <label htmlFor="contract-token-address" className="col-form-label">
            Token Contract Address(Contract) *:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="contract-token-address"
            name="contractTokenAddress"
            value={data.contractTokenAddress}
            onChange={(e) =>
              update("finance", "contractTokenAddress", e.target.value)
            }
          />
          {data.contractTokenAddress === "" ? (
            <p className="validateField">Address for contract</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="token-ABI" className="col-form-label">
            Token ABI *:
          </label>
          <input
            type="text"
            className="form-control"
            id="token-ABI"
            name="tokenABI"
            value={data.tokenABI}
            onChange={(e) => update("finance", "tokenABI", e.target.value)}
          />
          {data.tokenABI === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
      </div>
      <div className="mb-2 row">
        <div className="col">
          <label htmlFor="supported-token" className="col-form-label">
            Supported Currency Token *:
          </label>
          <select
            type="text"
            required
            className="form-select"
            id="supported-token"
            name="supportedToken"
            value={data.supportedToken}
            onChange={(e) =>
              update("finance", "supportedToken", e.target.value)
            }
          >
            <option value=" ">SELECT CURRENCY</option>
            {networkselectarray?.map((item) => {
              return <option value={item.currency}>{item.currency}</option>;
            })}
          </select>
          {data.supportedToken === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>

        <div className="col">
          <label htmlFor="currency-ABI" className="col-form-label">
            Currency ABI *:
          </label>
          <input
            type="text"
            className="form-control"
            id="currency-ABI"
            name="currencyABI"
            value={data.currencyABI}
            onChange={(e) => update("finance", "currencyABI", e.target.value)}
          />

          {data.currencyABI === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
      </div>
      <div className="mb-2 row">
        <div className="col">
          <label htmlFor="contract-currency-address" className="col-form-label">
            Currency Contract Address *:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="contract-currency-address"
            name="contractCurrencyAddress"
            value={data.contractCurrencyAddress}
            onChange={(e) =>
              update("finance", "contractCurrencyAddress", e.target.value)
            }
          />
          {data.contractCurrencyAddress === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>

        <div className="col">
          <label htmlFor="initial-market-cap" className="col-form-label">
            Initial Market Cap *:
          </label>
          <input
            type="number"
            min={1}
            required
            className="form-control"
            id="initial-market-cap"
            name="initialMarketCap"
            value={data.initialMarketCap}
            onChange={(e) =>
              update("finance", "initialMarketCap", e.target.value)
            }
          />
          {data.initialMarketCap === "" ? (
            <p className="nonRequired">Enter 0 for TBA</p>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ThirdPage;
