import React, { useEffect, useState } from 'react';

const WizTwo = ({
  launchData,
  handleInput,
  tokenPrice,
  projectId,
  noOfTier,
}) => {
  const [array, setArray] = useState([]);
  console.log(launchData);

  useEffect(() => {
    let arr = [];
    for (var i = 1; i <= noOfTier; i++) {
      arr.push(i);
    }
    setArray(arr);
  }, []);
  // console.log(launchData, noOfTier);

  return (
    <div className='container'>
      {array?.map?.((item, ind) => {
        return (
          <div className='col'>
            <label htmlFor='token-address' className='col-form-label'>
              Max Allocation($) per User (Tier {item}):
            </label>
            <input
              type='text'
              required
              value={launchData?.maxAmountforTier?.[ind]}
              onChange={(e) =>
                handleInput('maxAmountforTier', e.target.value, ind)
              }
              className='form-control'
              id='token-address'
            />
          </div>
        );
      })}
    </div>
  );
};

export default WizTwo;
