import React from "react";

const WizOne = ({ launchData, handleInput, tokenAddress, currencyAddress }) => {
  return (
    <div className="container">
      <div className="mb-2 row">
        <div className="col">
          <label htmlFor="token-address" className="col-form-label">
            Token Contract Address:
          </label>
          <input
            type="text"
            required
            defaultValue={tokenAddress}
            onChange={(e) => handleInput("token", e.target.value)}
            className="form-control"
            id="token-address"
            disabled
          />
        </div>
        <div className="col">
          <label htmlFor="currency-address" className="col-form-label">
            CurrencyContract Address:
          </label>
          <input
            type="text"
            required
            defaultValue={currencyAddress}
            onChange={(e) => handleInput("currency", e.target.value)}
            className="form-control"
            id="currency-address"
            disabled
          />
        </div>
      </div>
      <div className="mb-2 row">
        <div className="col-6">
          <label htmlFor="round-one-start" className="col-form-label">
            Round One Start:
          </label>
          <input
            type="datetime-local"
            required
            className="form-control"
            id="round-one-start"
            name="roundOneStart"
            defaultValue={launchData.roundOneStart}
            contentEditable={false}
            onChange={(e) => handleInput("roundOneStart", e.target.value)}
            disabled
          />
        </div>
        <div className="col-6">
          <label htmlFor="round-one-end" className="col-form-label">
            Round One End:
          </label>
          <input
            type="datetime-local"
            required
            className="form-control"
            id="round-one-end"
            name="roundOneEnd"
            defaultValue={launchData.roundOneEnd}
            contentEditable={false}
            onChange={(e) => handleInput("roundOneEnd", e.target.value)}
            disabled
          />
        </div>
      </div>
      <div className="mb-2 row">
        <div className="col-6">
          <label htmlFor="round-two-start" className="col-form-label">
            Round Two Start:
          </label>
          <input
            type="datetime-local"
            required
            className="form-control"
            id="round-two-start"
            name="roundTwoStart"
            defaultValue={launchData.roundTwoStart}
            contentEditable={false}
            onChange={(e) => handleInput("roundTwoStart", e.target.value)}
            disabled
          />
        </div>
        <div className="col-6">
          <label htmlFor="round-two-end" className="col-form-label">
            Round Two End:
          </label>
          <input
            type="datetime-local"
            required
            className="form-control"
            id="round-two-end"
            name="roundTwoEnd"
            defaultValue={launchData.roundTwoEnd}
            contentEditable={false}
            onChange={(e) => handleInput("roundTwoEnd", e.target.value)}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default WizOne;
