import React from "react";
import "./index.css";
import { Modal, Button } from "react-bootstrap";
import { projectType } from "../../config/config";
import axiosHelper from "../../helpers/axios-helper.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ProjectType() {
  const [projectTypeList, setProjectTypeList] = React.useState([]);
  const [projectTypeListRaw, setProjectTypeListRaw] = React.useState([]);
  const [id, setId] = React.useState("");
  const [projectTypeName, setProjectTypeName] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [showDeletePopUp, setShowDeletePopUp] = React.useState(false);
  const [selectedProjectType, setSelectedProjectType] = React.useState({});

  const saleTypeObj = [{ name: "IDO" }, { name: "IGO" }, { name: "IMO" }];

  async function fetchData() {
    const result = await axiosHelper(projectType, "GET", null, null);
    const { data } = result.data;
    setProjectTypeList(data);
    setProjectTypeListRaw(data);
    // console.log(data);
  }
  React.useEffect(() => {
    fetchData();
  }, []);
  const patchValueToForm = (data) => {
    // console.log(data);
    setId(data._id);
    setProjectTypeName(data.projectType);
    setShow(true);
    setIsEdit(true);
  };

  const handleClose = () => {
    setShow(false);
    setProjectTypeName("");
    setId("");
    setIsEdit(true);
    setShowDeletePopUp(false);
    setSelectedProjectType({});
  };
  const handleShow = () => {
    setShow(true);
    setIsEdit(false);
  };

  const callApi = async (url, method, obj) => {
    try {
      const result = await axiosHelper(url, method, null, obj);
      handleClose();
      toast.success(
        `ProjectType successfully ${isEdit ? "updated" : "added"} ! `,
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      fetchData();
    } catch (error) {
      handleClose();
      toast.error("Something went wrong!", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const upsertEntry = async () => {
    if (!projectTypeName.length || !projectTypeName.length) {
      // alert('please fill all mandatory fields...');
      toast.success("Please fill all mandatory fields...", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    let obj = {
      projectType: projectTypeName,
      // ?.toUpperCase()
    };
    if (isEdit) {
      await callApi(projectType + "/" + id, "PUT", obj);
    } else {
      await callApi(projectType, "POST", obj);
    }
  };
  const deleteEntry = async () => {
    try {
      const result = await axiosHelper(
        projectType + "/" + selectedProjectType._id,
        "DELETE",
        null,
        null
      );
      handleClose();
      toast.success(`ProjectType successfully delete! `, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      fetchData();
    } catch (error) {
      handleClose();
      toast.error("Something went wrong!", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const showDeleteEntryPopup = async (projectTypeData) => {
    setShowDeletePopUp(true);
    setSelectedProjectType(projectTypeData);
  };
  const searchFromList = (searchStr) => {
    const arr = [];
    for (let index = 0; index < projectTypeListRaw.length; index++) {
      if (
        JSON.stringify(projectTypeListRaw[index])
          .toLocaleLowerCase()
          .includes(searchStr.toLocaleLowerCase())
      ) {
        arr.push(projectTypeListRaw[index]);
      }
    }
    setProjectTypeList(arr);
  };
  return (
    <>
      <ToastContainer />

      <div className="container-fluid">
        <div className="row align-items-center shadow-sm p-3 bg-light rounded">
          <div className="col-8 bold">Manage Project Type</div>
          <div className="col-4 text-end">
            <div className="row align-items-center  text-end">
              <div className="col align-items-center text-end">
                <input
                  type="text"
                  className="form-control"
                  id="search"
                  placeholder="type to search"
                  onChange={(e) => {
                    searchFromList(e.target.value);
                  }}
                />
              </div>
              <div className="col-auto  text-end">
                <button className="btn btn-primary" onClick={handleShow}>
                  Add Project Type
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Update" : "Add"} Project Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col mb-3">
              <label htmlFor="projectType" className="form-label">
                Project Type *
              </label>
              <input
                type="text"
                className="form-control"
                id="projectType"
                onChange={(e) => setProjectTypeName(e.target.value)}
                value={projectTypeName}
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col mb-3">
              <label htmlFor="projectTypeId" className="form-label">
                ProjectType Id *
              </label>
              <input
                type="number"
                className="form-control"
                id="projectTypeId"
                onChange={(e) => setProjectTypeChainId(e.target.value)}
                value={projectTypeChainId}
              />
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={upsertEntry}>
            {isEdit ? "Update" : "Add"} Project Type
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete contact modal */}
      <Modal
        show={showDeletePopUp}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col mb-3">
              <span>
                Are you sure to delete
                <b>{` ${selectedProjectType.projectType}`}</b>?
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteEntry}>
            Delete ProjectType
          </Button>
        </Modal.Footer>
      </Modal>
      {projectTypeList.length ? (
        <div className="container-fluid mt-2">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col"> Project Type</th>
                <th scope="col">Action</th>
                {/* <th scope="col">Delete</th> */}
              </tr>
            </thead>
            <tbody>
              {projectTypeList?.map((entry, id) => {
                return (
                  <tr key={id + 1}>
                    <td>{id + 1}</td>
                    <td>{entry.projectType}</td>
                    <td>
                      <button
                        className="btn btn-primary marginRight"
                        onClick={() => patchValueToForm(entry)}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-danger marginleft"
                        onClick={() => showDeleteEntryPopup(entry)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="container-fluid mt-3">
          <div className="row text-center">
            <div className="col">
              <h4>No Data Available!!!</h4>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
