import React, { useEffect, useState } from 'react';

const WizThree = ({ launchData, handleInput, roundOffBuyValue, noOfTier }) => {
  console.log(launchData.totalAmount);
  const [array, setArray] = useState([]);
  const tier = Object.values(launchData.tier);

  useEffect(() => {
    let arr = [];
    for (var i = 1; i <= noOfTier; i++) {
      arr.push(i);
    }
    setArray(arr);
  }, []);
  let totalAmounts = 0;
  for (let i = 0; i < tier.length; i++) {
    totalAmounts += roundOffBuyValue(tier[i] / launchData.price);
  }
  let total = 0;
  for (let i = 0; i < tier.length; i++) {
    total += parseInt(tier[i]);
  }

  // console.log(launchData, noOfTier);
  // function communityInput(launchData) {
  //   handleInput('tierFour', launchData.totalAmount);
  // }
  // useEffect(() => {
  //   if (launchData.typeOfProject === 'Community IDO') {
  //     communityInput(launchData);
  //   }
  // }, []);

  return (
    <div className='container'>
      <label htmlFor='token-address' className='col-form-label m-2 fw-bold'>
        Total Fund Raise ($) : $({launchData.totalAmount}) = $({total}){' '}
      </label>
      {array?.map?.((item, ind) => {
        return (
          <div className='col'>
            <label htmlFor='token-address' className='col-form-label'>
              Tier {item} Pool Allocation($)
            </label>
            <input
              type='text'
              required
              value={launchData?.tier?.[ind]}
              onChange={(e) => handleInput('tier', e.target.value, ind)}
              className='form-control'
              id='token-address'
            />
            <label htmlFor='token-address' className='col-form-label'>
              {launchData?.tier?.[ind] / launchData.price}
            </label>
          </div>
        );
      })}
      <hr />
      <label htmlFor='token-address' className='col-form-label m-1 fw-bold'>
        Total Tokens Raise :
      </label>
      {tier.map((item, index) => {
        return (
          <label htmlFor='token-address' className='col-form-label m-1'>
            {index === 0 ? null : '+ '}
            {roundOffBuyValue(parseInt(item) / launchData.price)}
          </label>
        );
      })}{' '}
      =
      <br />
      <label htmlFor='token-address' className='col-form-label m-2 fw-bold'>
        Total :
      </label>
      <label htmlFor='token-address' className='col-form-label m-2'>
        ({roundOffBuyValue(totalAmounts)})
      </label>
    </div>
  );
};

export default WizThree;
