import React, { useEffect } from "react";
import "./index.css";
import { Modal, Button } from "react-bootstrap";
import axiosHelper from "../../helpers/axios-helper.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  AiOutlineAppstoreAdd,
  AiFillCheckCircle,
  AiFillCloseCircle,
} from "react-icons/ai";
import {
  networkUrl,
  projectType,
  upload,
  featuredProjectUrl,
} from "../../config/config";
import { MdDelete } from "react-icons/md";

const FeaturedProjects = () => {
  const [featuredProject, setFeaturedProject] = React.useState([]);
  const [networkList, setNetworkList] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [showDeletePopUp, setShowDeletePopUp] = React.useState(false);
  const [selectedFeaturedProject, setSelectedFeaturedProject] = React.useState(
    {}
  );
  const [imgLoading, setImgLoading] = React.useState(false);
  const [mediaLoading, setMediaLoading] = React.useState(false);
  const [projectTypeList, setProjectTypeList] = React.useState([]);
  const [featureImage, setFeatureImage] = React.useState();
  const [featureMedia, setFeatureMedia] = React.useState();

  async function fetchDataProjectType() {
    const resultProjectType = await axiosHelper(projectType, "GET", null, null);
    const { data } = resultProjectType.data;
    setProjectTypeList(data);
  }
  async function getProjectScreenings() {
    const result = await axiosHelper(
      featuredProjectUrl + "?type=all",
      "GET",
      null,
      null
    );
    const { data } = result.data;
    setFeaturedProject(data);
  }
  async function fetchNetworkData() {
    const result = await axiosHelper(networkUrl, "GET", null, null);
    const { data } = result.data;
    setNetworkList(data);
  }
  useEffect(() => {
    getProjectScreenings();
    fetchNetworkData();
    fetchDataProjectType();
  }, []);
  const patchValueToForm = (data) => {
    console.log(data);
    setSelectedFeaturedProject(data);
    setShow(true);
    setIsEdit(true);
  };
  const handleClose = () => {
    setShow(false);
    setIsEdit(true);
    setShowDeletePopUp(false);
    setSelectedFeaturedProject({});
  };
  const handleShow = () => {
    setShow(true);
    setSelectedFeaturedProject((selectedFeaturedProject) => ({
      ...selectedFeaturedProject,
    }));
    setIsEdit(false);
  };
  const callApi = async (url, method, obj) => {
    try {
      const result = await axiosHelper(url, method, null, obj);
      handleClose();

      toast.success(
        `Feature project successfully ${isEdit ? "updated" : "added"} ! `,
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      getProjectScreenings();
    } catch (error) {
      handleClose();
      console.log(error);
      toast.error(error?.errorMsg || "Something went wrong!", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const upsertEntry = async () => {
    if (isEdit) {
      await callApi(
        featuredProjectUrl + "/" + selectedFeaturedProject?._id,
        "PUT",
        selectedFeaturedProject
      );
    } else {
      await callApi(featuredProjectUrl, "POST", selectedFeaturedProject);
    }
  };
  const deleteEntry = async () => {
    try {
      const result = await axiosHelper(
        featuredProjectUrl + "/" + selectedFeaturedProject._id,
        "DELETE",
        null,
        null
      );
      handleClose();
      toast.success(`Feature project successfully delete! `, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getProjectScreenings();
    } catch (error) {
      handleClose();
      toast.error("Something went wrong!", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const showDeleteEntryPopup = async (stakePoolDate) => {
    setShowDeletePopUp(true);
    setSelectedFeaturedProject(stakePoolDate);
  };
  const uploadImg = async (_type) => {
    await uploadFunction(_type, featureImage);
  };
  const uploadMedia = async (_type) => {
    await uploadFunction(_type, featureMedia);
  };
  const getMediaType = async (mimeType) => {
    if (mimeType.includes("gif")) {
      return "GIF";
    } else if (mimeType.includes("video")) {
      return "VIDEO";
    } else if (mimeType.includes("image")) {
      return "IMAGE";
    } else {
      return "AUDIO";
    }
  };
  async function uploadFunction(_type, file) {
    try {
      const formData = new FormData();
      formData.append("file", file);

      if (_type === "banner") {
        setImgLoading(true);
      } else if (_type === "media") {
        setMediaLoading(true);
      }

      const result = await axiosHelper(upload, "POST", formData);
      const mediaType = await getMediaType(result?.data?.data?.mimetype);
      if (_type === "banner") {
        setSelectedFeaturedProject({
          ...selectedFeaturedProject,
          banner: {
            url: result?.data?.data?.location,
            type: mediaType,
          },
        });
        setImgLoading(false);
      } else if (_type === "media") {
        setSelectedFeaturedProject({
          ...selectedFeaturedProject,
          media: {
            url: result?.data?.data?.location,
            type: mediaType,
          },
        });
        setMediaLoading(false);
      }
      toast.success("Media uploaded!", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error("Something went wrong!", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (_type === "banner") {
        setImgLoading(false);
      } else if (_type === "media") {
        setMediaLoading(false);
      }
    }
  }
  const handelFileInput = (_event, _type) => {
    if (_type === "banner") {
      setFeatureImage(_event.target.files[0]);
    } else if (_type === "media") {
      setFeatureMedia(_event.target.files[0]);
    }
  };
  const onChangeHandler = (key, val) => {
    console.log("key : ", key, " val ", val);
    if (key === "chainId") {
      val = parseInt(val);
    }
    setSelectedFeaturedProject((selectedFeaturedProject) => ({
      ...selectedFeaturedProject,
      [key]: val,
    }));
  };
  return (
    <>
      <ToastContainer />

      <div className="container-fluid">
        <div className="row align-items-center shadow-sm p-3 bg-light rounded">
          <div className="col-8 bold">Featured Projects</div>
          <div className="col-4 text-end">
            <button className="btn btn-primary" onClick={handleShow}>
              <AiOutlineAppstoreAdd /> Add
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit ? "Update" : "Add"} Featured Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col mb-3">
              <label htmlFor="projectName" className="form-label">
                Project Name
              </label>
              <input
                type="text"
                className="form-control"
                id="projectName"
                value={selectedFeaturedProject?.projectName}
                onChange={(e) => onChangeHandler("projectName", e.target.value)}
              />
            </div>
            <div className="col mb-3">
              <label htmlFor="network" className="form-label">
                Network (chainId)
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={selectedFeaturedProject?.chainId}
                onChange={(e) => onChangeHandler("chainId", e.target.value)}
              >
                <option value={0}>Select Network Name</option>
                {networkList?.map((entry, id) => {
                  return (
                    <option value={entry.chainId}>
                      {entry.name} - [{entry.chainId}]
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col mb-3">
              <label htmlFor="idoType" className="form-label">
                IDO Type{" "}
              </label>
              <select
                className="form-select"
                required
                name="idoType"
                value={selectedFeaturedProject?.idoType}
                onChange={(e) => onChangeHandler("idoType", e.target.value)}
                id="idoType"
              >
                <option value={0}>Select IDO Type</option>
                {projectTypeList?.map((entry, id) => {
                  return (
                    <option value={entry.projectType}>
                      {entry.projectType}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col mb-3">
              <label htmlFor="idoDate" className="form-label">
                IDO Date (IST)
              </label>
              <input
                type="datetime-local"
                className="form-control"
                id="idoDate"
                onChange={(e) => onChangeHandler("idoDate", e.target.value)}
                value={
                  selectedFeaturedProject?.idoDate
                    ? moment(selectedFeaturedProject?.idoDate).format(
                        "YYYY-MM-DDTHH:mm:ss"
                      )
                    : null
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="d-flex flex-column">
                <div className="">
                  <label htmlFor="network" className="form-label">
                    Image
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={(event) => handelFileInput(event, "banner")}
                    />
                    <button
                      className="input-group-text"
                      onClick={() => uploadImg("banner")}
                    >
                      {imgLoading ? (
                        <div
                          className="spinner-border text-primary spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <span>Upload</span>
                      )}
                    </button>
                  </div>
                </div>
                <div className="">
                  {selectedFeaturedProject?.banner?.url?.length && (
                    <div className="pb-3">
                      <img
                        src={selectedFeaturedProject?.banner?.url}
                        alt="banner"
                        width={"100%"}
                        height={"200px"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col">
              <div className="d-flex flex-column">
                <div className="">
                  <label htmlFor="network" className="form-label">
                    Video/Gif
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="file"
                      className="form-control"
                      onChange={(event) => handelFileInput(event, "media")}
                    />
                    <button
                      className="input-group-text"
                      onClick={() => uploadMedia("media")}
                    >
                      {mediaLoading ? (
                        <div
                          className="spinner-border text-primary spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <span>Upload</span>
                      )}
                    </button>
                  </div>
                </div>
                {selectedFeaturedProject?.media?.url && (
                  <div className="">
                    {selectedFeaturedProject?.media?.type === "VIDEO" ? (
                      <video width="100%" height="200" controls>
                        <source src={selectedFeaturedProject?.media?.url} />
                      </video>
                    ) : (
                      <img
                        src={selectedFeaturedProject?.media?.url}
                        alt="Screening Media"
                        width={"100%"}
                        height={"200px"}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <input
                className="form-check-input mt-1"
                type="checkbox"
                value={selectedFeaturedProject?.isActive}
                checked={selectedFeaturedProject?.isActive}
                id="flexSwitchCheckDefault"
                onChange={() =>
                  onChangeHandler(
                    "isActive",
                    !selectedFeaturedProject?.isActive
                  )
                }
              />
              <label
                htmlFor="flexSwitchCheckDefault"
                className="form-label mx-2 bold"
              >
                Is feature project active?{" "}
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={upsertEntry}>
            <AiOutlineAppstoreAdd /> {isEdit ? "Update" : "Add"}{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete contact modal */}
      <Modal
        show={showDeletePopUp}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Screening</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col mb-3">
              <span>
                Are you sure to delete{" "}
                <b>
                  {" "}
                  {`${selectedFeaturedProject?.projectName} - [ ${selectedFeaturedProject?.idoType} ]`}{" "}
                </b>
                ?{" "}
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteEntry}>
            <MdDelete size={20} /> Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {featuredProject?.length ? (
        <div className="container-fluid mt-2">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Project</th>
                <th scope="col">IDO Type</th>
                <th scope="col">IDO Date (IST)</th>
                <th scope="col">Chain Id</th>
                <th scope="col">Image</th>
                <th scope="col">Video/Gif</th>
                <th scope="col">Is Active?</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {featuredProject?.map((entry, id) => {
                return (
                  <tr key={id + 1}>
                    <td className="align-middle">{id + 1}</td>
                    <td className="align-middle">{entry?.projectName}</td>
                    <td className="align-middle">{entry?.idoType}</td>
                    <td className="align-middle">
                      {entry?.idoDate
                        ? moment(entry?.idoDate).format("YYYY-MM-DDTHH:mm:ss")
                        : "TBA"}
                    </td>
                    <td className="align-middle">{entry?.chainId}</td>
                    <td className="align-middle">
                      {entry?.banner?.url?.length ? (
                        <img
                          src={entry?.banner?.url}
                          alt="Screening Img"
                          width={32}
                          height={32}
                        />
                      ) : (
                        "NA"
                      )}
                    </td>
                    <td className="align-middle">
                      {entry?.media?.url?.length ? (
                        entry?.media?.type === "VIDEO" ? (
                          <video width="55" height="55" controls>
                            <source src={entry?.media?.url} />
                          </video>
                        ) : (
                          <img
                            src={entry?.media?.url}
                            alt="Screening Media"
                            width={32}
                            height={32}
                          />
                        )
                      ) : (
                        "NA"
                      )}
                    </td>
                    <td className="align-middle">
                      {entry?.isActive ? (
                        <AiFillCheckCircle color="green" size={32} />
                      ) : (
                        <AiFillCloseCircle color="red" size={32} />
                      )}
                    </td>
                    <td className="align-middle">
                      <button
                        className="btn btn-primary marginRight"
                        onClick={() => patchValueToForm(entry)}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-danger marginleft"
                        onClick={() => showDeleteEntryPopup(entry)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="container-fluid mt-3">
          <div className="row text-center">
            <div className="col">
              <h4>No Data Available!!!</h4>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeaturedProjects;
