import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import MetalaunchScreening from '../ProductMoreDetailsPage/MetalaunchScreening';
import ProjectDetails from '../ProductMoreDetailsPage/ProjectDetails';
import TokenMetrics from '../ProductMoreDetailsPage/TokenMetrics';
import axiosHelper from '../../helpers/axios-helper';
import { getProjectById, projectDetails, upload } from '../../config/config';
function MoreDetails() {
  const { id } = useParams();
  const [isEditProject, toggleIsEditProject] = useState(true);
  const [projectData, setProjectData] = useState({
    projectName: '',
    typeOfProject: '',
  });
  const [projectDetailsvisiblity, setProjectDetailsVisiblity] = useState(false);
  const [tokenMetricsvisiblity, setTokenMetricsvisiblity] = useState(false);
  const [currentTab, setCurrentTab] = useState('All');

  useEffect(() => {
    async function fetchData() {
      const result = await axiosHelper(
        getProjectById,
        'POST',
        null,
        JSON.stringify({ id: id })
      );
      const { data } = result.data;
      setProjectData({
        projectName: data.projectName,
        typeOfProject: data.typeOfProject,
      });
      // console.log('Data', data);
    }
    fetchData();
  }, [id]);

  const mystyle = {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px',
        }}
      >
        {/* <div>
          <h2>{`${projectData.projectName} ${projectData.typeOfProject}`}</h2>
        </div> */}
      </div>
      <div>
        <div style={mystyle}>
          <div>
            <button
              className='btn btn-primary'
              type='button'
              // id='dropdownMenuButton1'
              // data-bs-toggle='dropdown'
              // aria-expanded='false'
              // onClick={() => {
              //   setProjectDetailsVisiblity(!projectDetailsvisiblity);
              // }}
              onClick={() => setCurrentTab('Project Details')}
            >
              Project Details
            </button>
            {currentTab === 'Project Details' && (
              <ProjectDetails
                isEditProject={isEditProject}
                projectData={projectData}
              />
            )}
            {/* {projectDetailsvisiblity && (
              <div>
                <ProjectDetails
                  isEditProject={isEditProject}
                  projectData={projectData}
                />
              </div>
            )} */}
            {/* <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
              <li className='linkList'>
                <Link className='NavLink' to={`../project-details/${id}`}>
                  Add
                </Link>
              </li>
              <li className='linkList'>
                <Link className='NavLink' to={`../project-details/${id}`}>
                  Edit
                  <div style={{ display: 'none' }}>
                    <ProjectDetails isEditProject={isEditProject} />
                  </div>
                </Link>
              </li>
            </ul> */}
          </div>
          <div>
            <button
              className='btn btn-primary'
              type='button'
              // onClick={() => {
              //   setTokenMetricsvisiblity(!tokenMetricsvisiblity);
              // }}
              onClick={() => setCurrentTab('Token Metrics')}
            >
              Token Metrics
            </button>
            {currentTab === 'Token Metrics' && (
              <TokenMetrics
                isEditProject={isEditProject}
                projectData={projectData}
              />
            )}
            {/* <div>
              <TokenMetrics
                isEditProject={isEditProject}
                projectData={projectData}
              />
            </div> */}
            {/* <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
              <li className='linkList'>
                <Link className='NavLink' to={`../token-metrics/${id}`}>
                  Add
                </Link>
              </li>
              <li className='linkList'>
                <Link className='NavLink' to={`../token-metrics/${id}`}>
                  Edit
                  <div style={{ display: 'none' }}>
                    <TokenMetrics isEditProject={isEditProject} />
                  </div>
                </Link>
              </li>
            </ul> */}
          </div>
           <div>
            <button
              className='btn btn-primary'
              type='button'
              // id='dropdownMenuButton1'
              // data-bs-toggle='dropdown'
              // aria-expanded='false'
              onClick={() => setCurrentTab('Metalaunch Screening')}
            >
              Metalaunch Screening
            </button>
            {currentTab === 'Metalaunch Screening' && (
              <MetalaunchScreening
                isEditProject={isEditProject}
                projectData={projectData}
              />
            )}
             {/* <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
              <li className='linkList'>
                <Link className='NavLink' to={`../metalaunch-screening/${id}`}>
                  Add
                </Link>
              </li>
              <li className='linkList'>
                <Link className='NavLink' to={`../metalaunch-screening/${id}`}>
                  Edit
                  <div style={{ display: 'none' }}>
                    <MetalaunchScreening isEditProject={isEditProject} />
                  </div>
                </Link>
              </li>
            </ul> */}
          </div> 
        </div>
      </div>
    </>
  );
}

export default MoreDetails;
