const addresses = {
  AsvaPoolFactory: {
    56: '0x21ab4bb2fec645a14c5254a0f5819fd0a5fd4479', //BSC mainnet
    // 56: "0x5A97353D6912d72d36E4a176Ffa9A0451e030d6a",
    //43113: '0xfc7d482Df0A69ab2a2b953AA3a918b5BB6B0BD6E',
    43113: '0x60B34d6A235B03D03240E2f8C50d2bAd84240222', //'0xa0784A5B6f01AC95d7759e5f304928Ba12280eF6',
    97: '0x4DeE82194E72044DD0B7F92F4f737de75e73B1f0', //'0xF66E430637D187B2E132C265918af76Ef21Fe13F',
    80001: '0x6a4aEeea8Ff6f4f38431BFa0091542AC3423c787',
    137: '0x34C9c687c26F152d8732f213BD1b04f864454066', //Polygon mainnet
  },
  ClaimFactory: {
    137: '0x9d85f6e82339ffc6d1cdbbc6461b7842371af729',
    80001: '0xdD8c783a803F73CB6e10E5B5F67cB5E8C48945D6',
    97: '0xC3aA02dA10dac258B2cFB4f0a01cF11dCF00764B', //'0xD983df46eE51a7320c00E1C2bd9DAC0d9927dE2B',
    // 43113: '0x18E6A4F092508d8B591811c33045809622274014', //avax
    43113: '0x1541f8ee72967E2f9c6BCBD68B61D6f38b1D15B9', // '0xB68a8b09853D374BbD4c5B035c1B8964b74Ad55C', //avax
  },
  IDOToken: {
    56: '',
    97: '',
  },
  Currency: {
    56: '',
    97: '',
  },
  InvestmentsInfo: {
    56: '',
    97: '',
  },
  airdrop: {
    56: '',
    97: '',
  },
};

export default addresses;
