import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid';
import {
  getUsersById,
  updateUsers,
  getProjectById,
  bulkRegistration,
  asvaPrice,
} from '../../config/config';
import { ToastContainer, toast } from 'react-toastify';
import ProjectInfo from './projectInfo';
import axiosHelper from '../../helpers/axios-helper.js';
import openApiAxiosHelper from '../../helpers/openApi.js';
import { useParams } from 'react-router-dom';
import './index.css';
import AlertDialog from './modalTier';
import {
  checkWalletAddress,
  getAsvaStakingDetails,
  getLPStakedetails,
} from '../../helpers/web3-helpers';
import Web3 from 'web3';
import AlertDialogPublic from './modalPublicAllocation';
const columns = [
  {
    field: 'walletAddress',
    filterable: true,
    headerName: 'Wallet Address',
    width: 450,
  },
  {
    field: `qualifiedForTier`,
    headerName: 'Qualified For Tier',
    width: 160,
    valueGetter: (props) => {
      if (props.value === -1) {
        return 'NA';
      } else if (props.value === 1) {
        return 'Community';
      } else {
        return props.value - 1;
      }
    },
  },
  {
    field: 'totalAsvaStaked',
    headerName: 'Total Asva Staked',
    width: 160,
  },
  {
    field: 'totalLPStaked',
    headerName: 'Total LP Staked',
    width: 160,
  },
  { field: 'kycStatus', headerName: 'Kyc Status', width: 160 },

  {
    field: 'isVerified',
    headerName: 'Verified Status',
    width: 160,
  },
];
function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function DataTable() {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [tableData, setTableData] = useState();
  const [typeOfProject, setTypeOfProject] = useState();
  const [chainIds, setChainIds] = useState();
  const [numberOfUser, setNumberOfUser] = useState('');
  const [tierCount, setTierCount] = useState({
    tier1: 0,
    tier2: 0,
    tier3: 0,
    tier4: 0,
    tier5: 0,
    tier6: 0,
  });
  const [selectionModel, setSelectionModel] = useState([]);
  const [tierArray, setTierArray] = useState([]);
  const [tierAllowcation, setTierAllowcation] = useState('-1');
  const [csvFile, setCSVFile] = useState('');
  const [asvaTokenPrice, setAsvaTokenPrice] = useState(0);
  const [lPValue, setLPValue] = useState(0);
  const [inputValues, setInputValues] = useState({
    tier1: 0,
    tier2: 0,
    tier3: 0,
    tier4: 0,
    tier5: 0,
    asvaToken: 0,
    LPToken: 0,
  });

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  async function fetchData() {
    let result = await axiosHelper(
      `${getUsersById}?pageNo=1&noOfRecords=10`,
      'POST',
      null,
      { id }
    );
    // setTableData(result.data.data);
    var row = result.data.data.map(function (el) {
      var o = Object.assign({}, el);
      o.id = o._id;
      return o;
    });
    setTableData(row);
  }
  async function fetchProject() {
    const result = await axiosHelper(
      getProjectById,
      'POST',
      null,
      JSON.stringify({ id: id })
    );

    let nooftier = result.data.data.noOfTier;
    let arr = [];
    for (var i = 1; i <= nooftier; i++) {
      arr.push(i);
    }
    setTierArray(arr);
    setTypeOfProject(result.data.data.typeOfProject);
    const hex = parseInt(
      result.data.data.projectNetwork.networkId,
      10
    ).toString(16);
    setChainIds(hex);
    const asvaVal = await openApiAxiosHelper(asvaPrice, 'GET', null);
    let asvatokenPrice = asvaVal.data.asva.usd;
    setAsvaTokenPrice(asvatokenPrice);
  }
  useEffect(() => {
    fetchData();
    fetchProject();
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const chooseRandom = (arr, num = 1) => {
    const res = [];
    for (let i = 0; i < num; ) {
      const random = Math.floor(Math.random() * arr.length);
      if (res.indexOf(arr[random]) !== -1) {
        continue;
      }
      res.push(arr[random]);
      i++;
    }
    return res;
  };
  const autoAllocation = async () => {
    var newArray = tableData.filter((el) => {
      if (el.qualifiedForTier === 0) {
        return el;
      }
    });

    if (
      numberOfUser !== '0' &&
      numberOfUser !== '' &&
      newArray.length >= numberOfUser
    ) {
      let RandomAddress = chooseRandom(newArray, numberOfUser);

      let newObj = {};
      RandomAddress.forEach(({ id }) => {
        newObj[id] = {
          qualifiedForTier: '4',
          isVerified: true,
        };
      });
      await axiosHelper(updateUsers, 'PUT', null, newObj);
      await fetchData();
      setTierAllowcation('0');
      setOpen(false);
    } else {
      toast.error('Count is greater than unallocated tier addresses', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const AssignTier = async () => {
    console.log(tierAllowcation);
    let object = {};
    selectionModel.forEach((id) => {
      object[id] = {
        qualifiedForTier: tierAllowcation,
        isVerified: true,
      };
    });
    await axiosHelper(updateUsers, 'PUT', null, object);
    await fetchData();
    setTierAllowcation('0');
  };
  const copyToClipBoard = (e) => {
    let copyVal = e.formattedValue;
    navigator.clipboard.writeText(copyVal);
  };
  const onChangeHandler = (event) => {
    setNumberOfUser(event.target.value);
  };

  const toastWrapper = (type, message) => {
    const toastMap = {
      error: toast.error,
      success: toast.success,
      warn: toast.warn,
    };
    return toastMap[type](message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const uploadCSVFileAddresses = async () => {
    if (csvFile.length == 0) {
      toastWrapper('error', 'Please select a file to upload');
    } else {
      const allFileRows = csvFile.split('\r\n');
      const headerItems = allFileRows[0].split(',');
      if (allFileRows.length > 201) {
        toastWrapper('error', 'Maximum capacity is 200 addresses at once!');
        return;
      }
      if (
        headerItems[0].toUpperCase() !== 'ADDRESS' ||
        headerItems[1].toUpperCase() !== 'TIER'
      ) {
        toastWrapper(
          'error',
          'Please upload a file with the correct header format'
        );
      } else {
        let allAddressesToAdd = [];
        for (let i = 1; i < allFileRows.length; i++) {
          let rowToUpload = allFileRows[i].split(',');
          if (rowToUpload[0] === '') continue;
          if (!checkWalletAddress(rowToUpload[0])) {
            toastWrapper(
              'error',
              `${rowToUpload[0]} is not a valid Ethereum address`
            );
            break;
          } else {
            let updateObj = {
              projectId: parseInt(id),
              walletAddress: rowToUpload[0],

              qualifiedForTier:
                typeOfProject !== 'Community IDO'
                  ? parseInt(rowToUpload[1]) + 1
                  : parseInt(rowToUpload[1]),

              qualifiedForTiers: rowToUpload[1],
              isVerified: true,
            };
            allAddressesToAdd.push(updateObj);
            // if (typeOfProject !== 'Community IDO') {
            //   let updateObj = {
            //     projectId: parseInt(id),
            //     walletAddress: rowToUpload[0],

            //     qualifiedForTier: parseInt(rowToUpload[1]) + 1,

            //     qualifiedForTiers: rowToUpload[1],
            //     isVerified: true,
            //   };
            //   allAddressesToAdd.push(updateObj);
            // } else {
            //   let updateObj = {
            //     projectId: parseInt(id),
            //     walletAddress: rowToUpload[0],

            //     qualifiedForTier: parseInt(rowToUpload[1]),

            //     qualifiedForTiers: rowToUpload[1],
            //     isVerified: true,
            //   };
            //   allAddressesToAdd.push(updateObj);
            // }
          }
        }
        const result = await axiosHelper(bulkRegistration, 'POST', null, {
          userArray: allAddressesToAdd,
        });
        if (result.data.message === 'User successfully updated!') {
          const newAddressesAdded = result.data.data.nUpserted;
          const modifiedAddresses = result.data.data.nModified;
          toastWrapper(
            'success',
            `${newAddressesAdded} new addresses added and ${modifiedAddresses} addresses modified!`
          );
        } else {
          toastWrapper('error', 'There seems to have been an issue!');
        }
      }
    }
  };
  console.log(typeOfProject, 'typeOfProject');
  const handleFileChange = (e) => {
    const reader = new FileReader();
    reader.onloadend = function (ev) {
      const content = ev.target.result;
      setCSVFile(content);
    };
    reader.readAsText(e);
  };
  const whitlistTierCount = () => {
    var newArray1 = tableData?.filter((el) => {
      if (el.qualifiedForTier === 1) {
        return el;
      }
    });
    var newArray2 = tableData?.filter((el) => {
      if (el.qualifiedForTier === 2) {
        return el;
      }
    });
    var newArray3 = tableData?.filter((el) => {
      if (el.qualifiedForTier === 3) {
        return el;
      }
    });
    var newArray4 = tableData?.filter((el) => {
      if (el.qualifiedForTier === 4) {
        return el;
      }
    });
    var newArray5 = tableData?.filter((el) => {
      if (el.qualifiedForTier === 5) {
        return el;
      }
    });
    var newArray6 = tableData?.filter((el) => {
      if (el.qualifiedForTier === 6) {
        return el;
      }
    });

    if (
      newArray1 !== undefined &&
      newArray2 !== undefined &&
      newArray3 !== undefined &&
      newArray4 !== undefined
    ) {
      setTierCount({
        tier1: newArray1.length,
        tier2: newArray2.length,
        tier3: newArray3.length,
        tier4: newArray4.length,
        tier5: newArray5.length,
        tier6: newArray6.length,
      });
    }
    // console.log(tierCount);
  };
  console.log(tierArray.length);
  const roundOffBuyValue = (price) => {
    if (typeof price !== 'string') price = price.toString();
    let roundOffPrice = price;
    if (price.indexOf('.') !== -1) {
      const decimalIndex = price.indexOf('.');
      roundOffPrice =
        price.substring(0, decimalIndex + 1) +
        price.substring(decimalIndex + 1, decimalIndex + 4);
      return parseFloat(roundOffPrice);
    }
    return parseFloat(roundOffPrice);
  };
  const allocateTier = async () => {
    if (
      inputValues.tier1 !== 0 &&
      inputValues.tier2 !== 0 &&
      inputValues.tier3 !== 0
    ) {
      let newArr = [];
      console.log(tableData);
      const arr = tableData?.map((e) => {
        let cal =
          e.totalAsvaStaked * parseFloat(inputValues.asvaToken) +
          e.totalLPStaked * parseFloat(inputValues.LPToken);
        if (tierArray.length === 4) {
          if (cal < inputValues.tier1 * parseFloat(inputValues.asvaToken)) {
            newArr.push({ id: e._id, tier: 0 });
          } else if (
            cal >= inputValues.tier1 * parseFloat(inputValues.asvaToken) &&
            cal < inputValues.tier2 * parseFloat(inputValues.asvaToken)
          ) {
            newArr.push({ id: e._id, tier: 2 });
          } else if (
            cal >= inputValues.tier2 * parseFloat(inputValues.asvaToken) &&
            cal < inputValues.tier3 * parseFloat(inputValues.asvaToken)
          ) {
            newArr.push({ id: e._id, tier: 3 });
          } else if (
            cal >= inputValues.tier3 * parseFloat(inputValues.asvaToken) &&
            cal < inputValues.tier4 * parseFloat(inputValues.asvaToken)
          ) {
            newArr.push({ id: e._id, tier: 4 });
          } else {
            newArr.push({ id: e._id, tier: 5 });
          }
        } else if (tierArray.length === 5) {
          if (cal < inputValues.tier1 * parseFloat(inputValues.asvaToken)) {
            newArr.push({ id: e._id, tier: 0 });
          } else if (
            cal >= inputValues.tier1 * parseFloat(inputValues.asvaToken) &&
            cal < inputValues.tier2 * parseFloat(inputValues.asvaToken)
          ) {
            newArr.push({ id: e._id, tier: 2 });
          } else if (
            cal >= inputValues.tier2 * parseFloat(inputValues.asvaToken) &&
            cal < inputValues.tier3 * parseFloat(inputValues.asvaToken)
          ) {
            newArr.push({ id: e._id, tier: 3 });
          } else if (
            cal >= inputValues.tier3 * parseFloat(inputValues.asvaToken) &&
            cal < inputValues.tier4 * parseFloat(inputValues.asvaToken)
          ) {
            newArr.push({ id: e._id, tier: 4 });
          } else if (
            cal >= inputValues.tier4 * parseFloat(inputValues.asvaToken) &&
            cal < inputValues.tier5 * parseFloat(inputValues.asvaToken)
          ) {
            newArr.push({ id: e._id, tier: 5 });
          } else {
            newArr.push({ id: e._id, tier: 6 });
          }
        } else {
          if (cal < inputValues.tier1 * parseFloat(inputValues.asvaToken)) {
            newArr.push({ id: e._id, tier: 0 });
          } else if (
            cal >= inputValues.tier1 * parseFloat(inputValues.asvaToken) &&
            cal < inputValues.tier2 * parseFloat(inputValues.asvaToken)
          ) {
            newArr.push({ id: e._id, tier: 2 });
          } else if (
            cal >= inputValues.tier2 * parseFloat(inputValues.asvaToken) &&
            cal < inputValues.tier3 * parseFloat(inputValues.asvaToken)
          ) {
            newArr.push({ id: e._id, tier: 3 });
          } else {
            newArr.push({ id: e._id, tier: 4 });
          }
        }
      });
      let object = {};
      if (newArr !== undefined) {
        tableData.forEach(({ id }) => {
          object[id] = {
            qualifiedForTier: newArr.filter((e) => e.id === id)[0]?.tier,
            isVerified: true,
          };
        });
      }
      console.log(newArr);
      await axiosHelper(updateUsers, 'PUT', null, object);
      await fetchData();
      setOpen(false);
    } else {
      toastWrapper('error', 'please Enter Tier Allocations');
    }
  };
  const noOftokenStake = async (data, chainId) => {
    console.log(chainId);
    let walletAddress = data?.map((e) => {
      return e.walletAddress;
    });
    let stakeInfo = await getAsvaStakingDetails(walletAddress, chainId);
    const stakeAndLp = stakeInfo.map((a) => {
      return {
        walletAddress: a[0],
        asva: parseInt(Web3.utils.fromWei(a[1], 'ether')),
        Lp: parseInt(Web3.utils.fromWei(a[2], 'ether')),
      };
    });
    console.log(stakeAndLp);
    let object = {};
    if (stakeAndLp !== undefined) {
      tableData.forEach(({ id, walletAddress }) => {
        object[id] = {
          totalAsvaStaked: stakeAndLp.filter(
            (e) => e.walletAddress === walletAddress
          )[0]?.asva,
          totalLPStaked: stakeAndLp.filter(
            (e) => e.walletAddress === walletAddress
          )[0]?.Lp,
        };
      });
    }
    await axiosHelper(updateUsers, 'PUT', null, object);
    await fetchData();
  };
  const lpValue = async () => {
    let LpStakeVal = await getLPStakedetails();
    if (LpStakeVal !== undefined) {
      setLPValue(
        (2 / parseInt(LpStakeVal.totalSupply)) *
          parseInt(LpStakeVal.reserves._reserve0)
      );
    }
  };
  useEffect(() => {
    lpValue();
    setInputValues({
      ...inputValues,
      asvaToken: asvaTokenPrice,
      LPToken: roundOffBuyValue(lPValue),
    });
  }, [asvaTokenPrice, lPValue]);
  useEffect(() => {
    whitlistTierCount();
  }, [tableData]);
  return (
    <div className='projects'>
      <ToastContainer />
      <ProjectInfo id={id} />
      <div className='launch-Ido' style={{ height: 600, width: '95%' }}>
        <div className='head-whitList'>
          <h2 className='text-center'>White listed User</h2>

          <div>
            {typeOfProject === 'Community IDO' ? (
              <div className='modalSpace'>
                <AlertDialog
                  autoAllocation={autoAllocation}
                  onChangeHandler={onChangeHandler}
                  handleClickOpen={handleClickOpen}
                  open={open}
                  handleClose={handleClose}
                />
              </div>
            ) : null}
            <div className='view-details-title-row'>
              <div className='address-upload-section'>
                <input
                  type='file'
                  accept='.csv, application/vnd.ms-excel'
                  className='form-control-file'
                  id='csv-file-input'
                  onChange={(e) => {
                    handleFileChange(e.target.files[0]);
                  }}
                />
                <button
                  className='btn btn-primary upload-address-button'
                  onClick={uploadCSVFileAddresses}
                >
                  Upload Addresses
                </button>
              </div>
              <select
                className='form-select tier-select-dropdown'
                onChange={(e) => setTierAllowcation(e.target.value)}
                aria-label='Default select example'
              >
                <option value='0'>NA</option>
                {typeOfProject === 'Community IDO' ? (
                  <option value='1'> Community</option>
                ) : (
                  <>
                    {tierArray?.map((items) => {
                      return (
                        <option value={(items + 1).toString()}>
                          Tier {items}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
              <button className='btn btn-primary mr-5' onClick={AssignTier}>
                Verify
              </button>
            </div>
          </div>
        </div>
        <hr />
        {typeOfProject !== 'Community IDO' ? (
          <div className='autoAllocateDiv'>
            <label>
              <b> Asva Price :$</b> {asvaTokenPrice}
            </label>
            <label>
              <b>LP Price :$</b> {roundOffBuyValue(lPValue)}
            </label>
            <button
              className='btn btn-outline-primary'
              onClick={() => noOftokenStake(tableData, chainIds)}
            >
              Get User Token Staked
            </button>
            <div className='modalSpace'>
              <AlertDialogPublic
                autoAllocation={allocateTier}
                handleOnChange={handleOnChange}
                handleClickOpen={handleClickOpen}
                open={open}
                inputValues={inputValues}
                handleClose={handleClose}
                tierArray={tierArray}
              />
            </div>
          </div>
        ) : null}
        <div className='allocatedDiv'>
          <p>Total Whitlist Count = {tableData && tableData.length}</p>
          {typeOfProject === 'Community IDO' ? (
            <p> Allocated :: Community ={tierCount.tier1} </p>
          ) : (
            <p>
              Allocated :: Tier 1= {tierCount.tier2}
              <span>
                Tier 2=
                {tierCount.tier3}
              </span>
              <span>
                Tier 3=
                {tierCount.tier4}
              </span>
              {tierArray.length >= 4 && (
                <span>
                  Tier 4=
                  {tierCount.tier5}
                </span>
              )}
              {tierArray.length >= 5 && <span>Tier 5={tierCount.tier6}</span>}
            </p>
          )}
        </div>
        {tableData && (
          <DataGrid
            rows={tableData}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            components={{
              Toolbar: CustomToolbar,
            }}
            onCellClick={copyToClipBoard}
          />
        )}
      </div>
    </div>
  );
}
export default DataTable;
// const arr = [2, 5, 4, 45, 32, 46, 78, 87, 98, 56, 23, 12];
// const chooseRandom = (arr, num = 1) => {
//   const res = [];
//   for (let i = 0; i < num; ) {
//     const random = Math.floor(Math.random() * arr.length);
//     if (res.indexOf(arr[random]) !== -1) {
//       continue;
//     }
//     res.push(arr[random]);
//     i++;
//   }
//   return res;
// };
// console.log(chooseRandom(top100Films, 10));
