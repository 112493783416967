import React, { useEffect, useState } from "react";
import MultipleDatePicker from "react-multiple-datepicker";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
const FirstPage = (props) => {
  const { launchData, handleInput } = props;
  const [values, setValues] = useState(
    [].map((number) =>
      new DateObject().set({
        day: number,
        hour: number,
        minute: number,
        second: number,
      })
    )
  );
  let arr = values.map((item) => {
    return new Date(item);
  });
  const addDateTime = (arr) => {
    handleInput("times", arr);
  };
  return (
    <div className="container">
      <div className="col">
        <label htmlFor="token-address" className="col-form-label">
          Token Contract Address:
        </label>
        <input
          type="text"
          required
          defaultValue={launchData.token}
          // onChange={(e) => handleInput("token", e.target.value)}
          className="form-control"
          id="token-address"
          disabled
        />
      </div>
      <div className="col">
        <label htmlFor="currency-address" className="col-form-label">
          Project Contract Address:
        </label>
        <input
          type="text"
          required
          defaultValue={launchData.contract}
          // onChange={(e) => handleInput("currency", e.target.value)}
          className="form-control"
          id="currency-address"
          disabled
        />
      </div>

      <div className="col">
        <label htmlFor="token-address" className="col-form-label">
          Project Id:
        </label>
        <input
          type="text"
          required
          defaultValue={launchData.idoID}
          // onChange={(e) => handleInput("token", e.target.value)}
          className="form-control"
          id="token-address"
          disabled
        />
      </div>
      <div className="col dateTime">
        <label htmlFor="currency-address" className="col-form-label m-3">
          Dates & Time:
        </label>
        {/* <MultipleDatePicker
          onSubmit={(dates) => {
            handleInput("times", dates);
            console.log(dates);
          }}
        /> */}
        <DatePicker
          value={values}
          onChange={setValues}
          format="MM/DD/YYYY HH:mm:ss"
          multiple
          plugins={[
            <TimePicker position="bottom" />,
            <DatePanel markFocused />,
          ]}
        />
        <button
          className="btn btn-primary m-3"
          onClick={() => addDateTime(arr)}
        >
          Add Date & Time
        </button>
      </div>
    </div>
  );
};

export default FirstPage;
