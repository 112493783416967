import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import BigNumber from 'bignumber.js/bignumber';
import { useParams, useNavigate } from 'react-router-dom';
import './index.css';
import WizOne from './WizOne';
import WizTwo from './WizTwo';
import WizThree from './WizThree';
import axiosHelper from '../../helpers/axios-helper';
import { getProjectById, addIDOToLaunch } from '../../config/config';
import {
  createAsvaContract,
  switchNetwork,
  getTokenInfo,
} from '../../helpers/web3-helpers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
const IdoLaunch = () => {
  let navigate = useNavigate();
  const [submitted, handleSubmitted] = useState(false);
  const [chainId, setChainId] = useState(0);
  const [network, setNetwork] = useState();
  const [tokenInfo, setTokenInfo] = useState();
  const [noOfTier, setnoOfTier] = useState();
  const [currencyAbi, setCurrencyAbi] = useState();
  const [decimalBaseData, setDecimalBaseData] = useState({});
  const [launchData, setLaunchData] = useState({
    token: '',
    currency: '',
    roundOneStart: '',
    roundOneEnd: '',
    roundTwoEnd: '',
    roundTwoStart: '',
    price: '',
    totalAmount: '',
    maxAmountforTier: [],
    presaleProjectID: '',
    tier: [],
    tokenSymbol: '',
  });
  const { id } = useParams();

  const roundOffBuyValue = (price) => {
    if (typeof price !== 'string') price = price.toString();
    let roundOffPrice = price;
    if (price.indexOf('.') !== -1) {
      const decimalIndex = price.indexOf('.');
      roundOffPrice =
        price.substring(0, decimalIndex + 1) +
        price.substring(decimalIndex + 1, decimalIndex + 3);
      return parseFloat(roundOffPrice);
    }
    return parseFloat(roundOffPrice);
  };
  useEffect(() => {
    async function fetchData() {
      const result = await axiosHelper(
        getProjectById,
        'POST',
        null,
        JSON.stringify({ id: id })
      );
      const { data } = result.data;
      console.log(parseInt(data.currDecimal));
      setTokenInfo(parseInt(data.currDecimal));
      setnoOfTier(data.noOfTier);
      setCurrencyAbi(data.abi.currencyABI);
      setNetwork(data.projectNetwork.networkId);
      const hex = parseInt(data.projectNetwork.networkId, 10).toString(16);
      setChainId(hex);
      // console.log(chainId);
      setLaunchData({
        projectName: data.projectName,
        typeOfProject: data.typeOfProject,
        token: data.smartContractAddress.token,
        currency: data.smartContractAddress.currency,
        roundOneStart: moment(data.idoDates.roundOneStartDate).format(
          `YYYY-MM-DDTHH:mm:ss`
        ),
        roundOneEnd: moment(data.idoDates.roundOneEndDate).format(
          `YYYY-MM-DDTHH:mm:ss`
        ),
        roundTwoStart: moment(data.idoDates.roundTwoStartDate).format(
          `YYYY-MM-DDTHH:mm:ss`
        ),
        roundTwoEnd: moment(data.idoDates.roundTwoEndDate).format(
          `YYYY-MM-DDTHH:mm:ss`
        ),
        price: data.tokenDetails.tokenPrice,
        totalAmount: data.tokenDetails.totalFundraise,
        maxAmountforTier: [],
        // maxAmountforTierTwo: '0',
        // maxAmountforTierThree: '0',
        // maxAmountforTierFour: '0',
        presaleProjectID: id,
        tier: [],
        tokenSymbol: data.tokenDetails.tokenSymbol,
      });
    }
    if (parseInt(id) !== 0) {
      fetchData();
    }
  }, []);
  console.log(launchData.price);

  useEffect(() => {
    switchNetwork(network);
  }, [network]);
  // const symbolAndDecimal = async (currencyAbi, launchData) => {
  //   try {
  //     let Info = await getTokenInfo(JSON.parse(currencyAbi), launchData);
  //     if (Info !== undefined) {
  //       setTokenInfo(Info);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  // if (currencyAbi !== '' && launchData.currency !== '') {
  //   symbolAndDecimal(currencyAbi, launchData.currency);
  // }

  const convertToDecimal = (amount, decimal) => {
    const _amount = !amount ? '0' : amount;
    return new BigNumber(_amount)
      .times(new BigNumber(10).pow(decimal))
      .toString();
  };
  console.log(convertToDecimal(launchData.price, tokenInfo).toString());
  useEffect(() => {
    const maxAllocation = Object.values(launchData.maxAmountforTier);
    let maxAllocationValue;
    if (tokenInfo === 18) {
      maxAllocationValue = maxAllocation.map((item) => {
        return Web3.utils.toWei(
          roundOffBuyValue(item / launchData.price).toString()
        );
      });
    } else if (tokenInfo === 6) {
      maxAllocationValue = maxAllocation.map((item) => {
        return convertToDecimal(
          roundOffBuyValue(item / launchData.price),
          tokenInfo
        ).toString();
      });
    } else {
      console.log('error in maxAllocation decimal ');
    }
    if (launchData.typeOfProject !== 'Community IDO') {
      maxAllocationValue?.unshift('0');
    }
    const tier = Object.values(launchData.tier);
    let tierValue;
    if (tokenInfo === 18) {
      tierValue = tier.map((item) => {
        return Web3.utils.toWei(
          roundOffBuyValue(item / launchData.price).toString()
        );
      });
    } else if (tokenInfo === 6) {
      tierValue = tier.map((item) => {
        return convertToDecimal(
          roundOffBuyValue(item / launchData.price),
          tokenInfo
        ).toString();
      });
    } else {
      console.log('error in tierAllocation decimal ');
    }
    if (launchData.typeOfProject !== 'Community IDO') {
      tierValue?.unshift('0');
    }
    let totalAmounts = 0;
    for (let i = 0; i < tier.length; i++) {
      totalAmounts += roundOffBuyValue(tier[i] / launchData.price);
    }
    console.log(totalAmounts);
    console.log(tierValue);

    if (tokenInfo === 18) {
      setDecimalBaseData({
        _token: launchData.token,
        _currency: launchData.currency,
        _round1Start: parseInt(
          new Date(launchData.roundOneStart).getTime() / 1000
        ).toString(),
        _round1End: parseInt(
          new Date(launchData.roundOneEnd).getTime() / 1000
        ).toString(),
        _round2Start: parseInt(
          new Date(launchData.roundTwoStart).getTime() / 1000
        ).toString(),
        _round2End: parseInt(
          new Date(launchData.roundTwoEnd).getTime() / 1000
        ).toString(),
        _price: Web3.utils.toWei(launchData.price.toString()),
        _totalAmount: Web3.utils.toWei(
          roundOffBuyValue(totalAmounts).toString()
        ),
        _maxAmountThatCanBeInvestedInTiers: maxAllocationValue,

        _presaleProjectID: id,
        _tiersAllocation: tierValue,
      });
    } else if (tokenInfo === 6) {
      setDecimalBaseData({
        _token: launchData.token,
        _currency: launchData.currency,
        _round1Start: parseInt(
          new Date(launchData.roundOneStart).getTime() / 1000
        ).toString(),
        _round1End: parseInt(
          new Date(launchData.roundOneEnd).getTime() / 1000
        ).toString(),
        _round2Start: parseInt(
          new Date(launchData.roundTwoStart).getTime() / 1000
        ).toString(),
        _round2End: parseInt(
          new Date(launchData.roundTwoEnd).getTime() / 1000
        ).toString(),
        _price: convertToDecimal(launchData.price, tokenInfo).toString(),
        _totalAmount: convertToDecimal(totalAmounts, tokenInfo).toString(),
        _maxAmountThatCanBeInvestedInTiers: maxAllocationValue,
        _presaleProjectID: id,
        _tiersAllocation: tierValue,
      });
    } else {
      console.log('error');
    }
  }, [launchData]);
  // console.log(
  //   "double wrap",
  //   roundOffBuyValue(
  //     roundOffBuyValue(launchData.tierTwo / launchData.price) +
  //       roundOffBuyValue(launchData.tierOne / launchData.price) +
  //       roundOffBuyValue(launchData.tierThree / launchData.price) +
  //       roundOffBuyValue(launchData.tierFour / launchData.price)
  //   )
  // );
  // console.log(
  //   "single wrap",
  //   roundOffBuyValue(launchData.tierTwo / launchData.price) +
  //     roundOffBuyValue(launchData.tierOne / launchData.price) +
  //     roundOffBuyValue(launchData.tierThree / launchData.price) +
  //     roundOffBuyValue(launchData.tierFour / launchData.price)
  // );
  // console.log(roundOffBuyValue(launchData.tierOne / launchData.price));
  // console.log(roundOffBuyValue(launchData.tierThree / launchData.price));
  // console.log(roundOffBuyValue(launchData.tierTwo / launchData.price));
  // console.log(roundOffBuyValue(launchData.tierFour / launchData.price));
  useEffect(() => {
    console.log(decimalBaseData);
    async function createNewContract() {
      const contractInstance = await createAsvaContract(
        decimalBaseData,
        chainId
      );
      if (contractInstance) {
        toast.success('Ido Launched ', {
          position: 'top-right',
          // autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        handleSubmitted(false);
      }
    }

    if (submitted) {
      console.log(decimalBaseData);
      console.log(launchData);
      if (
        launchData.tier !== ''
        // launchData.totalAmount ===
        //   parseFloat(launchData.tierOne) +
        //     parseFloat(launchData.tierTwo) +
        //     parseFloat(launchData.tierThree) +
        //     parseFloat(launchData.tierFour) &&
        // launchData.roundOneStart !== '' &&
        // launchData.roundOneEnd !== '' &&
        // launchData.roundTwoStart !== '' &&
        // launchData.roundTwoEnd !== '' &&
        // launchData.totalAmount !== '' &&
        // launchData.maxAmountforTierOne !== '' &&
        // launchData.maxAmountforTierTwo !== '' &&
        // launchData.maxAmountforTierThree !== '' &&
        // launchData.maxAmountforTierFour !== ''
      ) {
        createNewContract();
      } else {
        toast.error('Please Check All Fields ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleSubmitted(false);
      }
    }
  }, [submitted]);
  // console.log(launchData.tokenSymbol);
  const [page, setPage] = useState(1);

  const handleSubmit = () => {
    handleSubmitted(true);
  };
  const handleInput = (parant, val, ind) => {
    const temp = {
      ...launchData,
    };
    if (ind >= 0) {
      temp[parant] = { ...temp[parant], [ind]: val };
    } else {
      temp[parant] = val;
    }
    setLaunchData(temp);
  };
  const preview = () => {
    if (page <= 1) return;
    setPage((page) => page - 1);
  };
  const next = () => {
    if (page >= 3) return;
    setPage((page) => page + 1);
  };
  console.log();
  return (
    <div className='add_page'>
      <ToastContainer />
      <div className='inner_add_page card'>
        <h2>IDO Launch </h2>
        <div className='projectNameType'>
          {launchData.projectName === undefined ? (
            <p>loading....</p>
          ) : (
            <p>{`${launchData.projectName} (${launchData.typeOfProject})`}</p>
          )}
        </div>
        <div className='stepper-launch'>
          <hr />
          <div className='btn-div'>
            <button
              className={page === 1 ? 'active-btn' : 'btn-stepper'}
              onClick={() => setPage(1)}
            >
              1
            </button>
            <button
              className={page === 2 ? 'active-btn' : 'btn-stepper'}
              onClick={() => setPage(2)}
            >
              2
            </button>
            <button
              className={page === 3 ? 'active-btn' : 'btn-stepper'}
              onClick={() => setPage(3)}
            >
              3
            </button>
          </div>
        </div>
        {page === 1 && (
          <WizOne
            launchData={launchData}
            handleInput={handleInput}
            tokenAddress={launchData.token}
            currencyAddress={launchData.currency}
          />
        )}
        {page === 2 && (
          <WizTwo
            launchData={launchData}
            handleInput={handleInput}
            tokenPrice={launchData.price}
            projectId={id}
            noOfTier={noOfTier}
          />
        )}
        {page === 3 && (
          <WizThree
            launchData={launchData}
            handleInput={handleInput}
            updatedData={launchData}
            roundOffBuyValue={roundOffBuyValue}
            noOfTier={noOfTier}
          />
        )}
        <div className='btn_addnext mt-3'>
          <button className='btn btn-outline-primary' onClick={preview}>
            Preview
          </button>
          {page === 3 ? (
            <button className='btn btn-success' onClick={handleSubmit}>
              Submit
            </button>
          ) : (
            <button className='btn btn-outline-primary' onClick={next}>
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default IdoLaunch;
