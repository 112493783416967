import React from "react";
import { useState, useEffect } from "react";
import axiosHelper from "../../helpers/axios-helper.js";
import { EditAdminUser } from "../../config/config.js";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./manage.css"
const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [tableData, setTableData] = useState({
    name:"",
    email:"",
    password:"",
    phone:"",
    role:"ADMIN",
    isActive:true
  });
  let [showPassword, setShowPassword] = useState(false);
  
  
  useEffect(() => {
    async function fetchData() {
      let result = await axiosHelper(
        `${EditAdminUser}/${id}`,
        "GET",
        null,
        JSON.stringify({id:id})
      );
      const {data} = result.data;
      console.log(data);
    setTableData({
      name:data.name,
      email:data.email,
      phone:data.phone.slice(3),
      role:data.role,
      isActive:data.isActive
    })
    }
    fetchData();
    
    
  }, []);
 
  const handleInput = (name, val) => {
    const temp = {
      ...tableData,
    };
    temp[name] = val;
    setTableData(temp);
  };
  console.log(tableData);
  const handleSubmit =async(e)=>{
    e.preventDefault();
    
    try {
        let res = await axiosHelper(
          `${EditAdminUser}/${id}`,
          "PUT",
          null,
          {            
            email: tableData.email,
            name: tableData.name,
            phone: `+91${tableData.phone}`,
            role: tableData.role
        }
        );
        toast.success("User Added", {
            position: "top-right",
            // autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        
      } catch (err) {
        console.log(err)
        if (err?.response?.status === 500) {
            setMessage('There was a problem with the server');
        } else if (err?.response?.status === 400) {
          setMessage(err?.response?.data?.errorMsg);
          console.log(err?.response?.data?.errorMsg);
          toast.error(`${err?.response?.data?.errorMsg}`, {
            position: "top-right",
            // autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
            
        } else {
           
        }
      }
   
    
  }

  return (
    <div className="adduser">
        <ToastContainer />
      <div className="card ">
        <form class="row g-3 needs-validation p-3" onSubmit={handleSubmit} noValidate>
          <div class="col-6">
            <label for="validationCustom01" class="form-label">
               Name
            </label>
            <input
              type="text"
              class="form-control"
              name="name"
              id="validationCustom01"
              value={tableData.name}
              onChange={(e) =>
                handleInput("name", e.target.value)
              }
              required
            />
            <div class="valid-feedback">Looks good!</div>
          </div>
          <div class="col-6">
            <label for="validationCustomUsername" class="form-label">
              Email
            </label>
            <input
              type="text"
              class="form-control"
              id="validationCustom02"
              value={tableData.email}
              onChange={(e) =>
                handleInput("email", e.target.value)
              }
              required
            />
        
          </div>
          
          <div class="col-6">
            <label for="validationCustomUsername" class="form-label">
              Phone
            </label>
            <input
              type="number"
              class="form-control"
              id="validationCustom05"
              value={tableData.phone}
              onChange={(e) =>
                handleInput("phone", e.target.value)
              }
              required
            />
            <div class="valid-feedback">Looks good!</div>
          </div>
          
          <div class="col-6">
            <label for="validationCustom04" class="form-label">
              Role
            </label>
            <select class="form-select" id="validationCustom04" onChange={(e) =>
                handleInput("role", e.target.value)
              } required>
              <option  value="ADMIN">
                Admin
              </option>
              <option value="SUPER_ADMIN">Super Admin</option>
              <option value="INTERNAL_ADMIN">Internal Admin</option>
            </select>
            <div class="invalid-feedback">Please select a valid state.</div>
          </div>
          
          <div class="col-12">
            <div class="form-check">
            <label for="validationCustom05" class="form-label">
              Is Active
            </label>
              <input
                class="form-check-input mt-1"
                type="checkbox"
                name="isActive"
                value={tableData.isActive}
                
                defaultChecked={tableData.isActive}
                onClick={(e) =>
                    handleInput("isActive", tableData.isActive =! tableData.isActive)
                  } 
                
              />
              
            </div>
          </div>
          <div class="col-12">
            <button class="btn btn-primary" type="submit">
              Submit form
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUser;

