import React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { Web3ConnectButton } from '../Web3ConnectButton';
import './index.css';
import axiosHelper from '../../helpers/axios-helper.js';
import { logout } from '../../config/config';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';

const dropDownData = [
  // {
  //   name: "Manage",
  //   href: "/projects",
  //   role: "ADMIN",
  // },
  {
    name: 'Manage User',
    href: '/manage-user',
    role: 'SUPER_ADMIN',
  },
  {
    name: 'Networks',
    href: '/networks',
    role: 'ADMIN',
  },
  {
    name: 'Contracts',
    href: '/contracts',
    role: 'ADMIN',
  },
  {
    name: 'Stake Pool',
    href: '/stake-pool',
    role: 'ADMIN',
  },

  // {
  //   name: "Sale Type",
  //   href: "/sale-type",
  //   role: "ADMIN",
  // },

  {
    name: 'Project Type',
    href: '/project-type',
    role: 'ADMIN',
  },
  {
    name: 'Featured Projects',
    href: '/featured-projects',
    role: 'ADMIN',
  },
  {
    name: 'Project ATH (coingecko)',
    href: '/project-ath',
    role: 'ADMIN',
  },
];

const NavBar = (props) => {
  const [btnText, setBtnText] = React.useState('Manage');
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const adminRole = JSON.parse(localStorage.getItem('userCtx'));
  const role = adminRole.role;
  const id = adminRole._id;
  const logoutUser = async () => {
    try {
      let res = await axiosHelper(`${logout}`, 'DELETE', null, null);
      localStorage.removeItem('token');
      localStorage.removeItem('userCtx');
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <div className='navbar' id='navbarToggleExternalContent'>
      <Link to='/projects'>
        <img
          src={logo}
          alt={'Asva Labs'}
          width={174}
          height={40}
          className='logo'
        />
      </Link>

      {/* <button
        className="btn btn-secondary wallet"
        data-bs-toggle="tooltip"
        onClick={handleWalletLink}>
        {address ? address.substr(0,15)+'...' :'Link your wallet'} */}
      {/* </button> */}

      <div className='links'>
        <div className='navbar-projects'>
          <Link to='/projects'>Projects</Link>
        </div>
        <div className='navbar-transfer'>
          <Link to='/transfer'>Transfer Ownership</Link>
        </div>
        <div className='navbar-launched'>
          <Link to='/launched-claim'>Claim Launched IDOs</Link>
        </div>
        <div className='navbar-launched'>
          <Link to='/launched-idos'>Launched IDOs</Link>
        </div>

        {role === 'SUPER_ADMIN' ? (
          <div className='dropdown' >
            <button
              className='dropdown-toggle custom-button'
              type='button'
              id='dropdownMenuButton2'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              {btnText}
            </button>
            <ul
              className='dropdown-menu dropdown-menu custom-ul max-width-content'
              aria-labelledby='dropdownMenuButton2'
            >
              <div className='custom-div'>
                {dropDownData.map(({ name, href }) => {
                  return (
                    <li
                      key={name}
                      className='linklist2'
                      // onClick={() => setBtnText("Manage")}
                    >
                      <Link className='navbar-custom-launch' to={href}>
                        {name}
                      </Link>
                    </li>
                  );
                })}
              </div>
            </ul>
          </div>
        ) : (
          <div className='dropdown'>
            <button
              className='dropdown-toggle custom-button'
              type='button'
              id='dropdownMenuButton2'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              {btnText}
            </button>
            <ul
              className='dropdown-menu dropdown-menu custom-ul max-width-content'
              aria-labelledby='dropdownMenuButton2'
            >
              <div className='custom-div'>
                {dropDownData.map(({ name, href, role }) => {
                  return (
                    role !== 'SUPER_ADMIN' && (
                      <li
                        key={name}
                        className='linklist2'
                        // onClick={() => setBtnText("Manage")}
                      >
                        <Link className='navbar-custom-launch' to={href}>
                          {name}
                        </Link>
                      </li>
                    )
                  );
                })}
              </div>
            </ul>
          </div>
        )}

        <div className='navbar-launched'>
          <Web3ConnectButton useWalletIcon={false} />
        </div>
        <div className='navbar-launched'>
          <Stack direction='row' spacing={2}>
            <div>
              <Button
                ref={anchorRef}
                id='composition-button'
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}
              >
                <PersonIcon className='m-4 '></PersonIcon>
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement='bottom-start'
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom-start'
                          ? 'left top'
                          : 'left bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id='composition-menu'
                          aria-labelledby='composition-button'
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem>
                            <Link
                              className='changePass'
                              to={`change-password/${id}`}
                            >
                              {' '}
                              Change Password
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={logoutUser}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
