import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosHelper from '../../helpers/axios-helper';
import { getProjectById } from '../../config/config';
import {
  createClaimContract,
  switchNetwork,
  getTokenInfo,
} from '../../helpers/web3-helpers';
import { ToastContainer, toast } from 'react-toastify';
import FirstPage from './firstPage';
import SecondPage from './secondPage';

const ClaimLaunch = () => {
  let navigate = useNavigate();
  const [chainId, setChainId] = useState(0);
  const [network, setNetwork] = useState();
  const [page, setPage] = useState(1);
  const [launchData, setLaunchData] = useState({
    projectName: '',
    idoID: '',
    contract: '',
    token: '',
    times: [],
    percents: [],
  });
  const { id } = useParams();
  useEffect(() => {
    async function fetchData() {
      const result = await axiosHelper(
        getProjectById,
        'POST',
        null,
        JSON.stringify({ id: id })
      );
      const { data } = result.data;
      setNetwork(data.projectNetwork.networkId);
      const hex = parseInt(data.projectNetwork.networkId, 10).toString(16);
      setChainId(hex);
      setLaunchData({
        idoID: data._id,
        contract: data.projectContractAddress,
        token: data.smartContractAddress.token,
        projectName: data.projectName,
        times: [],
        percents: [],
      });
    }
    fetchData();
  }, []);
  useEffect(() => {
    switchNetwork(network);
  }, [network]);

  const handleInput = (parant, val, ind) => {
    const temp = {
      ...launchData,
    };
    if (ind >= 0) {
      temp[parant] = { ...temp[parant], [ind]: val };
    } else {
      temp[parant] = val;
    }
    // console.log(Object.values(temp[parant]));
    setLaunchData(temp);
  };
  const preview = () => {
    if (page <= 1) return;
    setPage((page) => page - 1);
  };
  const next = () => {
    if (page >= 2) return;
    setPage((page) => page + 1);
  };

  const unixTime =
    launchData?.times &&
    launchData?.times?.map?.((item) => {
      return parseInt(new Date(item).getTime() / 1000);
    });
  const percentsArr = Object.values(launchData.percents);
  const percentsIntValue = percentsArr.map((item) => {
    return parseInt(item * 1000);
  });

  const handleSubmit = async () => {
    let obj = {
      idoId: launchData.idoID,
      contract: launchData.contract,
      token: launchData.token,
      times: unixTime,
      percents: percentsIntValue,
    };

    const contractInstance = await createClaimContract(obj, chainId);
    if (contractInstance) {
      toast.success('Claim Launched', {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log(false);
    }
  };

  return (
    <div className='add_page'>
      <ToastContainer />
      <div className='inner_add_page card'>
        <h2> Claim Launch (Input Vesting %) </h2>
        <div className='projectNameType'></div>

        {page === 1 && (
          <FirstPage launchData={launchData} handleInput={handleInput} />
        )}
        {page === 2 && (
          <SecondPage launchData={launchData} handleInput={handleInput} />
        )}

        <div className='btn_addnext mt-3'>
          <button className='btn btn-outline-primary' onClick={preview}>
            Preview
          </button>
          {page === 2 ? (
            <button className='btn btn-success' onClick={handleSubmit}>
              Submit
            </button>
          ) : (
            <button className='btn btn-outline-primary' onClick={next}>
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClaimLaunch;
