import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import axiosHelper from '../../helpers/axios-helper.js';
import copyIcon from '../../assets/images/copy-icon.png';
import Loader from '../Loader/Loader';
import moment from 'moment';
// import { useHistory } from "react-router-dom";

import {
  getAllProjects,
  verifyProject,
  isUpcomingDisabled,
  getProjectById,
} from '../../config/config';
import './index.css';
import Dropdown from '../Dropdown/Dropdown.js';
// import { useParams } from 'react-router-dom';

const Projects = () => {
  const [tableData, setTableData] = useState(null);
  const [arrayData, setArrayData] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filteredarray, setFilteredArray] = useState([]);
  const [wordEntered, setWordEntered] = useState('');
  const [loading, setLoading] = useState(false);
  const [docLink, setDocLink] = useState();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let result = await axiosHelper(`${getAllProjects}`, 'GET');
      setTableData(result.data.data.reverse());
      setLoading(false);
    }
    fetchData();
  }, []);

  const formatDate = (dateToParse) => {
    const date = new Date(dateToParse);
    return `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}`;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tableData?.length - page * rowsPerPage);

  const copyContent = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
  };

  const handleProjectVerificationChange = async (project) => {
    // const selectedProj = tableData.data.filter(
    //   (proj) => proj._id === project._id
    // )[0];
    // console.log(selectedProj);
    const data = JSON.stringify({
      id: project._id,
      isApproved: !project.isApproved,
    });

    await axiosHelper(`${verifyProject}`, 'POST', null, data);
  };

  const searchFromList = (searchStr) => {
    const arr = [];
    setWordEntered(searchStr);

    for (let index = 0; index < tableData?.length; index++) {
      let newArray = JSON.stringify(tableData[index].projectName);
      if (
        newArray?.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase())
      ) {
        // console.log(searchStr);
        arr.push(tableData[index]);
      }
    }
    // console.log(arr.length);
    // console.log(arr);
    if (searchStr === '') {
      setFilteredArray([]);
    } else {
      setFilteredArray(arr);
    }
  };
  const upcommingDisabled = async (project) => {
    const data = JSON.stringify({
      id: project._id,
      isUpcomingDisabled: !project.isUpcomingDisabled,
    });

    await axiosHelper(`${isUpcomingDisabled}`, 'POST', null, data);
    let result = await axiosHelper(
      `${getAllProjects}?pageNo=1&noOfRecords=2000`,
      'GET'
    );
    setTableData(result.data.data.reverse());
  };

  return (
    <div className='projects'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <button type='button' className='btn add-project-button'>
          <Link to={`../project/0`}>Add Project</Link>
        </button>

        <div style={{ paddingRight: '20px' }}>
          <input
            type='text'
            placeholder='Search Project'
            value={wordEntered}
            onChange={(event) => searchFromList(event.target.value)}
            autoComplete='off'
          />
        </div>
      </div>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        {loading && <Loader width='100px' />}
        {filteredarray.length ? (
          <TableContainer className='allproject' sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead className='tableHead'>
                <TableRow>
                  <TableCell>Approved</TableCell>

                  <TableCell>Action</TableCell>
                  <TableCell>Project </TableCell>
                  <TableCell>Project Type</TableCell>
                  {/* <TableCell>Project Description</TableCell> */}
                  <TableCell> ShowDetailView </TableCell>
                  <TableCell>Is Kyc</TableCell>

                  <TableCell>Token Name</TableCell>
                  <TableCell>Token Symbol</TableCell>
                  <TableCell>Token Sale Date</TableCell>
                  <TableCell>Token Distribution Date</TableCell>
                  <TableCell>Whitelist Start Date</TableCell>
                  <TableCell>Whitelist End Date</TableCell>
                  <TableCell>Supported Token</TableCell>
                  <TableCell>Total Fundraise</TableCell>
                  <TableCell>Token Address</TableCell>
                  <TableCell>Token Contract Link</TableCell>
                  <TableCell>Initial Market Cap</TableCell>
                  <TableCell>Rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredarray?.map((entry, id) => {
                  return (
                    <TableRow key={id}>
                      <TableCell>
                        <label className='switch'>
                          <input
                            type='checkbox'
                            defaultChecked={entry.isApproved}
                            onChange={() => {
                              handleProjectVerificationChange(entry);
                            }}
                          />
                          <span className='slider round'></span>
                        </label>
                      </TableCell>

                      <TableCell>
                        <div className='dropdown  dropend primary'>
                          <button
                            className='btn btn-primary dropdown-toggle'
                            type='button'
                            id='dropdownMenuButton1'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            Actions
                          </button>
                          <ul
                            className='dropdown-menu custom-width'
                            aria-labelledby='dropdownMenuButton1'
                          >
                            <li className='linkList'>
                              <Link
                                className='NavLink'
                                to={`../project/${entry._id}`}
                              >
                                Edit Project
                              </Link>
                            </li>
                            <li className='linkList'>
                              <Link
                                className='NavLink'
                                to={`../launch-ido/${entry._id}`}
                              >
                                Launch IDO
                              </Link>
                            </li>
                            <li className='linkList'>
                              <Link
                                className='NavLink'
                                to={`../claim-ido/${entry._id}`}
                              >
                                Launch Claim
                              </Link>
                            </li>

                            <li className='linkList'>
                              <Link
                                className='NavLink'
                                to={`/view-details/${entry._id}`}
                              >
                                View Whitlisted
                              </Link>
                            </li>
                            <li className='linkList'>
                              <Link
                                className='NavLink'
                                to={`/Whitlisted/${entry._id}`}
                              >
                                Whitlisted Tiers
                              </Link>
                            </li>
                            <li className='linkList'>
                              <Link
                                className='NavLink'
                                to={`../copyproject/${entry._id}`}
                              >
                                Copy Project
                              </Link>
                            </li>
                            <li className='linkList'>
                              <Link
                                className='NavLink'
                                to={`../more-details/${entry._id}`}
                                // style={{ fontSize: '15px' }}
                              >
                                Add More Details
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </TableCell>
                      <TableCell>{entry.projectName}</TableCell>
                      <TableCell>
                        <div className='project-desc'>
                          {entry.typeOfProject}
                        </div>
                      </TableCell>
                      {/* <TableCell>
                        <div className="project-desc">
                          {entry.projectDescription}
                        </div>
                      </TableCell> */}
                      <TableCell>
                        <label className='switch'>
                          <input
                            type='checkbox'
                            defaultChecked={entry.isUpcomingDisabled}
                            onChange={() => {
                              upcommingDisabled(entry);
                            }}
                          />
                          <span className='slider round'></span>
                        </label>
                      </TableCell>
                      <TableCell>{entry.isKYC.toString()}</TableCell>

                      <TableCell>{entry.tokenDetails.tokenName}</TableCell>
                      <TableCell>{entry.tokenDetails.tokenSymbol}</TableCell>
                      <TableCell>
                        <div className='project-desc'>
                          {moment(entry.tokenDetails.tokenSaleDate).format(
                            `YYYY-MM-DD HH:mm`
                          )}
                        </div>
                        <span>(IST)</span>
                      </TableCell>
                      <TableCell>
                        <div className='project-desc'>
                          {moment(
                            entry.tokenDetails.tokenDistributionDate
                          ).format(`YYYY-MM-DD HH:mm`)}
                        </div>
                        <span>(IST)</span>
                      </TableCell>
                      <TableCell>
                        <div className='project-desc'>
                          {moment(entry.idoDates.whitelistStartDate).format(
                            `YYYY-MM-DD HH:mm`
                          )}
                        </div>
                        <span>(IST)</span>
                      </TableCell>
                      <TableCell>
                        <div className='project-desc'>
                          {moment(entry.idoDates.whitelistEndDate).format(
                            `YYYY-MM-DD HH:mm`
                          )}
                        </div>
                        <span>(IST)</span>
                      </TableCell>
                      <TableCell>{entry.supportedToken}</TableCell>
                      <TableCell>{entry.tokenDetails.totalFundraise}</TableCell>
                      <TableCell>
                        {entry.tokenDetails.tokenContract.tokenAddress}
                        <img
                          height={15}
                          width={10}
                          className='copy-text-image'
                          src={copyIcon}
                          alt='copy'
                          onClick={() =>
                            copyContent(
                              entry.tokenDetails.tokenContract.tokenAddress
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {entry.tokenDetails.tokenContract.tokenContractLink}
                        <img
                          height={15}
                          width={10}
                          className='copy-text-image'
                          src={copyIcon}
                          alt='copy'
                          onClick={() =>
                            copyContent(
                              entry.tokenDetails.tokenContract.tokenContractLink
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {entry.tokenDetails.initialMarketCap}
                      </TableCell>
                      <TableCell>{entry.tokenDetails.rate}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          tableData && (
            <TableContainer className='allproject' sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead className='tableHead'>
                  <TableRow>
                    <TableCell>Approved</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Project Type</TableCell>
                    {/* <TableCell>Project Description</TableCell> */}
                    <TableCell> ShowDetailView </TableCell>
                    <TableCell>Is Kyc</TableCell>

                    <TableCell>Token Name</TableCell>
                    <TableCell>Token Symbol</TableCell>
                    <TableCell>Token Sale Date</TableCell>
                    <TableCell>Token Distribution Date</TableCell>
                    <TableCell>Whitelist Start Date</TableCell>
                    <TableCell>Whitelist End Date</TableCell>
                    <TableCell>Supported Token</TableCell>
                    <TableCell>Total Fundraise</TableCell>
                    <TableCell>Token Address</TableCell>
                    <TableCell>Token Contract Link</TableCell>
                    <TableCell>Initial Market Cap</TableCell>
                    <TableCell>Rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((entry, id) => {
                      return (
                        <TableRow key={id}>
                          <TableCell>
                            <label className='switch'>
                              <input
                                type='checkbox'
                                defaultChecked={entry.isApproved}
                                onChange={() => {
                                  handleProjectVerificationChange(entry);
                                }}
                              />
                              <span className='slider round'></span>
                            </label>
                          </TableCell>
                          <TableCell>
                            <div className='dropdown  dropend primary'>
                              <button
                                className='btn btn-primary dropdown-toggle'
                                type='button'
                                id='dropdownMenuButton1'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                              >
                                Actions
                              </button>
                              <ul
                                className='dropdown-menu custom-width'
                                aria-labelledby='dropdownMenuButton1'
                              >
                                <li className='linkList'>
                                  <Link
                                    className='NavLink'
                                    to={`../project/${entry._id}`}
                                  >
                                    Edit Project
                                  </Link>
                                </li>
                                <li className='linkList'>
                                  <Link
                                    className='NavLink'
                                    to={`../launch-ido/${entry._id}`}
                                  >
                                    Launch IDO
                                  </Link>
                                </li>
                                <li className='linkList'>
                                  <Link
                                    className='NavLink'
                                    to={`../claim-ido/${entry._id}`}
                                  >
                                    Launch Claim
                                  </Link>
                                </li>
                                <li className='linkList'>
                                  <Link
                                    className='NavLink'
                                    to={`/view-details/${entry._id}`}
                                  >
                                    View Whitlisted
                                  </Link>
                                </li>
                                <li className='linkList'>
                                  <Link
                                    className='NavLink'
                                    to={`/Whitlisted/${entry._id}`}
                                  >
                                    Whitlisted Tiers
                                  </Link>
                                </li>
                                {/* <li className='linkList'>
                                  <Link
                                    className='NavLink'
                                    to={`../screening/${entry._id}`}
                                  >
                                    Project Screening
                                  </Link>
                                </li> */}
                                <li className='linkList'>
                                  <Link
                                    className='NavLink'
                                    to={`../copyproject/${entry._id}`}
                                  >
                                    Copy Project
                                  </Link>
                                </li>
                                <li className='linkList'>
                                  <Link
                                    className='NavLink'
                                    to={`../more-details/${entry._id}`}
                                    // style={{ fontSize: '15px' }}
                                  >
                                    Add More Details
                                  </Link>
                                </li>
                                {/* <Dropdown entry={entry} /> */}
                              </ul>
                            </div>
                          </TableCell>
                          <TableCell>
                            <a
                              href={`${entry.internalProjectLink}`}
                              target='_blank'
                            >
                              {entry.projectName}
                            </a>
                          </TableCell>
                          <TableCell>
                            <div className='project-desc'>
                              {entry.typeOfProject}
                            </div>
                          </TableCell>
                          {/* <TableCell>
                            <div className="project-desc">
                              {entry.projectDescription}
                            </div>
                          </TableCell> */}
                          <TableCell>
                            <label className='switch'>
                              <input
                                type='checkbox'
                                defaultChecked={entry.isUpcomingDisabled}
                                onChange={() => {
                                  upcommingDisabled(entry);
                                }}
                              />
                              <span className='slider round'></span>
                            </label>
                          </TableCell>
                          <TableCell>{entry.isKYC.toString()}</TableCell>

                          <TableCell>{entry.tokenDetails.tokenName}</TableCell>
                          <TableCell>
                            {entry.tokenDetails.tokenSymbol}
                          </TableCell>
                          <TableCell>
                            <div className='project-desc'>
                              {moment(entry.tokenDetails.tokenSaleDate).format(
                                `YYYY-MM-DD HH:mm`
                              )}
                            </div>
                            <span>(IST)</span>
                          </TableCell>
                          <TableCell>
                            <div className='project-desc'>
                              {moment(
                                entry.tokenDetails.tokenDistributionDate
                              ).format(`YYYY-MM-DD HH:mm`)}
                            </div>
                            <span>(IST)</span>
                          </TableCell>
                          <TableCell>
                            <div className='project-desc'>
                              {moment(entry.idoDates.whitelistStartDate).format(
                                `YYYY-MM-DD HH:mm`
                              )}
                            </div>
                            <span>(IST)</span>
                          </TableCell>
                          <TableCell>
                            <div className='project-desc'>
                              {moment(entry.idoDates.whitelistEndDate).format(
                                `YYYY-MM-DD HH:mm`
                              )}
                            </div>
                            <span>(IST)</span>
                          </TableCell>
                          <TableCell>{entry.supportedToken}</TableCell>
                          <TableCell>
                            {entry.tokenDetails.totalFundraise}
                          </TableCell>
                          <TableCell>
                            {entry.tokenDetails.tokenContract.tokenAddress}
                            <img
                              height={15}
                              width={10}
                              className='copy-text-image'
                              src={copyIcon}
                              alt='copy'
                              onClick={() =>
                                copyContent(
                                  entry.tokenDetails.tokenContract.tokenAddress
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            {entry.tokenDetails.tokenContract.tokenContractLink}
                            <img
                              height={15}
                              width={10}
                              className='copy-text-image'
                              src={copyIcon}
                              alt='copy'
                              onClick={() =>
                                copyContent(
                                  entry.tokenDetails.tokenContract
                                    .tokenContractLink
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            {entry.tokenDetails.initialMarketCap}
                          </TableCell>
                          <TableCell>{entry.tokenDetails.rate}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component='div'
          count={tableData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <copyState copyProject={tableData} /> */}
    </div>
  );
};

export default Projects;
