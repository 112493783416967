import { Box } from '@mui/material';
import React from 'react';


export const Flex = ({
  direction,
  align,
  justify,
  grow,
  column,
  center,
  children,
  ...props
}) => {
  return (
    <Box
      display={props.display || 'flex'}
      flexDirection={!!column ? 'column' : direction || 'row'}
      alignItems={!!center ? 'center' : align || undefined}
      justifyContent={!!center ? 'center' : justify || undefined}
      // flexGrow={grow ? (grow === true ? '1' : grow) : undefined}
      {...props}
    >
      {children}
    </Box>
  );
};
