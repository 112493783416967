import React, { useState } from "react";
import './index.css';

const Transfer = (props) => {
  
  const [address, setAddress] = useState('');

  const handleAddressInput = (e) => {
    if(props !== undefined) {
      setAddress(e.target.value);
    }
    else {
      
    }
  }
  
  return (
    <div className=" transfer-container">
      <div className="card current-address">
        <h4>Current Owner</h4>
      </div>
      <div className="card new-address">
        <form className="add-project-form">
          <div className="container">
            <div className="mb-3 row">
              <div className="col">
                <label htmlFor="transfer-address" className="col-form-label">New Transfer Address:</label>
                <input 
                type="text" 
                className="form-control" id="transfer-address" 
                value={address}
                onChange={handleAddressInput}
                />
              </div>
            </div>
            <button className="btn btn-primary transfer-button">
              Transfer
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Transfer;