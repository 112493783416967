import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import axiosHelper from '../../helpers/axios-helper.js';
import moment from 'moment';
import FirstPage from './firstPage';
import SecondPage from './secondPage';
import ThirdPage from './thirdPage';
import FourPage from './fourPage';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { switchNetwork, getTokenInfo } from '../../helpers/web3-helpers';
import { registration, editProject, getProjectById } from '../../config/config';
import { useParams } from 'react-router-dom';
import { parse } from 'dotenv';

const Project = () => {
  const networkType = {
    BSC: 56,
    POLYGON: 137,
    AVALANCHE: 43114,
    'BSC TESTNET': 97,
    'POLYGON TESTNET': 80001,
    'AVALANCHE TESTNET': 43113,
  };

  const { id } = useParams();
  const location = useLocation();
  const path = location.pathname.slice(' ', 8);

  const pathcopyproject = location.pathname.slice(' ', 12);

  let navigate = useNavigate();
  const formatDates = (date) => {
    const dateToFormat = new Date(date);
    const ts = dateToFormat.toISOString();
    const requirdDate = ts.slice(0, 16);
    return requirdDate;
  };

  useEffect(() => {
    async function fetchData() {
      const result = await axiosHelper(
        getProjectById,
        'POST',
        null,
        JSON.stringify({ id: id })
      );
      const { data } = result.data;
      console.log(data);
      setEdittokenInfo(data.currDecimal);
      setNetwork(data.projectNetwork.networkId);
      setAddNetworkType(addData.basic.networkType);
      setAddData({
        basic: {
          projectName: data.projectName,
          projectDescription: data.projectDescription,
          projectOneLiner: data.projectOneLiner,
          networkType: data.projectNetwork.networkName,
          projectStatus: data.isKYC,
          projectTags: data.projectTags,
          typeOfProject: data.typeOfProject,
        },
        social: {
          twitter: data.projectLinks.twitter,
          telegram: data.projectLinks.telegram,
          medium: data.projectLinks.medium,
          website: data.projectLinks.website,
          github: data.projectLinks.github,
          onePager: data.projectLinks.onepager,
          whitePaper: data.projectLinks.whitepaper,
          country: data.projectRestriction,
          competitonWinner: data.competetionWinner,
          countryRestricted: data.countryRestriction,
        },
        finance: {
          tokenName: data.tokenDetails.tokenName,
          tokenSymbol: data.tokenDetails.tokenSymbol,
          tokenPrice: data.tokenDetails.tokenPrice,
          tokenRate: data.tokenDetails.rate,
          vesting: data.tokenDetails.vesting,
          reportLink: data.tokenDetails.auditReportLink,
          tokenContractAddressUI: data.tokenDetails.tokenContractAddressUI,
          tokenContractAddress: data.tokenDetails.tokenContract.tokenAddress,
          tokenContractLink: data.tokenDetails.tokenContract.tokenContractLink,
          totalFundRaise: data.tokenDetails.totalFundraise,
          tba: data.isTBA,
          tokenSaleDate: moment(data.tokenDetails.tokenSaleDate).format(
            `YYYY-MM-DDTHH:mm:ss`
          ),
          tokenDistributionDate: moment(
            data.tokenDetails.tokenDistributionDate
          ).format(`YYYY-MM-DDTHH:mm:ss`),
          initialMarketCap: data.tokenDetails.initialMarketCap,
          supportedToken: data.supportedToken,
          contractTokenAddress: data.smartContractAddress.token,
          contractCurrencyAddress: data.smartContractAddress.currency,
          whitelistStartDate: moment(data.idoDates.whitelistStartDate).format(
            `YYYY-MM-DDTHH:mm:ss`
          ),
          whitelistEndDate: moment(data.idoDates.whitelistEndDate).format(
            `YYYY-MM-DDTHH:mm:ss`
          ),
          tokenABI: data.abi.tokenABI,
          currencyABI: data.abi.currencyABI,
          roundOneStartDate: moment(data.idoDates.roundOneStartDate).format(
            `YYYY-MM-DDTHH:mm:ss`
          ),
          roundOneEndDate: moment(data.idoDates.roundOneEndDate).format(
            `YYYY-MM-DDTHH:mm:ss`
          ),
          roundTwoStartDate: moment(data.idoDates.roundTwoStartDate).format(
            `YYYY-MM-DDTHH:mm:ss`
          ),
          roundTwoEndDate: moment(data.idoDates.roundTwoEndDate).format(
            `YYYY-MM-DDTHH:mm:ss`
          ),
          projectLogoUrl: data.projectLogoUrl,
          projectBannerUrl: data.projectBannerUrl,
          mediaURL: data?.media[0]?.url,
          mediaType: data?.media[0]?.type,
          GoogleDocLink: data?.internalProjectLink,
          noOfTier: data?.noOfTier,
          // media: [{ url: data?.media[0]?.url }],
        },
      });
      if (data?.media !== undefined) {
        setMediaNameData(data);
      } else {
        setMediaNameData('');
      }
    }
    if (parseInt(id) !== 0 && path === '/project') {
      toggleIsEditProject(true);
    }

    fetchData();
  }, [id]);

  const [mediaNameData, setMediaNameData] = useState(null);

  const [network, setNetwork] = useState();
  const [tokenInfo, setTokenInfo] = useState();
  const [edittokenInfo, setEdittokenInfo] = useState();
  const [isEditProject, toggleIsEditProject] = useState(false);

  const [page, setPage] = useState(1);
  const projectLogoImageRef = useRef();
  const networkImageRef = useRef();
  const projectBannerImageRef = useRef();
  const projectMediaURLRef = useRef();

  const [contractResponse, setContractResponse] = useState();
  const [AddNetworkType, setAddNetworkType] = useState();
  const [addData, setAddData] = useState({
    basic: {
      projectName: '',
      projectDescription: '',
      projectOneLiner: '',
      networkType: '',
      currencyDecimal: '18',
      projectStatus: false,
      projectTags: '',
      typeOfProject: 'Community IDO',
    },
    social: {
      twitter: '',
      telegram: '',
      medium: '',
      website: '',
      github: '',
      onePager: '',
      whitePaper: '',
      country: '',
      competitonWinner: '',
      countryRestricted: [],
    },
    finance: {
      tokenName: '',
      tokenSymbol: '',
      tokenPrice: '',
      tokenRate: '',
      vesting: '',
      reportLink: '',
      tokenContractAddress: '',
      tokenContractLink: '',
      totalFundRaise: '',
      tokenSaleDate: '',
      tokenDistributionDate: '',
      initialMarketCap: '',
      supportedToken: '',
      tokenContractAddressUI: '',
      contractTokenAddress: '',
      contractCurrencyAddress: '',
      whitelistStartDate: '',
      whitelistEndDate: '',
      tokenABI: '',
      currencyABI: '',
      roundOneStartDate: '',
      roundOneEndDate: '',
      roundTwoStartDate: '',
      roundTwoEndDate: '',
      tba: false,
      projectLogoUrl: '',
      projectBannerUrl: '',
      mediaURL: '',
      mediaType: '',
      noOfTier: '',
      GoogleDocLink: '',
      // media: [],
    },
  });
  useEffect(() => {
    if (addData.basic.networkType === 'BSC TESTNET') {
      setNetwork(97);
    } else if (addData.basic.networkType === 'POLYGON TESTNET') {
      setNetwork(80001);
    } else if (addData.basic.networkType === 'AVALANCHE TESTNET') {
      setNetwork(43113);
    } else if (addData.basic.networkType === 'BSC') {
      setNetwork(56);
    } else if (addData.basic.networkType === 'POLYGON') {
      setNetwork(137);
    } else if (addData.basic.networkType === 'AVALANCHE') {
      setNetwork(43114);
    } else {
      console.log('select network type');
    }
    // switchNetwork(network);
  }, [addData.basic.networkType]);

  useEffect(() => {
    switchNetwork(network);
  }, [network]);

  const handleInput = (parant, key, val) => {
    const temp = {
      ...addData,
    };
    temp[parant][key] = val;
    setAddData(temp);
  };
  const symbolAndDecimal = async (currencyAbi, launchData) => {
    try {
      let Info = await getTokenInfo(JSON.parse(currencyAbi), launchData);
      if (Info !== undefined) {
        setTokenInfo(Info);
      }
    } catch (e) {
      console.log(e);
    }
  };
  console.log(parseInt(addData.finance.noOfTier));
  useEffect(() => {
    symbolAndDecimal(
      addData.finance.currencyABI,
      addData.finance.contractCurrencyAddress
    );
  }, [addData.finance.currencyABI, addData.finance.contractCurrencyAddress]);

  let newDate = new Date();
  console.log(tokenInfo);
  const handleSubmit = async () => {
    console.log(addData);
    if (
      // addData.social.countryRestricted.length === 0 &&
      addData.basic.projectName !== '' &&
      addData.basic.projectDescription !== '' &&
      addData.basic.projectStatus !== '' &&
      addData.basic.projectTags !== '' &&
      addData.basic.networkType !== '' &&
      addData.basic.typeOfProject !== '' &&
      addData.finance.tokenName !== '' &&
      addData.finance.tokenSymbol !== '' &&
      addData.finance.tokenPrice !== '' &&
      addData.finance.tokenRate !== '' &&
      addData.finance.vesting !== '' &&
      addData.finance.noOfTier !== '' &&
      // addData.finance.reportLink !== "" &&
      // addData.finance.totalFundRaise !== "" &&
      // addData.finance.tokenSaleDate !== "" &&
      addData.finance.tokenDistributionDate !== '' &&
      // addData.finance.initialMarketCap !== "" &&
      addData.finance.supportedToken !== '' &&
      addData.finance.contractTokenAddress !== '' &&
      addData.finance.contractCurrencyAddress !== '' &&
      addData.finance.whitelistStartDate !== '' &&
      addData.finance.whitelistEndDate !== '' &&
      addData.finance.tokenABI !== '' &&
      addData.finance.currencyABI !== '' &&
      addData.finance.roundOneStartDate !== '' &&
      addData.finance.roundOneEndDate !== '' &&
      addData.finance.roundTwoStartDate !== '' &&
      addData.finance.roundTwoEndDate !== '' &&
      addData.finance.totalFundRaise !== '' &&
      // networkImageRef.current.value !== "" &&
      // projectBannerImageRef.current.value !== "" &&
      // projectLogoImageRef.current.value !== "" &&
      // addData.social.twitter !== "" &&
      // addData.social.telegram !== "" &&
      // addData.social.medium !== "" &&
      // addData.social.github !== "" &&
      // addData.social.onePager !== "" &&
      // addData.social.whitePaper !== "" &&
      addData.social.country !== '' &&
      // addData.social.competitonWinner !== "" &&
      // addData.social.website !== "" &&
      new Date(addData.finance.whitelistEndDate) >
        new Date(addData.finance.whitelistStartDate) &&
      // new Date(addData.finance.tokenSaleDate) >
      new Date(addData.finance.roundOneStartDate) >
        new Date(addData.finance.whitelistEndDate) &&
      // new Date(addData.finance.roundOneStartDate) >=
      // new Date(addData.finance.tokenSaleDate) &&
      new Date(addData.finance.roundOneEndDate) >
        new Date(addData.finance.roundOneStartDate) &&
      new Date(addData.finance.roundTwoStartDate) >
        new Date(addData.finance.roundOneEndDate) &&
      new Date(addData.finance.roundTwoEndDate) >
        new Date(addData.finance.roundTwoStartDate)
      //    &&
      // new Date(addData.finance.tokenDistributionDate) >
      //   new Date(addData.finance.roundTwoEndDate)
    ) {
      const formData = new FormData();
      if (isEditProject) {
        formData.append('id', id);
      }
      if (!isEditProject) {
        formData.append('images', projectLogoImageRef.current.files[0]);
        // formData.append("images", networkImageRef.current.files[0]);
        formData.append('images', projectBannerImageRef.current.files[0]);

        // if (projectMediaURLRef?.current?.files[0] !== undefined) {
        //   formData.append('mediaURL', addData.finance.mediaURL);
        // }
        if (
          // projectMediaURLRef?.current?.files[0] !== undefined &&
          addData.finance.mediaURL !== undefined
        ) {
          formData.append('mediaURL', addData.finance.mediaURL);
        }
      }
      if (addData.finance.mediaType !== undefined) {
        formData.append('mediaType', addData.finance.mediaType);
      }
      if (
        // projectMediaURLRef?.current?.files[0] !== undefined &&
        addData.finance.mediaURL !== undefined &&
        isEditProject
      ) {
        formData.append('mediaURL', addData.finance.mediaURL);
      }

      if (
        projectLogoImageRef?.current?.files[0] !== undefined &&
        isEditProject
      ) {
        formData.append('images', projectLogoImageRef.current.files[0]);
      }
      if (
        projectBannerImageRef?.current?.files[0] !== undefined &&
        isEditProject
      ) {
        formData.append('images', projectBannerImageRef.current.files[0]);
      }

      formData.append('projectName', addData.basic.projectName);
      formData.append('internalProjectLink', addData.finance.GoogleDocLink);

      formData.append('projectDescription', addData.basic.projectDescription);
      formData.append('projectOneLiner', addData.basic.projectOneLiner);
      formData.append('projectTags', addData.basic.projectTags);
      formData.append('networkType', networkType[addData.basic.networkType]);
      formData.append('networkName', addData.basic.networkType);
      formData.append('isKYC', addData.basic.projectStatus);
      formData.append('typeOfProject', addData.basic.typeOfProject);
      formData.append('noOfTier', parseInt(addData.finance.noOfTier));
      formData.append('supportedToken', addData.finance.supportedToken);
      formData.append('telegram', addData.social.telegram);
      formData.append('medium', addData.social.medium);
      formData.append('website', addData.social.website);
      formData.append('twitter', addData.social.twitter);
      formData.append('github', addData.social.github);
      formData.append('onepager', addData.social.onePager);
      formData.append('whitepaper', addData.social.whitePaper);
      formData.append('projectRestriction', addData.social.country);
      formData.append('countryRestriction', addData.social.countryRestricted);
      formData.append('competetionWinner', addData.social.competitonWinner);
      formData.append('contractTokenAdd', addData.finance.contractTokenAddress);
      formData.append(
        'contractCurrencyAdd',
        addData.finance.contractCurrencyAddress
      );
      formData.append('tokenName', addData.finance.tokenName);
      formData.append('tokenSymbol', addData.finance.tokenSymbol);
      formData.append('rate', addData.finance.tokenRate);
      formData.append('tokenPrice', addData.finance.tokenPrice);
      formData.append('vesting', addData.finance.vesting);
      formData.append('auditReportLink', addData.finance.reportLink);
      formData.append(
        'tokenContractAddressUI',
        addData.finance.tokenContractAddressUI
      );
      formData.append('tokenAddress', addData.finance.contractTokenAddress);
      formData.append(
        'tokenContractLink',
        addData.finance.contractTokenAddress
      );
      formData.append(
        'tokenSaleDate',
        new Date(addData.finance.roundOneStartDate)
      );

      if (
        new Date(addData.finance.tokenDistributionDate) >
        new Date(addData.finance.roundTwoEndDate)
      ) {
        formData.append(
          'tokenDistributionDate',
          new Date(addData.finance.tokenDistributionDate)
        );
      } else {
        formData.append('tokenDistributionDate', '');
      }

      formData.append('totalFundraise', addData.finance.totalFundRaise);
      formData.append('initialMarketCap', addData.finance.initialMarketCap);
      formData.append(
        'whitelistStartDate',
        new Date(addData.finance.whitelistStartDate)
      );
      formData.append(
        'whitelistEndDate',
        new Date(addData.finance.whitelistEndDate)
      );
      formData.append(
        'roundOneStartDate',
        new Date(addData.finance.roundOneStartDate)
      );
      formData.append(
        'roundOneEndDate',
        new Date(addData.finance.roundOneEndDate)
      );
      formData.append(
        'roundTwoStartDate',
        new Date(addData.finance.roundTwoStartDate)
      );
      formData.append(
        'roundTwoEndDate',
        new Date(addData.finance.roundTwoEndDate)
      );
      formData.append('tokenABI', addData.finance.tokenABI);
      formData.append('currencyABI', addData.finance.currencyABI);
      formData.append('isTBA', addData.finance.tba);

      if (tokenInfo === undefined) {
        formData.append('currDecimal', edittokenInfo);
      } else {
        formData.append('currDecimal', tokenInfo);
      }
      if (
        !isEditProject &&
        addData.finance.whitelistStartDate > formatDates(newDate) &&
        tokenInfo !== undefined
      ) {
        const response = await axiosHelper(registration, 'POST', formData);
        if (response.status === 201) {
          toast.success('Project added ', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate('/projects');
        } else {
          toast.error('Check Network ', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else if (isEditProject) {
        const res = await axiosHelper(editProject, 'POST', formData);
        if (res.status === 200) {
          toast.success('Project Edited Successful', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate('/projects');
        } else {
          toast.error('Check Network ', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.warn(
          ` Check all Require fields
        `,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } else {
      if (!isEditProject) {
        toast.warn(
          `${
            addData.finance.whitelistStartDate > formatDates(newDate)
              ? 'Whitelist Start Time Should be greater than Current Time,'
              : ''
          }
          ${addData.basic.projectName === '' ? 'Project Name Require, ' : ''} ${
            addData.basic.projectDescription === ''
              ? 'Project Description Require, '
              : ''
          } ${
            addData.basic.projectStatus === '' ? 'Project Status Require, ' : ''
          } ${
            addData.basic.projectTags === '' ? 'Project Tag Require, ' : ''
          } ${
            addData.basic.networkType === ''
              ? 'Project Network Type Require, '
              : ''
          } ${
            addData.basic.typeOfProject === '' ? 'Project Type Require, ' : ''
          } ${
            addData.finance.tokenName === ''
              ? 'Project Token Name Require, '
              : ''
          } ${
            addData.finance.tokenSymbol === ''
              ? 'Project Token Symbol Require, '
              : ''
          } ${
            addData.finance.tokenPrice === ''
              ? 'Project Token Price Require, '
              : ''
          } ${
            addData.finance.tokenRate === ''
              ? 'Project Token Rate Require, '
              : ''
          } ${addData.finance.vesting === '' ? 'Project vesting Require, ' : ''}
          ${addData.finance.noOfTier === '' ? 'noOfTeir Require, ' : ''}
        ${
          addData.finance.contractCurrencyAddress === ''
            ? 'Project Contract Currency Address Require, '
            : ''
        }
        ${
          addData.finance.contractTokenAddress === ''
            ? 'Project Contract Token Address Require, '
            : ''
        }
        ${
          addData.finance.currencyABI === ''
            ? 'Project Currency ABI Require, '
            : ''
        }
        ${
          addData.finance.initialMarketCap === ''
            ? 'Project Initial Market Cap Require, '
            : ''
        }
        ${addData.finance.tokenABI === '' ? 'Project Token ABI Require, ' : ''}
        ${
          addData.finance.auditReportLink === ''
            ? 'Project Report Link Require, '
            : ''
        }
        ${
          addData.finance.supportedToken === ''
            ? 'Project Supported Token Require, '
            : ''
        }
        ${
          addData.finance.totalFundRaise === ''
            ? 'Project Total Fund Raise is Require'
            : ''
        }
      ${addData.social.country === '' ? 'Project Country is Require, ' : ''}
       ${
         addData.finance.auditReportLink === ''
           ? 'Project Audit Link is Require, '
           : ''
       }
       ${
         addData.finance.whitelistEndDate === ''
           ? 'Project Whitelist End Date is Require, '
           : ''
       }
        ${
          addData.finance.whitelistStartDate === ''
            ? 'Project WhiteList Start Date is Require ,'
            : ''
        }

        ${
          addData.finance.tokenDistributionDate === ''
            ? 'Project Distribution Date is require, '
            : ''
        }

        ${
          addData.finance.roundOneStartDate === ''
            ? 'Project Round One Start Date is Require, '
            : ''
        }
        ${
          addData.finance.roundOneEndDate === ''
            ? 'Project Round one End Date is Require, '
            : ''
        }
        ${
          addData.finance.roundTwoStartDate === ''
            ? 'Project Round Two Start Date is Require, '
            : ''
        }
        ${
          addData.finance.roundTwoEndDate === ''
            ? 'Project Round Two End Date is Require, '
            : ''
        }
          ${
            projectBannerImageRef.current.value === ''
              ? 'Project Banner Image is Require, '
              : ''
          }
          ${
            projectLogoImageRef.current.value === ''
              ? 'Project Logo Image is Require, '
              : ''
          }
         `,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        toast.warn(
          `${
            addData.basic.projectName === '' ? 'Project Name Require, ' : ''
          } ${
            addData.basic.projectDescription === ''
              ? 'Project Description Require, '
              : ''
          } ${
            addData.basic.projectStatus === '' ? 'Project Status Require, ' : ''
          } ${
            addData.basic.projectTags === '' ? 'Project Tag Require, ' : ''
          } ${
            addData.basic.networkType === ''
              ? 'Project Network Type Require, '
              : ''
          } ${
            addData.basic.typeOfProject === '' ? 'Project Type Require, ' : ''
          } ${
            addData.finance.tokenName === ''
              ? 'Project Token Name Require, '
              : ''
          } ${
            addData.finance.tokenSymbol === ''
              ? 'Project Token Symbol Require, '
              : ''
          } ${
            addData.finance.tokenPrice === ''
              ? 'Project Token Price Require, '
              : ''
          } ${
            addData.finance.tokenRate === ''
              ? 'Project Token Rate Require, '
              : ''
          } ${addData.finance.vesting === '' ? 'Project vesting Require, ' : ''}
           ${addData.finance.noOfTier === '' ? 'noOfTeir Require, ' : ''}
          ${
            addData.finance.contractCurrencyAddress === ''
              ? 'Project Contract Currency Address Require, '
              : ''
          }
        ${
          addData.finance.contractTokenAddress === ''
            ? 'Project Contract Token Address Require, '
            : ''
        }
        ${
          addData.finance.currencyABI === ''
            ? 'Project Currency ABI Require, '
            : ''
        }
        ${
          addData.finance.initialMarketCap === ''
            ? 'Project Initial Market Cap Require, '
            : ''
        }
        ${addData.finance.tokenABI === '' ? 'Project Token ABI Require, ' : ''}
        ${
          addData.finance.auditReportLink === ''
            ? 'Project Report Link Require, '
            : ''
        }
        ${
          addData.finance.supportedToken === ''
            ? 'Project Supported Token Require, '
            : ''
        }
        ${
          addData.finance.totalFundRaise === ''
            ? 'Project Total Fund Raise is Require'
            : ''
        }
      ${addData.social.country === '' ? 'Project Country is Require, ' : ''}
        ${
          addData.finance.auditReportLink === ''
            ? 'Project Audit Link is Require, '
            : ''
        }
        ${
          addData.finance.whitelistEndDate === ''
            ? 'Project Whitelist End Date is Require, '
            : ''
        }
        ${
          addData.finance.whitelistStartDate === ''
            ? 'Project WhiteList Start Date is Require ,'
            : ''
        }

        ${
          addData.finance.tokenDistributionDate === ''
            ? 'Project Distribution Date is Require, '
            : ''
        }
        ${
          addData.finance.roundOneStartDate === ''
            ? 'Project Round One Start Date is Require, '
            : ''
        }
        ${
          addData.finance.roundOneEndDate === ''
            ? 'Project Round one End Date is Require, '
            : ''
        }
        ${
          addData.finance.roundTwoStartDate === ''
            ? 'Project Round Two Start Date is Require, '
            : ''
        }
        ${
          addData.finance.roundTwoEndDate === ''
            ? 'Project Round Two End Date is Require, '
            : ''
        }
        `,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  };

  const previous = () => {
    if (page <= 1) return;
    setPage((page) => page - 1);
  };
  const next = () => {
    if (page >= 4) return;
    setPage((page) => page + 1);
    if (page === 1) {
    }
  };

  return (
    <div className='add_page'>
      <ToastContainer />
      <div className='card inner_add_page'>
        {id === '0' ? (
          <h2>Add Project Form </h2>
        ) : pathcopyproject === '/copyproject' ? (
          <>
            <h2>{`Copy ${addData.basic.projectName}`}</h2>
          </>
        ) : (
          <>
            <h2>
              {`Edit ${addData.basic.projectName} (${addData.basic.typeOfProject})`}
            </h2>
          </>
        )}

        {/* <h3>IST Time </h3> */}
        <div className='stepper'>
          <hr />
          <div className='btn-div'>
            <button
              className={page === 1 ? 'active-btn' : 'btn-stepper'}
              onClick={() => setPage(1)}
            >
              1
            </button>
            <button
              className={page === 2 ? 'active-btn' : 'btn-stepper'}
              onClick={() => setPage(2)}
            >
              2
            </button>
            <button
              className={page === 3 ? 'active-btn' : 'btn-stepper'}
              onClick={() => setPage(3)}
            >
              3
            </button>
            <button
              className={page === 4 ? 'active-btn' : 'btn-stepper'}
              onClick={() => setPage(4)}
            >
              4
            </button>
          </div>
        </div>
        <div className='needs-validation' role='form' noValidate>
          {page === 1 && (
            <FirstPage data={addData.basic} update={handleInput} />
          )}
          {page === 2 && (
            <SecondPage
              data={addData.social}
              id={id}
              projectType={addData.basic.typeOfProject}
              update={handleInput}
            />
          )}
          {page === 3 && (
            <ThirdPage
              data={addData.finance}
              update={handleInput}
              NetworkType={addData.basic.networkType}
              SupportedToken={addData.finance.supportedToken}
              curencyabi={addData.finance.currencyABI}
            />
          )}
          {page === 4 && (
            <FourPage
              typeOfProject={addData.basic.typeOfProject}
              data={addData.finance}
              update={handleInput}
              formatDates={formatDates}
              projectLogoImageRef={projectLogoImageRef}
              networkImageRef={networkImageRef}
              projectBannerImageRef={projectBannerImageRef}
              // projectMediaURLRef={projectMediaURLRef}
              id={id}
              isEditProject={isEditProject}
              mediaNameData={mediaNameData}
            />
          )}
        </div>
        <div className='btn_addnext mt-3'>
          <button className='btn btn-outline-primary' onClick={previous}>
            Previous
          </button>
          {page === 4 ? (
            <button
              className='btn btn-success'
              type='submit'
              onClick={handleSubmit}
            >
              Submit
            </button>
          ) : (
            <button className='btn btn-outline-primary' onClick={next}>
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Project;
