import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosHelper from '../../helpers/axios-helper';
import { getProjectById, tokenomics, upload } from '../../config/config';

function TokenMetrics({ isEditProject, projectData }) {
  const { id } = useParams();
  let navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState('');
  const [disabled, setDisable] = useState(false);
  const [name, setName] = useState('');
  const [media, setMedia] = useState({
    tokenomicsImage: '',
    tokenReleaseSchedule: '',
  });
  const [buttonDisable, setButtonDisable] = useState(true);
  const [editbuttonDisable, setEditButtonDisable] = useState(false);
  const [previewImage, setPreviewImage] = useState({
    tokenomicsImagePreview: null,
    tokenReleaseSchedulePreview: null,
  });

  const uploadPreviewImage = (key, val) => {
    const temp = {
      ...previewImage,
    };
    temp[key] = val;
    setPreviewImage(temp);
  };

  useEffect(() => {
    async function editFetchData() {
      // if (isEditProject) {
      const response = await axiosHelper(
        `${tokenomics}/${id}`,
        'GET',
        null,
        id
      );
      // console.log('Response', response);
      if (response.data.data !== null) {
        setButtonDisable(false);
        setEditButtonDisable(true);
        setMedia({
          tokenomicsImage: response?.data?.data?.tokenomicsImage,
          tokenReleaseSchedule: response?.data?.data?.tokenReleaseSchedule,
        });
        setPreviewImage({
          tokenomicsImagePreview: response?.data?.data?.tokenomicsImage,
          tokenReleaseSchedulePreview:
            response?.data?.data?.tokenReleaseSchedule,
        });
      }
      // }
    }

    editFetchData();
  }, [id, isEditProject]);

  const uploadMedia = (key, val) => {
    const temp = {
      ...media,
    };
    temp[key] = val;
    setMedia(temp);
  };

  async function uploadFunction(file, Inputname) {
    setDisable(true);
    setName(Inputname);
    const formData = new FormData();
    formData.append('file', file);
    // console.log(formData);
    const result = await axiosHelper(upload, 'POST', formData);
    setDisable(false);
    setName('');

    if (Inputname === 'tokenomicsImage') {
      uploadMedia('tokenomicsImage', result?.data?.data?.location);
    }
    if (Inputname === 'tokenReleaseSchedule') {
      uploadMedia('tokenReleaseSchedule', result?.data?.data?.location);
    }
  }

  const handelFileInput = (event, previewCheck) => {
    setSelectedFile(event.target.files[0]);
    if (previewCheck === 'tokenomicsImagePreview') {
      const file = event.target.files[0];
      if (!file) return;
      uploadPreviewImage('tokenomicsImagePreview', URL.createObjectURL(file));
    }
    if (previewCheck === 'tokenReleaseSchedulePreview') {
      // setSelectedFile(event.target.files[0]);
      const file = event.target.files[0];
      if (!file) return;
      uploadPreviewImage(
        'tokenReleaseSchedulePreview',
        URL.createObjectURL(file)
      );
    }
  };

  const handleSubmit = async (props) => {
    const submit = {
      projectId: parseInt(id),
      tokenomicsImage: media.tokenomicsImage,
      tokenReleaseSchedule: media.tokenReleaseSchedule,
    };

    if (props === 'editDetails') {
      const result = await axiosHelper(
        `${tokenomics}/${id}`,
        'PUT',
        null,
        submit
      );
      if (result.status === 200) {
        navigate('/projects');
      }
    } else {
      const result = await axiosHelper(tokenomics, 'POST', null, submit);
      if (result.status === 200) {
        navigate('/projects');
      }
    }
    // const result = await axiosHelper(tokenomics, 'POST', null, submit);
    // if (result.status === 200) {
    //   navigate('/projects');
    // }
  };
  const divStyles = {
    display: 'flex',
    margin: '0px',
  };
  const pStyles = {
    margin: '0px 0 0 10px',
  };
  return (
    <div>
      <div className='add_page' style={{ left: 0 }}>
        <div className='card inner_add_page'>
          <h2>{`${projectData.projectName} ${projectData.typeOfProject}`}</h2>
          <div className='mb-2 row'>
            <div className='col'>
              <label htmlFor='tokenomics-image' className='col-form-label'>
                Tokenomics Image:
              </label>

              <input
                type='file'
                // required
                //   ref={projectMediaURLRef}
                className='form-control form-control-sm'
                id='tokenomics-image'
                accept='image/*'
                onChange={(event) => {
                  handelFileInput(event, 'tokenomicsImagePreview');
                }}
              />
              <div style={divStyles}>
                <button
                  onClick={() =>
                    uploadFunction(selectedFile, 'tokenomicsImage')
                  }
                  disabled={disabled}
                >
                  upload
                </button>
                {name === 'tokenomicsImage' && <p style={pStyles}>Uploading</p>}
              </div>
            </div>
            {previewImage.tokenomicsImagePreview && (
              <div
                className='project-images'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '10px',
                  justifyContent: 'space-between',
                }}
              >
                <div className='project-logo'>
                  <img
                    src={previewImage.tokenomicsImagePreview}
                    alt=''
                    height='50px'
                    width='50px'
                  />
                </div>
              </div>
            )}
            <div className='col'>
              <label
                htmlFor='token-release-schedule'
                className='col-form-label'
              >
                Token Release Schedule:
              </label>
              <input
                type='file'
                // ref={projectLogoImageRef}
                className='form-control form-control-sm'
                id='token-release-schedule'
                accept='image/*'
                onChange={(event) => {
                  handelFileInput(event, 'tokenReleaseSchedulePreview');
                }}
              />
              <div style={divStyles}>
                <button
                  onClick={() =>
                    uploadFunction(selectedFile, 'tokenReleaseSchedule')
                  }
                  disabled={disabled}
                >
                  upload
                </button>
                {name === 'tokenReleaseSchedule' && (
                  <p style={pStyles}>Uploading</p>
                )}
              </div>
            </div>
            {previewImage.tokenReleaseSchedulePreview && (
              <div
                className='project-images'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '10px',
                  justifyContent: 'space-between',
                }}
              >
                <div className='project-logo'>
                  <img
                    src={previewImage.tokenReleaseSchedulePreview}
                    alt=''
                    height='50px'
                    width='50px'
                  />
                </div>
              </div>
            )}
          </div>
          {buttonDisable && (
            <div className='btn_addnext mt-3'>
              <button
                className='btn btn-success'
                type='submit'
                onClick={() => handleSubmit('notEditDetails')}
              >
                Submit
              </button>
            </div>
          )}
          {editbuttonDisable && (
            <div
              className='btn_addnext mt-3'
              style={{ justifyContent: 'flex-end' }}
            >
              <button
                className='btn btn-success'
                type='submit'
                onClick={() => handleSubmit('editDetails')}
              >
                Update
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TokenMetrics;
