import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AddEditDropdown from './AddEditDropdown';
import './Dropdown.css';

function Dropdown({ entry }) {
  const [data, setData] = useState(true);
  return (
    <div className='dropdown  dropend primary customHover'>
      <button
        className='btn btn-primary dropdown-toggle'
        type='button'
        id='dropdownMenuButton1'
        data-bs-toggle='dropdown'
        aria-expanded='false'
        onClick={() => setData(true)}
      >
        Add More Details
      </button>

      <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
        <li className='linkList'>
          Project Details
          <AddEditDropdown entry={entry} />
        </li>
        <li className='linkList'>
          <Link className='NavLink' to={`../project/${entry._id}`}>
            Token Metrics
          </Link>
        </li>
        <li className='linkList'>
          <Link className='NavLink' to={`../project/${entry._id}`}>
            Metalaunch Screening
          </Link>
        </li> 
      </ul>
    </div>
  );
}

export default Dropdown;
