import React from 'react';
import './index.css';

const SecondPage = (props) => {
  const { launchData, handleInput } = props;
  let arr = Object.values(launchData.percents);
  let sum = 0;
  arr.reduce((a, b) => (sum = parseInt(a) + parseInt(b)), 0);
  return (
    <div>
      {launchData?.times?.map?.((item, ind) => {
        return (
          <div className='col'>
            <label htmlFor='token-address' className='col-form-label'>
              {new Date(item).toString()}
            </label>

            <input
              type='text'
              required
              value={launchData?.percents?.[ind]}
              onChange={(e) => handleInput('percents', e.target.value, ind)}
              className='form-control'
              id='token-address'
            />
          </div>
        );
      })}
      {launchData?.times?.length > 0 && (
        <div className='totalVesting'>
          <div>
            Total of All input value =
            {arr.length === 0
              ? '0'
              : arr.map((elem, ind) => {
                  return (
                    <p>
                      {ind == 0 ? null : '+'} {elem}%{' '}
                    </p>
                  );
                })}
          </div>
          {'  '}
          <p className='sum'>
            <b> = {sum}%</b>
          </p>
        </div>
      )}
    </div>
  );
};

export default SecondPage;
