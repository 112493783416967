import React, { useState } from 'react';
// import './index.css';
import { Modal, Button } from 'react-bootstrap';
import { getAllClaim } from '../../config/config';
import axiosHelper from '../../helpers/axios-helper.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
const Claim = () => {
  const [claimLaunchIdo, setClaimLaunchIdo] = useState([]);
  const [claimLaunchIdoRaw, setClaimLaunchIdoRaw] = useState([]);

  async function fetchData() {
    const result = await axiosHelper(getAllClaim, 'GET', null, null);
    const { data } = result.data;
    setClaimLaunchIdo(data);
    setClaimLaunchIdoRaw(data);
  }
  React.useEffect(() => {
    fetchData();
  }, []);
  const toastWrapper = (type, message) => {
    const toastMap = {
      error: toast.error,
      success: toast.success,
      warn: toast.warn,
    };
    return toastMap[type](message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const searchFromList = (searchStr) => {
    const arr = [];
    for (let index = 0; index < claimLaunchIdoRaw.length; index++) {
      let newArr = JSON.stringify(
        claimLaunchIdoRaw[index].projectData[0]?.projectName
      );
      // console.log(claimLaunchIdoRaw[index].projectData[0]?.projectName);
      if (
        newArr?.toLocaleLowerCase().includes(searchStr?.toLocaleLowerCase())
      ) {
        arr.push(claimLaunchIdoRaw[index]);
      }
    }
    setClaimLaunchIdo(arr);
  };
  return (
    <>
      <ToastContainer />

      <div className='container-fluid'>
        <div className='row align-items-center shadow-sm p-3 bg-light rounded'>
          <div className='col-8 bold'>Manage Claim</div>
          <div className='col-4 text-end'>
            <div className='row align-items-center  text-end'>
              <div className='col align-items-center text-end'>
                <input
                  type='text'
                  className='form-control'
                  id='search'
                  placeholder='type to search'
                  onChange={(e) => {
                    searchFromList(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {claimLaunchIdo.length ? (
        <div className='container-fluid mt-2'>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>#</th>
                <th scope='col'> Project Name</th>
                <th scope='col'> Project Type</th>
                <th scope='col'> Token Distribution Date(IST)</th>
                <th scope='col'>Action</th>
                {/* <th scope="col">Delete</th> */}
              </tr>
            </thead>
            <tbody>
              {claimLaunchIdo?.map((entry, id) => {
                return (
                  <tr key={id + 1}>
                    <td>{id + 1}</td>
                    <td>{entry?.projectData[0]?.projectName}</td>
                    <td>{entry?.projectData[0]?.typeOfProject}</td>
                    {entry?.projectData[0]?.tokenDetails
                      ?.tokenDistributionDate === null ? (
                      <td>-</td>
                    ) : (
                      <td>
                        {moment(
                          entry?.projectData[0]?.tokenDetails
                            ?.tokenDistributionDate
                        ).format('MMMM Do YYYY, h:mm:ss a')}
                      </td>
                    )}
                    <td>
                      <div className='dropdown  dropend primary'>
                        <button
                          className='btn btn-primary dropdown-toggle'
                          type='button'
                          id='dropdownMenuButton1'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          Actions
                        </button>
                        <ul
                          className='dropdown-menu'
                          aria-labelledby='dropdownMenuButton1'
                        >
                          <li className='linkList'>
                            <Link
                              className='NavLink'
                              to={`../Delay/${entry.projectId}`}
                            >
                              Release/Delay
                            </Link>
                          </li>
                          <li className='linkList'>
                            <Link
                              className='NavLink'
                              to={`../view-claim-ido/${entry.projectId}`}
                            >
                              View Claim
                            </Link>
                          </li>
                          <li className='linkList'>
                            <Link
                              className='NavLink'
                              to={`../claim-ido/${entry.projectId}`}
                            >
                              Launch Claim
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className='container-fluid mt-3'>
          <div className='row text-center'>
            <div className='col'>
              <h4>No Data Available!!!</h4>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Claim;
