import React from 'react';
import { createStyles, withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const styles = (theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: '5px',
    },
    closeButton: {
      // position: 'absolute !important',
      right: '5px',
      top: '5px',
    },
  });


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant='h5'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: '5px',
  },
}))(MuiDialogContent);

const Modal = ({ title, onDismiss, children }) => (
  <Dialog open aria-labelledby='customized-dialog-title'>
    <DialogTitle
      id='customized-dialog-title'
      onClose={() => onDismiss && onDismiss()}
    >
      {title}
    </DialogTitle>
    <DialogContent dividers>{children}</DialogContent>
  </Dialog>
);

export default Modal;
