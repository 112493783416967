import React, { useState, useEffect, useRef } from 'react';
import axiosHelper from '../../helpers/axios-helper';
import { getProjectById, projectDetails, upload } from '../../config/config';
import { useParams, useNavigate } from 'react-router-dom';

function ProjectDetails({ isEditProject, projectData }) {
  const { id } = useParams();
  let navigate = useNavigate();

  const [buttonDisable, setButtonDisable] = useState(true);
  const [editbuttonDisable, setEditButtonDisable] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  // const [videoSrc, setVideoSrc] = useState();
  // const [typeofFile, setTypeofFile] = useState();

  const [media, setMedia] = useState({
    projectMedia: '',
    roadMapImage: '',
    teamAndAdvisorImage: '',
    backersPartnersImage: '',
  });
  const [previewImage, setPreviewImage] = useState({
    projectMediaPreview: null,
    roadMapImagePreview: null,
    teamAndAdvisorImagePreview: null,
    backersPartnersImagePreview: null,
  });

  const uploadPreviewImage = (key, val) => {
    const temp = {
      ...previewImage,
    };
    temp[key] = val;
    setPreviewImage(temp);
  };
  const [mimetypeData, setmimetypeData] = useState();
  const [disabled, setDisable] = useState(false);
  const [name, setName] = useState('');
  const [addData, setAddData] = useState({
    projectHighlights: '',
    tokenUtilityDetails: '',
  });
  // const [response, setResponse] = useState();
  const [uploadData, setUploadData] = useState('');
  const [mediaUploaded, setMediaUploaded] = useState('');

  useEffect(() => {
    async function editFetchData() {
      // if (isEditProject) {
      const response = await axiosHelper(
        `${projectDetails}/${id}`,
        'GET',
        null,
        id
      );
      // console.log('Response project', response);
      // setResponse(response);
      setUploadData(response.data?.data?.media[0].url);
      if (response.data.data !== null) {
        setButtonDisable(false);
        setEditButtonDisable(true);
        setAddData({
          projectHighlights: response?.data?.data?.highlights[0],
          tokenUtilityDetails: response?.data?.data?.tokenUtility[0],
        });
        setMedia({
          projectMedia: response?.data?.data?.media[0].url,
          roadMapImage: response?.data?.data?.roadMap[0].url,
          teamAndAdvisorImage: response?.data?.data?.teamAndAdvisor[0].url,
          backersPartnersImage: response?.data?.data?.backersPartners[0].url,
        });
        setPreviewImage({
          projectMediaPreview: response?.data?.data?.media[0].url,
          roadMapImagePreview: response?.data?.data?.roadMap[0].url,
          teamAndAdvisorImagePreview:
            response?.data?.data?.teamAndAdvisor[0].url,
          backersPartnersImagePreview:
            response?.data?.data?.backersPartners[0].url,
        });
      }
      // }
    }
    editFetchData();
  }, [id, isEditProject, mediaUploaded]);

  const uploadMedia = (key, val) => {
    const temp = {
      ...media,
    };
    temp[key] = val;
    setMedia(temp);
  };

  const update = (key, val) => {
    const temp = {
      ...addData,
    };
    temp[key] = val;
    setAddData(temp);
  };

  async function uploadFunction(file, Inputname) {
    setDisable(true);
    setName(Inputname);
    const formData = new FormData();
    formData.append('file', file);
    // console.log(formData);
    const result = await axiosHelper(upload, 'POST', formData);
    setDisable(false);
    setName('');

    if (Inputname === 'projectMedia') {
      uploadMedia('projectMedia', result?.data?.data?.location);
      if (result.status === 200) {
        setMediaUploaded('Media Uploaded');
        setUploadData('');
      }
      //   setProjectMedia(result?.data?.data?.location);
    }
    if (Inputname === 'roadMapImage') {
      uploadMedia('roadMapImage', result?.data?.data?.location);
      //   setRoadMapImage(result?.data?.data?.location);
    }
    if (Inputname === 'teamAndAdvisorImage') {
      uploadMedia('teamAndAdvisorImage', result?.data?.data?.location);
      //   setTeamAndAdvisorImage(result?.data?.data?.location);
    }
    if (Inputname === 'backersPartnersImage') {
      uploadMedia('backersPartnersImage', result?.data?.data?.location);
      //   setBackersPartnersImage(result?.data?.data?.location);
    }

    if (result?.data?.data?.mimetype.includes('image/gif')) {
      setmimetypeData('GIF');
      return;
    } else if (
      result?.data?.data?.mimetype.includes(
        'image/png' || 'image/jpeg' || 'image/jpg'
      )
    ) {
      setmimetypeData('IMAGE');
      return;
    } else if (result?.data?.data?.mimetype.includes('audio')) {
      setmimetypeData('AUDIO');
      return;
    } else if (result?.data?.data?.mimetype.includes('video')) {
      setmimetypeData('VIDEO');
      return;
    }
    setmimetypeData(result?.data?.data?.mimetype);
  }

  const handelFileInput = (event, previewCheck) => {
    setSelectedFile(event.target.files[0]);
    if (previewCheck === 'projectMediaPreview') {
      const file = event.target.files[0];
      if (!file) return;
      uploadPreviewImage('projectMediaPreview', URL.createObjectURL(file));
      // if (file.type === 'image/png') {
      //   setTypeofFile('Image');
      // } else if (file.type === 'image/jpeg') {
      //   setTypeofFile('Image');
      // } else if (file.type === 'image/jpg') {
      //   setTypeofFile('Image');
      // } else if (file.type === 'image/gif') {
      //   setTypeofFile('Image');
      // } else {
      //   setTypeofFile('Video');
      // }
    }
    if (previewCheck === 'roadMapImagePreview') {
      const file = event.target.files[0];
      if (!file) return;
      uploadPreviewImage('roadMapImagePreview', URL.createObjectURL(file));
    }
    if (previewCheck === 'teamAndAdvisorImagePreview') {
      const file = event.target.files[0];
      if (!file) return;
      uploadPreviewImage(
        'teamAndAdvisorImagePreview',
        URL.createObjectURL(file)
      );
    }
    if (previewCheck === 'backersPartnersImagePreview') {
      const file = event.target.files[0];
      if (!file) return;
      uploadPreviewImage(
        'backersPartnersImagePreview',
        URL.createObjectURL(file)
      );
    }
  };

  // const handleChange = (event) => {
  //   const file = event.target.files[0];
  //   if (!file) return;
  //   setVideoSrc(URL.createObjectURL(file));
  // };
  // const videoRef = useRef();
  // useEffect(() => {
  //   videoRef.current?.load();
  // }, [media.projectMedia]);

  const handleSubmit = async () => {
    const submit = {
      projectId: parseInt(id),
      highlights: [addData.projectHighlights],
      tokenUtility: [addData.tokenUtilityDetails],
      media: [{ url: media.projectMedia, type: mimetypeData }],
      roadMap: [{ url: media.roadMapImage, type: 'IMAGE' }],
      teamAndAdvisor: [{ url: media.teamAndAdvisorImage, type: 'IMAGE' }],
      backersPartners: [{ url: media.backersPartnersImage, type: 'IMAGE' }],
    };

    const result = await axiosHelper(projectDetails, 'POST', null, submit);
    if (result.status === 200) {
      navigate('/projects');
    }
  };
  const divStyles = {
    display: 'flex',
    margin: '0px',
  };
  const pStyles = {
    margin: '0px 0 0 10px',
  };

  return (
    <div>
      <div className='add_page' style={{ left: 0 }}>
        <div className='card inner_add_page'>
          <h2>{`${projectData.projectName} ${projectData.typeOfProject}`}</h2>
          <div className='mb-3 row' style={{ width: '100%' }}>
            <div className='col'>
              <label htmlFor='project-highlights' className='col-form-label'>
                Project Highlights:
              </label>
              <textarea
                // required
                type='text'
                className='form-control'
                id='project-highlights'
                name='projectHighlights'
                value={addData.projectHighlights}
                onChange={(e) => update('projectHighlights', e.target.value)}
              />
              <p className='nonRequired'>Accepted Format String ; String</p>
            </div>
          </div>
          <div className='mb-3 row' style={{ width: '100%' }}>
            <div className='col'>
              <label htmlFor='token-utility-details' className='col-form-label'>
                Token Utility Details:
              </label>
              <textarea
                // required
                className='form-control'
                id='token-utility-details'
                name='tokenUtilityDetails'
                value={addData.tokenUtilityDetails}
                onChange={(e) => update('tokenUtilityDetails', e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className='mb-2 row'>
            <div className='col'>
              <label htmlFor='media-URL' className='col-form-label'>
                Project Media :
              </label>
              <input
                type='file'
                // required
                // ref={videoRef}
                name='media-URL'
                className='form-control form-control-sm'
                id='media-URL'
                accept='image/*,audio/*,gif/*,video/mp4,video/x-m4v,video/*'
                onChange={(event) => {
                  handelFileInput(event, 'projectMediaPreview');
                }}
                // onChange={(event) => {
                //   handleChange(event);
                //
              />
              <div style={divStyles}>
                <button
                  onClick={() => uploadFunction(selectedFile, 'projectMedia')}
                  disabled={disabled}
                >
                  upload
                </button>
                {name === 'projectMedia' && <p style={pStyles}>Uploading</p>}
              </div>
              <p className='nonRequired'>
                Accepted media (MP4, GIF, JPEG max 50MB)
              </p>
              {mediaUploaded ? (
                <p
                  className='nonRequired'
                  style={{ fontSize: '15px', color: 'blue' }}
                >
                  Media Uploaded
                </p>
              ) : uploadData !== undefined ? (
                <p
                  className='nonRequired'
                  style={{ fontSize: '15px', color: 'blueviolet' }}
                >
                  Media present
                </p>
              ) : (
                <p
                  className='nonRequired'
                  style={{ fontSize: '15px', color: 'red' }}
                >
                  Media Not Present
                </p>
              )}
              {/* <div
                className='project-images'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '5px',
                  justifyContent: 'space-between',
                }}
              >
                <div className='project-logo'>
                  {typeofFile === 'Image' ? (
                    <img
                      src={previewImage.projectMediaPreview}
                      alt=''
                      height='50px'
                      width='50px'
                    />
                  ) : (
                    // <video
                    //   key={previewImage.projectMediaPreview}
                    //   ref={videoRef}
                    //   height='100px'
                    //   width='200px'
                    //   controls
                    //   muted
                    //   // autoPlay
                    // >
                    //   <source src={previewImage.projectMediaPreview} />
                    //   Your browser does not support the video tag.
                    // </video>
                    <img
                      src={previewImage.projectMediaPreview}
                      alt=''
                      height='50px'
                      width='50px'
                    />
                  )}
                </div>
              </div> */}
            </div>
            <div className='col'>
              <label htmlFor='roadmap-image' className='col-form-label'>
                Road Map Image :
              </label>

              <input
                type='file'
                // ref={roadMapImageRef}
                className='form-control form-control-sm'
                id='roadmap-image'
                accept='image/*'
                onChange={(event) => {
                  handelFileInput(event, 'roadMapImagePreview');
                }}
              />
              <div style={divStyles}>
                <button
                  onClick={() => uploadFunction(selectedFile, 'roadMapImage')}
                  disabled={disabled}
                >
                  upload
                </button>
                {name === 'roadMapImage' && <p style={pStyles}>Uploading</p>}
              </div>
              {previewImage.roadMapImagePreview && (
                <div
                  className='project-images'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className='project-logo'>
                    <img
                      src={previewImage.roadMapImagePreview}
                      alt=''
                      height='50px'
                      width='50px'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='mb-2 row'>
            <div className='col'>
              <label
                htmlFor='team-and-advisor-image'
                className='col-form-label'
              >
                Team And Advisor Image :
              </label>

              <input
                type='file'
                // ref={teamAndAdvisorImageRef}
                className='form-control form-control-sm'
                id='team-and-advisor-image'
                accept='image/*'
                onChange={(event) => {
                  handelFileInput(event, 'teamAndAdvisorImagePreview');
                }}
              />
              <div style={divStyles}>
                <button
                  onClick={() =>
                    uploadFunction(selectedFile, 'teamAndAdvisorImage')
                  }
                  disabled={disabled}
                >
                  upload
                </button>
                {name === 'teamAndAdvisorImage' && (
                  <p style={pStyles}>Uploading</p>
                )}
              </div>
              {previewImage.teamAndAdvisorImagePreview && (
                <div
                  className='project-images'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className='project-logo'>
                    <img
                      src={previewImage.teamAndAdvisorImagePreview}
                      alt=''
                      height='50px'
                      width='50px'
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='col'>
              <label
                htmlFor='backers-partners-image'
                className='col-form-label'
              >
                Backers Partners Image :
              </label>

              <input
                type='file'
                // ref={backersPartnersImageRef}
                className='form-control form-control-sm'
                id='backers-partners-image'
                accept='image/*'
                onChange={(event) => {
                  handelFileInput(event, 'backersPartnersImagePreview');
                }}
              />
              <div style={divStyles}>
                <button
                  onClick={() =>
                    uploadFunction(selectedFile, 'backersPartnersImage')
                  }
                  disabled={disabled}
                >
                  upload
                </button>
                {name === 'backersPartnersImage' && (
                  <p style={pStyles}>Uploading</p>
                )}
              </div>
              {previewImage.backersPartnersImagePreview && (
                <div
                  className='project-images'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className='project-logo'>
                    <img
                      src={previewImage.backersPartnersImagePreview}
                      alt=''
                      height='50px'
                      width='50px'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {buttonDisable && (
            <div className='btn_addnext mt-3'>
              <button
                className='btn btn-success'
                type='submit'
                onClick={() => handleSubmit('notEditDetails')}
              >
                Submit
              </button>
            </div>
          )}
          {editbuttonDisable && (
            <div
              className='btn_addnext mt-3'
              style={{ justifyContent: 'flex-end' }}
            >
              <button
                className='btn btn-success'
                type='submit'
                onClick={() => handleSubmit('editDetails')}
              >
                Update
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectDetails;
