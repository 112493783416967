import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import axiosHelper from '../../helpers/axios-helper';
import { Link } from 'react-router-dom';

function Launched() {
  const [projects, setProjects] = useState([]);
  const [projectsRaw, setProjectsRaw] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetchData() {
    setLoading(true);
    const result = await axiosHelper(
      'v2/projects/non-claimed',
      'GET',
      null,
      null
    );
    const { data } = result.data;
    setProjects(data);
    setProjectsRaw(data);
    setLoading(false);
  }
  const searchFromList = (searchStr) => {
    const arr = [];
    for (let index = 0; index < projectsRaw.length; index++) {
      if (
        JSON.stringify(projectsRaw[index])
          .toLocaleLowerCase()
          .includes(searchStr.toLocaleLowerCase())
      ) {
        arr.push(projectsRaw[index]);
      }
    }
    setProjects(arr);
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (loading) {
    return (
      <Container
        sx={{
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress sx={{ margin: 'auto' }} />
      </Container>
    );
  }
  if (!projects.length) {
    return (
      <>
        <div className='container-fluid'>
          <div className='row align-items-center shadow-sm p-3 bg-light rounded'>
            <div className='col-8 bold'></div>
            <div className='col-4 text-end'>
              <div className='row align-items-center  text-end'>
                <div className='col align-items-center text-end'>
                  <input
                    type='text'
                    className='form-control'
                    id='search'
                    placeholder='type to search'
                    onChange={(e) => {
                      searchFromList(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid mt-3'>
          <div className='row text-center'>
            <div className='col'>
              <h4>No Data Available!!!</h4>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className='container-fluid'>
        <div className='row align-items-center shadow-sm p-3 bg-light rounded'>
          <div className='col-8 bold'></div>
          <div className='col-4 text-end'>
            <div className='row align-items-center  text-end'>
              <div className='col align-items-center text-end'>
                <input
                  type='text'
                  className='form-control'
                  id='search'
                  placeholder='type to search'
                  onChange={(e) => {
                    searchFromList(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: '600' }}>Project Name</TableCell>
              <TableCell sx={{ fontWeight: '600' }} align='center'>
                Project Type
              </TableCell>
              <TableCell sx={{ fontWeight: '600' }} align='center'>
                Sale End Date
              </TableCell>
              <TableCell sx={{ fontWeight: '600' }} align='center'>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project) => (
              <TableRow
                key={project._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {project.projectName}
                </TableCell>
                <TableCell align='center'>{project.typeOfProject}</TableCell>
                <TableCell align='center'>
                  {moment(project.idoDates.roundTwoEndDate).format(
                    'DD MMM  YYYY, HH:mm'
                  )}
                </TableCell>
                <TableCell align='center'>
                  <Link to={`/claim-ido/${project._id}`}>
                    <Button variant='contained'>Launch Claim</Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Launched;
