import ReactDOM from "react-dom";
import React from "react";
import App from "./App.js";
import Providers from "./providers.js";
import {BASE_URL} from './config/config';
import './index.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink
} from "@apollo/client";
const httpLink = new HttpLink({ uri: `${BASE_URL}/graphql` });

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem('token');

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const Index = () => {
  return (
    <ApolloProvider client={client}>
    <Providers>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Providers>
    </ApolloProvider>
  );
};

ReactDOM.render(<Index />, document.getElementById("root"));
