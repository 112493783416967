import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import NavBar from './components/navbar';
import Projects from './components/projects';
import Launched from './components/launched';
import Transfer from './components/transfer';
import Project from './components/new-project';
import Contracts from './components/contracts';
import Networks from './components/networks';
import IdoLaunch from './components/ido-wizard';
import DataTable from './components/viewDetails';
import Whitlisted from './components/whitlisted';
import './App.css';
import Login from './components/auth/Login';
import Manage from './components/manage-user/manage';
import AddUser from './components/manage-user/addUser';
import UpdatePass from './components/auth/updatePass';
import EditUser from './components/manage-user/editUser';
import StakePool from './components/stake-pool';
import ClaimLaunch from './components/claimLaunch';
import SaleType from './components/SaleType/SaleType';
import ProjectType from './components/ProjectType/ProjectType';
import ProjectDetails from './components/ProductMoreDetailsPage/ProjectDetails';
import MoreDetails from './components/MoreDetails/MoreDetails';
import TokenMetrics from './components/ProductMoreDetailsPage/TokenMetrics';
import MetalaunchScreening from './components/ProductMoreDetailsPage/MetalaunchScreening';
import Claim from './components/claim';
import ViewClaim from './components/claim/viewClaim';
import DelayClaim from './components/claim/delayClaim';
import FeaturedProjects from './components/featured-projects';
import ProjectATH from './components/projectATH';
const App = () => {
  const [walletDetails, updateWalletDetails] = useState();
  const [user, setUser] = useState(null);
  useEffect(() => {
    const data = localStorage.getItem('token');
    data && JSON.parse(data) ? setUser(true) : setUser(false);
  }, []);

  return (
    <>
      <div className='main'>
        <BrowserRouter>
          {user === true ? (
            <NavBar updateWalletDetails={updateWalletDetails} />
          ) : null}
          <Routes>
            {user && (
              <>
                <Route path='/' element={<Projects />} />
                <Route path='/more-details/:id' element={<MoreDetails />} />
                {/* <Route
                  path='/project-details/:id'
                  element={<ProjectDetails />}
                />
                <Route path='/token-metrics/:id' element={<TokenMetrics />} />
                <Route
                  path='/metalaunch-screening/:id'
                  element={<MetalaunchScreening />}
                /> */}
                <Route path='/projects' element={<Projects />} />
                <Route path='/contracts' element={<Contracts />} />
                <Route path='/networks' element={<Networks />} />
                <Route path='/stake-pool' element={<StakePool />} />
                <Route
                  path='/featured-projects'
                  element={<FeaturedProjects />}
                />
                <Route path='/project-ath' element={<ProjectATH />} />
                <Route path='/project/:id' element={<Project />} />
                <Route path='/copyproject/:id' element={<Project />} />
                {/* <Route path="sale-type" element={<SaleType />} /> */}
                <Route path='project-type' element={<ProjectType />} />

                <Route path='/launch-ido/:id' element={<IdoLaunch />} />
                <Route path='/claim-ido/:id' element={<ClaimLaunch />} />

                <Route path='/launch-ido/:id' element={<IdoLaunch />} />
                <Route path='/claim-ido/:id' element={<ClaimLaunch />} />
                <Route
                  path='/view-claim-ido/:id'
                  element={<ViewClaim heading={false} />}
                />
                <Route path='/delay/:id' element={<DelayClaim />} />
                <Route
                  path='/transfer'
                  element={<Transfer walletDetails={walletDetails} />}
                />
                <Route path='/launched-idos' element={<Launched />} />
                <Route path='/launched-claim' element={<Claim />} />
                <Route path='/view-details/:id' element={<DataTable />} />
                <Route path='Whitlisted/:id' element={<Whitlisted />} />
                <Route path='/manage-user' element={<Manage />} />
                <Route path='/add-user' element={<AddUser />} />
                <Route path='/add-user/:id' element={<EditUser />} />
                <Route path='/change-password/:id' element={<UpdatePass />} />
              </>
            )}
            {!user && (
              <>
                <Route path='/login' element={<Login />} />
              </>
            )}

            <Route
              path='*'
              element={<Navigate to={user ? '/projects' : '/login'} />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
};

export default App;
