import React, { useEffect, useState, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { PROJECT_QUERY, PROJECT_REMOVE_COINGECKO_ID } from '../../graphql/projects';
import { COINGECKO_URL } from '../../config/config';
import './index.css';
import {
    AiOutlineAppstoreAdd,
    AiOutlineSearch
} from "react-icons/ai";

import { FiRefreshCw } from 'react-icons/fi';
import { Modal, Button } from "react-bootstrap";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { IoCloseCircleSharp } from "react-icons/io5";
import axiosHelper from '../../helpers/axios-helper';
import axios from "axios";
import { updateBulkCoingeckoId, refreshATHCoinsUrl } from '../../config/config';

const ProjectATH = () => {
    const [pageNo, setPageNo] = useState(0);
    const [perPageItems, setPerPageItems] = useState(10);
    const [projectStatus, setProjectStatus] = useState(["ended"]);
    const [keyNames, setKeyNames] = useState(["coingecko"]);
    const [keyExistType, setKeyExistType] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [projectData, setProjectData] = useState([]);
    const [pagination, setPagination] = useState(0)
    const [selectedProjects, setSelectedProjects] = useState({})
    const [show, setShow] = React.useState(false);
    const [coingeckoList, setCoingeckoList] = useState([]);
    const [coingeckoIdList, setCoingeckoIdList] = useState([])
    const [showRemoveCoingeckoIdPopUp, setShowRemoveCoingeckoIdPopUp] = React.useState(false);
    const [selectedProjectForRemoveCoingeckoId, setSelectedProjectForRemoveCoingeckoId] = React.useState({});

    const { fetchMore } = useQuery(PROJECT_QUERY, {
        variables: { pageNo, perPageItems, projectStatus, keyNames, keyExistType, searchValue },
        notifyOnNetworkStatusChange: true
    });

    const [getLazyResults, lazyResults] = useLazyQuery(PROJECT_QUERY, {
        variables: { pageNo, perPageItems, projectStatus, keyNames, keyExistType, searchValue },
        notifyOnNetworkStatusChange: true
    });
    const [mutateProject, { data_, error }] = useMutation(PROJECT_REMOVE_COINGECKO_ID);
    async function fetchCoingeckoList() {
        try {
            const response = await axios.get(COINGECKO_URL);
            setCoingeckoList(response.data);
            let idList = []
            response.data.map(item => {
                idList.push(item.id);
            });
            setCoingeckoIdList(idList)
        } catch (error) {
            console.log("error ",error);
        }
    }
    async function getMoreData() {
        try {
            await fetchMore({
                variables: { pageNo, perPageItems, projectStatus, keyNames, keyExistType, searchValue },
                updateQuery: (previousResult, { fetchMoreResult, queryVariables }) => {
                    setProjectData(fetchMoreResult?.projects?.data)
                    setPagination(fetchMoreResult?.projects?.pagination)
                    const total = Math.ceil(fetchMoreResult?.projects?.pagination?.totalDocumnets / perPageItems)
                },
            });
        } catch (error) {
            console.log("error ", error)
        }
    }
    function selectAllProjects() {
        if (Object.keys(selectedProjects).length === projectData.length) {
            setSelectedProjects({})
        } else {
            let obj = {}
            projectData.forEach(item => {
                obj[item._id] = item
            });
            setSelectedProjects(obj)
        }
    }
    function selectProject(project) {
        const _selectedProjects = JSON.parse(JSON.stringify(selectedProjects))
        if (Object.keys(selectedProjects).includes(project._id.toString())) {
            delete _selectedProjects[project._id.toString()]

        } else {
            _selectedProjects[project._id.toString()] = project
        }
        setSelectedProjects(_selectedProjects)
    }
    const handleClose = () => {
        setShow(false);
        if (pageNo) {
            setPageNo(0);
        } else {
            getMoreData();
        }
        setSelectedProjects({});
        setShowRemoveCoingeckoIdPopUp(false);
    };
    const handleShow = () => {
        setShow(true);
    };
    const handleRemoveCoingeckoIdPopUp = async (project) => {
        setShowRemoveCoingeckoIdPopUp(true);
        setSelectedProjectForRemoveCoingeckoId(project);
    }
    function addCoingeckoId(key, value) {
        const _selectedProjects = JSON.parse(JSON.stringify(selectedProjects));
        if (coingeckoIdList.includes(value)) {
            _selectedProjects[key]['coingecko']['coingeckoId'] = value;

        } else {
            _selectedProjects[key]['coingecko']['coingeckoId'] = null;
        }
        setSelectedProjects(_selectedProjects)
    }
    async function updateProjects() {
        try {
            let body = {}
            for (let key in selectedProjects) {
                if (selectedProjects[key]?.coingecko?.coingeckoId) {
                    body[key] = { coingecko: selectedProjects[key]?.coingecko }
                }
            }
            const res = await axiosHelper(
                `${updateBulkCoingeckoId}`,
                'post',
                null,
                JSON.stringify(body)
            );
            handleClose();
            toast.success(`Coingecko id added to projects successfully! `, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            getMoreData();
        } catch (error) {
            handleClose();
            toast.error("Something went wrong!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    useEffect(() => {
        getMoreData();
    }, [pageNo, perPageItems, keyExistType]);

    useEffect(() => {
        fetchCoingeckoList();
    }, []);

    function goToNextPage() {
        if (pageNo < Math.ceil(pagination?.totalDocumnets / pagination?.perPageItems))
            setPageNo((pageNo) => pageNo + 1);
    }
    function goToPreviousPage() {
        if (pageNo)
            setPageNo((pageNo) => pageNo - 1);
    }
    const getPaginationGroup = () => {
        let arr = []
        for (let i = 0; i < Math.ceil(pagination?.totalDocumnets / pagination?.perPageItems); i++) {
            arr.push(i)
        }
        return arr;

    };

    function toggleProjectSwitch() {
        setPageNo(0);
        setProjectData([]);
        setKeyExistType(!keyExistType);
    }
    async function removeIdFromProject(id) {
        try {
            await mutateProject({ variables: { _id: id.toString() } });
            handleClose();
        } catch (error) {

        }
    }
    async function refreshATHCoins() {
        try {

            const res = await axiosHelper(
                `${refreshATHCoinsUrl}`,
                'post',
                null,
                null
            );
            handleClose();
            toast.success(`Coin ATH refresh started successfully! `, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            getMoreData();
        } catch (error) {
            handleClose();
            toast.error("Something went wrong!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    if (lazyResults.loading) return <p>Loading...</p>;
    return (
        <>
            <ToastContainer />
            <div className="container-fluid">
                <div className="row align-items-center shadow-sm py-2 bg-light rounded">
                    <div className="col-4 bold">Manage Project Coingecko</div>
                    <div className="col-8 text-end">
                        <div className="d-flex flex-row-reverse">

                            <div>
                                <button className={`btn btn-primary marginRight ${!Object.keys(selectedProjects).length ? 'disabled' : ''}`} onClick={() => { handleShow() }} >  <AiOutlineAppstoreAdd /> Bulk Update</button>
                            </div>

                            <div className="px-1">
                                <button className={`btn btn-primary marginRight`} onClick={() => { refreshATHCoins() }} >  <FiRefreshCw /></button>
                            </div>

                            <div className="px-1 d-flex">
                                <input className="form-control me-2" type="text" placeholder="type project name" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                <button className="btn btn-primary" type="button" onClick={() => getMoreData()}><AiOutlineSearch /></button>
                            </div>

                            <div className="form-check form-switch px-2">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={keyExistType} onChange={() => toggleProjectSwitch()} />
                                <label className="form-check-label" for="flexSwitchCheckChecked"> {keyExistType ? 'Projects with Coingecko' : 'Projects with out Coingecko'}</label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {
                projectData.length ?
                    <><div className='container-fluid mt-1 tablecss'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" checked={Object.keys(selectedProjects).length === projectData.length} id="selectAll" onChange={() => selectAllProjects()} />
                                        </div>
                                    </th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Type</th>

                                    {
                                        keyExistType && (
                                            <>
                                                <th scope="col">Coin Gecko Key</th>
                                                <th scope="col">ATH</th>
                                                <th scope="col">Remove Key</th>
                                            </>
                                        )
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {projectData?.map((entry, id) => {
                                    return (<tr key={id + 1}>
                                        <td>{(id + 1) + (pageNo * 10)}</td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={Object.keys(selectedProjects).includes(entry._id.toString())} id={`select${id}`} onChange={() => selectProject(entry)} />
                                            </div>
                                        </td>
                                        <td>{entry?.projectName || 'NA'}</td>
                                        <td>{entry.typeOfProject || 'NA'}</td>

                                        {
                                            keyExistType && (
                                                <>
                                                    <td>{entry.coingecko?.coingeckoId}</td>
                                                    <td className={`bold ${(entry.coingecko?.ath >= 0) ? 'green' : 'red'}`}>{typeof entry.coingecko?.ath === 'number' ? entry.coingecko?.ath + '%' : 'NA'}</td>
                                                    <td>
                                                        <IoCloseCircleSharp color="red" size={32} className='cursor' onClick={() => handleRemoveCoingeckoIdPopUp(entry)} />
                                                    </td>
                                                </>
                                            )
                                        }
                                    </tr>);
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                        <div className='container-fluid'>
                            <div className='row shadow-sm bg-light rounded'>
                                <div className='col text-center'>
                                    <div className="py-1">
                                        <span className="px-2"><b>Total Project: </b>{pagination?.totalDocumnets} </span>
                                        <span className="px-2"><b>Total Pages: </b>{Math.ceil(pagination?.totalDocumnets / pagination?.perPageItems)} </span>
                                        <span className="px-2"><b>Per Page Project: </b> {perPageItems}</span>
                                    </div>
                                    <ul className="pagination pt-1">
                                        <li className={`page-item cursor ${pageNo === 0 ? 'disabled' : ''}`} onClick={() => goToPreviousPage()}><a className="page-link">Previous</a></li>

                                        {getPaginationGroup().map((item, index) => (
                                            <li className={`page-item cursor ${pageNo === item ? 'active' : null}`} onClick={() => setPageNo(item)}><a className="page-link" key={index}>{item + 1}</a></li>
                                        ))
                                        }
                                        <li className={`page-item cursor ${pageNo === Math.floor(pagination?.totalDocumnets / pagination?.perPageItems) ? 'disabled' : ''}`} onClick={() => goToNextPage()}><a className="page-link">Next</a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <Modal
                            show={showRemoveCoingeckoIdPopUp}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Remove Coingecko Id</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className="col mb-3">
                                        <span>Are you sure to delete <b> {`${selectedProjectForRemoveCoingeckoId.projectName} - [ ${selectedProjectForRemoveCoingeckoId?.coingecko?.coingeckoId} ]`} </b>? </span>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => removeIdFromProject(selectedProjectForRemoveCoingeckoId._id)}>Remove Coingecko Id</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            centered
                            size="lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Update Coingecko Id to Project</Modal.Title>
                            </Modal.Header>
                            <Modal.Body scrollable={true}
                                style={{
                                    maxHeight: 'calc(100vh - 210px)',
                                    overflowY: 'auto'
                                }}>
                                {Object.keys(selectedProjects).map(key => (
                                    <div className="row">
                                        <div className="col mb-3">
                                            <label htmlFor="projectName" className="form-label">
                                                Project Name
                                                <span className="px-1">{selectedProjects[key]?.coingecko?.coingeckoId ? <BsFillPatchCheckFill color='green' /> : <IoCloseCircleSharp color='red' />}</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="projectName"
                                                value={selectedProjects[key]?.projectName}
                                            // onChange={(e) => onChangeHandler("projectName", e.target.value)}
                                            />
                                        </div>
                                        <div className="col mb-3">
                                            {/* <label for="exampleDataList" className="form-label">Select Coingecko Id</label>
                                        <select
                                            className="form-select form-select-sm"
                                            aria-label="Default select example"
                                            // value={selectedFeaturedProject?.chainId}
                                            onChange={(e) => addCoingeckoId(key, e.target.value)}
                                        >
                                            <option value=''>Select Network Name</option>
                                            {coingeckoList?.map((entry, id) => {
                                            return (
                                                <option value={entry.id}>
                                                    {entry.name}
                                                </option>
                                            );
                                            })}
                                        </select> */}
                                            <label for="exampleDataList" className="form-label">
                                                Select Coingecko Id
                                                <span className="px-1">{selectedProjects[key]?.coingecko?.coingeckoId ? <BsFillPatchCheckFill color='green' /> : <IoCloseCircleSharp color='red' />}</span>
                                            </label>
                                            <input className="form-control form-control-sm" list="datalistOptions" id="exampleDataList" placeholder="Type to search..." value={selectedProjects[key]?.coingecko?.coingeckoId} onChange={(e) => addCoingeckoId(key, e.target.value)} />
                                            <datalist id="datalistOptions">
                                                <option value=''>Reset</option>
                                                {coingeckoList?.map(item => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))
                                                }
                                            </datalist>
                                        </div>
                                    </div>
                                ))}

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="success" onClick={updateProjects}>
                                    <AiOutlineAppstoreAdd size={20} /> Update
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </>
                    :
                    (
                        <div className='container-fluid mt-3'>
                            <div className='row text-center'>
                                <div className='col'>
                                    <h4>No Data Available!!!</h4>
                                </div>
                            </div>
                        </div>
                    )
            }
        </>
    )
}


export default ProjectATH;
