import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosHelper from '../../helpers/axios-helper';
import {
  getProjectById,
  metalaunchScreening,
  upload,
} from '../../config/config';

function MetalaunchScreening({ isEditProject, projectData }) {
  const { id } = useParams();

  let navigate = useNavigate();
  const [selectedFile1, setSelectedFile1] = useState('');
  const [selectedFile2, setSelectedFile2] = useState('');
  const [selectedFile3, setSelectedFile3] = useState('');
  const [disabled, setDisable] = useState(false);
  const [name, setName] = useState('');
  const [media, setMedia] = useState({
    leadVC1: '',
    leadVC2: '',
    leadVC3: '',

    leadVCImage1: '',
    leadVCImage2: '',
    leadVCImage3: '',
    TokenMetrics: false,
    Team: '',
    MetalaunchCouncilApproval: false,
  });
  const [buttonDisable, setButtonDisable] = useState(true);
  const [editbuttonDisable, setEditButtonDisable] = useState(false);
  const [previewImage1, setPreviewImage1] = useState(null);
  const [previewImage2, setPreviewImage2] = useState(null);
  const [previewImage3, setPreviewImage3] = useState(null);
  const [_id, set_id] = useState(null);

  useEffect(() => {
    async function editFetchData() {
      // if (isEditProject) {
      const response = await axiosHelper(
        `${metalaunchScreening}/${id}`,
        'GET',
        null,
        id
      );
      if (response.data.data !== null) {
        setButtonDisable(false);
        setEditButtonDisable(true);
        // console.log('Response', response);
        setMedia({
          ...media,
          leadVC1: response?.data?.data?.lead[0]?.leadVC,
          leadVC2: response?.data?.data?.lead[1]?.leadVC,
          leadVC3: response?.data?.data?.lead[2]?.leadVC,
          leadVCImage1: response?.data?.data?.lead[0]?.leadVCImage,
          leadVCImage2: response?.data?.data?.lead[1]?.leadVCImage,
          leadVCImage3: response?.data?.data?.lead[2]?.leadVCImage,
          Team: response?.data?.data?.team,
          MetalaunchCouncilApproval: response?.data?.data?.mcApproval,
          TokenMetrics: response?.data?.data?.tokenMetrics,
        });
        // setPreviewImage(response?.data?.data?.leadVCImage);
        setPreviewImage1(response?.data?.data?.lead[0]?.leadVCImage);
        setPreviewImage2(response?.data?.data?.lead[1]?.leadVCImage);
        setPreviewImage3(response?.data?.data?.lead[2]?.leadVCImage);
        set_id(response.data.data._id);
      }

      // }
    }

    editFetchData();
  }, [id, isEditProject]);

  const uploadMedia = (key, val) => {
    const temp = {
      ...media,
    };
    temp[key] = val;
    setMedia(temp);
  };

  async function uploadFunction(file, Inputname) {
    setDisable(true);
    setName(Inputname);
    const formData = new FormData();
    formData.append('file', file);

    const result = await axiosHelper(upload, 'POST', formData);
    setDisable(false);
    setName('');

    if (Inputname === 'leadVCImage1') {
      uploadMedia('leadVCImage1', result?.data?.data?.location);
    }
    if (Inputname === 'leadVCImage2') {
      uploadMedia('leadVCImage2', result?.data?.data?.location);
    }
    if (Inputname === 'leadVCImage3') {
      uploadMedia('leadVCImage3', result?.data?.data?.location);
    }
  }

  const handelFileInput = (event, previewCheck) => {
    if (previewCheck === 'previewImage1') {
      const file = event.target.files[0];
      if (!file) return;
      setPreviewImage1(URL.createObjectURL(file));
      setSelectedFile1(file);
    }
    if (previewCheck === 'previewImage2') {
      const file = event.target.files[0];
      if (!file) return;
      setPreviewImage2(URL.createObjectURL(file));
      setSelectedFile2(file);
    }
    if (previewCheck === 'previewImage3') {
      const file = event.target.files[0];
      if (!file) return;
      setPreviewImage3(URL.createObjectURL(file));
      setSelectedFile3(file);
    }
  };
  const handleSubmit = async (props) => {
    const submit = {
      projectId: parseInt(id),
      _id: _id,
      lead: [
        {
          leadVC: media.leadVC1,
          leadVCImage: media.leadVCImage1,
        },
        {
          leadVC: media.leadVC2,
          leadVCImage: media.leadVCImage2,
        },
        {
          leadVC: media.leadVC3,
          leadVCImage: media.leadVCImage3,
        },
      ],
      tokenMetrics: media.TokenMetrics,
      team: media.Team,
      mcApproval: media.MetalaunchCouncilApproval,
      // screeningFlag: false,
    };
    if (props === 'editDetails') {
      const result = await axiosHelper(
        `${metalaunchScreening}/${id}`,
        'PUT',
        null,
        submit
      );
      if (result.status === 200) {
        navigate('/projects');
      }
    } else {
      const result = await axiosHelper(
        metalaunchScreening,
        'POST',
        null,
        submit
      );
      if (result.status === 200) {
        navigate('/projects');
      }
    }
  };
  const divStyles = {
    display: 'flex',
    margin: '0px',
  };
  const pStyles = {
    margin: '0px 0 0 10px',
  };

  return (
    <div>
      <div className='add_page' style={{ left: 0 }}>
        <div className='card inner_add_page'>
          <h2>{`${projectData.projectName} ${projectData.typeOfProject}`}</h2>
          <div style={{ display: 'flex', gap: '20px' }}>
            <div className='mb-3 row' style={{ width: '50%' }}>
              <div className='col'>
                <label htmlFor='lead-vc' className='col-form-label'>
                  Lead VC1:
                </label>
                <input
                  // required
                  className='form-control'
                  id='lead-vc'
                  name='leadVC'
                  value={media.leadVC1}
                  onChange={(e) => uploadMedia('leadVC1', e.target.value)}
                />
              </div>
            </div>
            <div className='mb-2 row'>
              <div className='col'>
                <label htmlFor='lead-vc-image' className='col-form-label'>
                  Lead VC1 Image:
                </label>

                <input
                  type='file'
                  // ref={projectLogoImageRef}
                  className='form-control form-control-sm'
                  id='lead-vc-image'
                  accept='image/*'
                  onChange={(event) => handelFileInput(event, 'previewImage1')}
                />
                <div style={divStyles}>
                  <button
                    onClick={() =>
                      uploadFunction(selectedFile1, 'leadVCImage1')
                    }
                    disabled={disabled}
                  >
                    upload
                  </button>
                  {name === 'leadVCImage' && <p style={pStyles}>Uploading</p>}
                </div>
                {previewImage1 && (
                  <div
                    className='project-images'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '10px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className='project-logo'>
                      <img
                        src={previewImage1}
                        alt=''
                        height='50px'
                        width='50px'
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '20px' }}>
            <div className='mb-3 row' style={{ width: '50%' }}>
              <div className='col'>
                <label htmlFor='lead-vc' className='col-form-label'>
                  Lead VC2:
                </label>
                <input
                  // required
                  className='form-control'
                  id='lead-vc'
                  name='leadVC'
                  value={media.leadVC2}
                  onChange={(e) => uploadMedia('leadVC2', e.target.value)}
                />
              </div>
            </div>
            <div className='mb-2 row'>
              <div className='col'>
                <label htmlFor='lead-vc-image' className='col-form-label'>
                  Lead VC2 Image:
                </label>

                <input
                  type='file'
                  // ref={projectLogoImageRef}
                  className='form-control form-control-sm'
                  id='lead-vc-image'
                  accept='image/*'
                  onChange={(event) => handelFileInput(event, 'previewImage2')}
                />
                <div style={divStyles}>
                  <button
                    onClick={() =>
                      uploadFunction(selectedFile2, 'leadVCImage2')
                    }
                    disabled={disabled}
                  >
                    upload
                  </button>
                  {name === 'leadVCImage' && <p style={pStyles}>Uploading</p>}
                </div>
                {previewImage2 && (
                  <div
                    className='project-images'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '10px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className='project-logo'>
                      <img
                        src={previewImage2}
                        alt=''
                        height='50px'
                        width='50px'
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '20px' }}>
            <div className='mb-3 row' style={{ width: '50%' }}>
              <div className='col'>
                <label htmlFor='lead-vc' className='col-form-label'>
                  Lead VC3:
                </label>
                <input
                  // required
                  className='form-control'
                  id='lead-vc'
                  name='leadVC'
                  value={media.leadVC3}
                  onChange={(e) => uploadMedia('leadVC3', e.target.value)}
                />
              </div>
            </div>
            <div className='mb-2 row'>
              <div className='col'>
                <label htmlFor='lead-vc-image' className='col-form-label'>
                  Lead VC3 Image:
                </label>

                <input
                  type='file'
                  // ref={projectLogoImageRef}
                  className='form-control form-control-sm'
                  id='lead-vc-image'
                  accept='image/*'
                  onChange={(event) => handelFileInput(event, 'previewImage3')}
                />
                <div style={divStyles}>
                  <button
                    onClick={() =>
                      uploadFunction(selectedFile3, 'leadVCImage3')
                    }
                    disabled={disabled}
                  >
                    upload
                  </button>
                  {name === 'leadVCImage' && <p style={pStyles}>Uploading</p>}
                </div>
                {previewImage3 && (
                  <div
                    className='project-images'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '10px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className='project-logo'>
                      <img
                        src={previewImage3}
                        alt=''
                        height='50px'
                        width='50px'
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='col' style={{ width: '80%', alignItems: 'left' }}>
            <label htmlFor='team' className='col-form-label'>
              Team :
            </label>
            <select
              className='form-select'
              required
              name='Team'
              value={media.Team}
              onChange={(e) => uploadMedia('Team', e.target.value)}
              defaultValue='Public Anonymous'
              id='team'
            >
              <option value=''>SELECT NETWORK</option>
              <option value='Public'>Public</option>
              <option value='Anonymous'>Anonymous</option>
              <option value='NA'>NA</option>
            </select>
          </div>
          <div style={{ display: 'flex', 'margin-top': '2rem' }}>
            <div className='mb-2 row'>
              <div className='col'>
                <div class='form-check form-switch'>
                  <label
                    class='form-check-label tbaLable'
                    for='flexSwitchCheckDefault'
                  >
                    Metalaunch Council Approval :
                  </label>
                  <input
                    class='form-check-input btn-lg'
                    type='checkbox'
                    value={media?.MetalaunchCouncilApproval}
                    checked={media?.MetalaunchCouncilApproval}
                    onChange={() =>
                      uploadMedia(
                        'MetalaunchCouncilApproval',
                        !media.MetalaunchCouncilApproval
                      )
                    }
                    id='flexSwitchCheckDefault'
                  />
                </div>
              </div>
            </div>
            <div className='mb-2 row'>
              <div className='col'>
                <div class='form-check form-switch'>
                  <label
                    class='form-check-label tbaLable'
                    for='flexSwitchCheckDefault'
                  >
                    TokenMetrics :
                  </label>
                  <input
                    class='form-check-input btn-lg'
                    type='checkbox'
                    value={media?.TokenMetrics}
                    checked={media?.TokenMetrics}
                    onChange={() =>
                      uploadMedia('TokenMetrics', !media.TokenMetrics)
                    }
                    id='flexSwitchCheckDefault'
                  />
                </div>
              </div>
            </div>
          </div>
          {buttonDisable && (
            <div className='btn_addnext mt-3'>
              <button
                className='btn btn-success'
                type='submit'
                onClick={() => handleSubmit('notEditDetails')}
              >
                Submit
              </button>
            </div>
          )}
          {editbuttonDisable && (
            <div
              className='btn_addnext mt-3'
              style={{ justifyContent: 'flex-end' }}
            >
              <button
                className='btn btn-success'
                type='submit'
                onClick={() => handleSubmit('editDetails')}
              >
                Update
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MetalaunchScreening;
