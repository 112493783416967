import { useQuery, gql } from "@apollo/client";

export const PROJECT_QUERY = gql`
query projects($pageNo: Int, $perPageItems: Int, $projectStatus: [String], $keyNames: [String], $keyExistType: Boolean, $searchValue: String ) {
   projects(pageNo: $pageNo, perPageItems: $perPageItems, projectStatus: $projectStatus, keyNames: $keyNames, keyExistType: $keyExistType, searchValue: $searchValue){
   pagination {
    totalDocumnets
    pageNo
    perPageItems
  }
  data {
    _id
    projectName
    projectStatus
    typeOfProject
    coingecko{
        coingeckoId
        ath
    }
  }
}
}
`;

export const PROJECT_REMOVE_COINGECKO_ID = gql`
  mutation removeCoingeckoId($_id: String!) {
    removeCoingeckoId(_id: $_id) {
      _id
      coingecko {
        coingeckoId
        ath
      }
    }
  }
`;