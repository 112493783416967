import { indexOf } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getAllClaimById } from '../../config/config';
import axiosHelper from '../../helpers/axios-helper';
import {
  changeClaimDate,
  pauseClaim,
  releaseClaim,
  isClaimPause,
  switchNetwork,
} from '../../helpers/web3-helpers';
import './index.css';
import ViewClaim from './viewClaim';
import { claimLaunch } from '../../config/config';
const DelayClaim = () => {
  const { id } = useParams();
  const [indexOfDate, setIndexOfDate] = useState();
  const [release, setRelease] = useState('');
  const [claimLaunchIdo, setClaimLaunchIdo] = useState();
  const [pause, setPause] = useState(false);
  const [newDate, setNewDate] = useState();
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  async function fetchData() {
    const result = await axiosHelper(
      `${getAllClaimById}${id}`,
      'GET',
      null,
      JSON.stringify({ id: id })
    );
    const { data } = result.data;
    setClaimLaunchIdo(data[0]);
    setNetwork(data[0].projectData[0].projectNetwork.networkId);
    const hex = parseInt(
      data[0].projectData[0].projectNetwork.networkId,
      10
    ).toString(16);
    setChainId(hex);
  }

  const getClaimPaused = async () => {
    let a = await isClaimPause(claimLaunchIdo?.claimContractAddress, chainId);

    setPause(a);
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    getClaimPaused();
  }, [claimLaunchIdo?.claimContractAddress]);
  useEffect(async () => {
    await switchNetwork(network);
    await getClaimPaused();
  }, [network]);

  const toastWrapper = (type, message) => {
    const toastMap = {
      error: toast.error,
      success: toast.success,
      warn: toast.warn,
    };
    return toastMap[type](message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSubmit = async () => {
    if (newDate !== undefined && indexOfDate !== undefined) {
      let index = claimLaunchIdo?.claimDates?.indexOf(parseInt(indexOfDate));
      let unixDate = parseInt(new Date(newDate).getTime() / 1000);
      const contractInstance = await changeClaimDate(
        unixDate,
        index,
        claimLaunchIdo.claimContractAddress,
        chainId
      );
      if (contractInstance) {
        toastWrapper('success', 'Date has been change');
        let arr = await hello();
        const claimLaunchDetails = {
          projectId: id,
          claimDates: arr,
        };
        const result = await axiosHelper(
          `${claimLaunch}`,
          'POST',
          null,
          JSON.stringify(claimLaunchDetails)
        );
        fetchData();
      } else {
        toastWrapper('error', 'error');
      }
    } else {
      toastWrapper('error', 'please fill the Date And Index');
    }
  };
  const handlePause = async () => {
    const contractInstance = await pauseClaim(
      pause,
      claimLaunchIdo.claimContractAddress,
      chainId
    );
    if (contractInstance) {
      toastWrapper('success', 'Pause Done Succesfull');
      await getClaimPaused();
    } else {
      toastWrapper('error', 'error');
    }
  };
  const handleRelease = async () => {
    let index = claimLaunchIdo?.claimDates?.indexOf(parseInt(release));
    if (release !== '') {
      const contractInstance = await releaseClaim(
        index,
        claimLaunchIdo.claimContractAddress,
        chainId
      );
      if (contractInstance) {
        toastWrapper(
          'success',
          `${moment(parseInt(`${release}000`)).format(
            'MMMM Do YYYY, h:mm:ss a'
          )}`
        );
      } else {
        toastWrapper('error', 'error');
      }
    } else {
      toastWrapper('error', 'please fill Index for Release');
    }
  };
  const hello = () => {
    let a = claimLaunchIdo?.claimDates;
    let index = a.indexOf(parseInt(indexOfDate));
    if (index !== -1) {
      a[index] = parseInt(new Date(newDate).getTime() / 1000);
    }
    return a;
  };

  //   let index = claimLaunchIdo?.claimDates?.indexOf(parseInt(indexOfDate));
  return (
    <>
      <ToastContainer />
      <div className='container delayContainer'>
        <div className='m-2 row'>
          <h2 className='text-center'>
            Delay Claim:({claimLaunchIdo?.projectData[0].projectName}{' '}
            {claimLaunchIdo?.projectData[0].typeOfProject})
          </h2>
          <div className='col'>
            <label htmlFor='max-amount-tier-one' className='col-form-label'>
              Token Release Date:
            </label>
            <select
              className='form-select'
              required
              name='networkType'
              value={indexOfDate}
              onChange={(e) => setIndexOfDate(e.target.value)}
              defaultValue='POLYGON TESTNET'
              id='networkTypeSelect'
            >
              <option value=''>Select Date</option>
              {claimLaunchIdo?.claimDates?.map((item, id) => {
                return (
                  <option value={item} key={id + 1}>
                    {moment(parseInt(`${item}000`)).format(
                      'MMMM Do YYYY, h:mm:ss a'
                    )}
                  </option>
                );
              })}
            </select>
          </div>
          <div className='col'>
            <label htmlFor='max-amount-tier-two' className='col-form-label'>
              New Token Release Date:
            </label>
            <input
              required
              type='datetime-local'
              className='form-control'
              id='whitelist-end-date'
              name='whitelistEndDate'
              value={newDate}
              onChange={(e) => setNewDate(e.target.value)}
            />
          </div>
          <div className='mt-3 '>
            <button
              className='btn btn-outline-success '
              onClick={handleSubmit}
              type='button'
            >
              Change Date
            </button>
          </div>
        </div>
        <hr />
        <div className='m-2 row'>
          <h2>Release Claim:</h2>
          <div className='col mb-3'>
            <select
              className='form-select'
              required
              name='networkType'
              value={release}
              onChange={(e) => setRelease(e.target.value)}
              defaultValue=''
              id='networkTypeSelect'
            >
              <option value=''>Select Date</option>
              {claimLaunchIdo?.claimDates?.map((item, id) => {
                return (
                  <option value={item} key={id + 1}>
                    {moment(parseInt(`${item}000`)).format(
                      'MMMM Do YYYY, h:mm:ss a'
                    )}
                  </option>
                );
              })}
            </select>
          </div>
          <div className='col mb-3'>
            <button
              className='btn btn-outline-success '
              onClick={handleRelease}
              type='button'
            >
              Release Claim
            </button>
          </div>
        </div>
        <hr />
        <div className='m-2 row'>
          <h2>Pause Claim</h2>
          <div className='col'>
            <div className='form-check PauseDiv'>
              <input
                type='checkbox'
                value={pause}
                onChange={() => setPause(!pause)}
                checked={pause}
              />
              <label className='m-2' for='flexCheckDefault'>
                yes
              </label>
              <input
                type='checkbox'
                value={!pause}
                onChange={() => setPause(!pause)}
                checked={!pause}
              />
              <label className='m-2' for='flexCheckDefault'>
                no
              </label>
            </div>
            <button
              className='btn btn-outline-success'
              onClick={handlePause}
              type='button'
            >
              Pause Submit
            </button>
          </div>
        </div>
      </div>
      <ViewClaim heading={true} />
    </>
  );
};

export default DelayClaim;
