import React from "react";
import { projectType } from "../../config/config";
import axiosHelper from "../../helpers/axios-helper.js";

const FirstPage = ({ data, update }) => {
  const [projectTypeList, setProjectTypeList] = React.useState([]);

  async function fetchDataProjectType() {
    const resultProjectType = await axiosHelper(projectType, "GET", null, null);
    const { data } = resultProjectType.data;
    setProjectTypeList(data);
    // console.log("ProjectType", data);
  }
  React.useEffect(() => {
    fetchDataProjectType();
  }, []);

  return (
    <>
      <div className="mb-3 row">
        <div className="col">
          <label htmlFor="project-name" className="col-form-label">
            Project Name *:
          </label>
          <input
            type="text"
            required
            name="projectName"
            value={data.projectName}
            onChange={(e) => update("basic", "projectName", e.target.value)}
            className="form-control"
            id="project-name"
          />
          {data.projectName === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="networkTypeSelect" className="col-form-label">
            Network Type *:
          </label>
          <select
            className="form-select"
            required
            name="networkType"
            value={data.networkType}
            onChange={(e) => update("basic", "networkType", e.target.value)}
            defaultValue="POLYGON TESTNET"
            id="networkTypeSelect"
          >
            <option value="">SELECT NETWORK</option>
            <option value="BSC TESTNET">BSC TESTNET</option>
            <option value="POLYGON TESTNET">POLYGON TESTNET</option>
            <option value="AVALANCHE TESTNET">AVALANCHE TESTNET</option>
            <option value="BSC">BSC MAINNET</option>
            <option value="POLYGON">POLYGON MAINNET</option>
            <option value="AVALANCHE">AVALANCHE MAINNET</option>
          </select>
          {data.networkType === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
        {/* <div className="col">
          <label htmlFor="projectStatus" className="col-form-label">
            Currency Decimal:
          </label>
          <select
            className="form-select"
            name="currencyDecimal"
            value={data.currencyDecimal}
            onChange={(e) => update("basic", "currencyDecimal", e.target.value)}
            required
            id="currencyDecimal"
          >
            <option value="18">18</option>
            <option value="6">6</option>
          </select>
          {data.projectStatus === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div> */}
      </div>
      <div className="mb-3 row" style={{ width: "100%" }}>
        <div className="col">
          <label htmlFor="project-description" className="col-form-label">
            Project Description *:
          </label>
          <textarea
            required
            className="form-control"
            id="project-description"
            name="projectDescription"
            value={data.projectDescription}
            onChange={(e) =>
              update("basic", "projectDescription", e.target.value)
            }
          ></textarea>
          {data.projectDescription === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
      </div>

      <div className="mb-3 row">
        <div className="col">
          <label htmlFor="project-one-line" className="col-form-label">
            Project One Line:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="project-One-Liner"
            name="projectOneLiner"
            value={data.projectOneLiner}
            onChange={(e) => update("basic", "projectOneLiner", e.target.value)}
          ></input>
          {data.projectOneLiner === "" ? (
            <p className="nonRequired">Project details in one line</p>
          ) : null}
        </div>
      </div>

      <div className="mb-3 row">
        <div className="col">
          <label htmlFor="projectStatus" className="col-form-label">
            Is Kyc Require *:
          </label>
          <select
            className="form-select"
            name="projectStatus"
            value={data.projectStatus}
            onChange={(e) => update("basic", "projectStatus", e.target.value)}
            required
            id="projectStatus"
          >
            <option value={false}>false</option>
            <option value={true}>true</option>
          </select>
          {data.projectStatus === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
        <div className="col">
          <label htmlFor="project-tags" className="col-form-label">
            Project Tags *:
          </label>
          <input
            type="text"
            required
            className="form-control"
            id="project-tags"
            name="projectTags"
            value={data.projectTags}
            onChange={(e) => update("basic", "projectTags", e.target.value)}
          />
          {data.projectTags === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>

        <div className="col">
          <label htmlFor="projectType" className="col-form-label">
            Type of Project *:
          </label>
          <select
            className="form-select"
            required
            defaultValue="Community IDO"
            id="projectType"
            name="typeOfProject"
            value={data.typeOfProject}
            onChange={(e) => update("basic", "typeOfProject", e.target.value)}
          >
            {/* <option value="Community IDO">Community IDO</option>
            <option value="Public IDO">Public IDO, IGO, IMO</option>
            <option value="Private IDO">Private IDO, IGO, IMO</option> */}

            {projectTypeList?.map((item, id) => {
              return (
                <option value={item.projectType} key={id + 1}>
                  {item.projectType}
                </option>
              );
            })}
          </select>
          {data.typeOfProject === "" ? (
            <p className="validateField">Please provide a valid data</p>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default FirstPage;
