import React from 'react'
import './index.css';
import { Modal, Button } from "react-bootstrap";
import { networkUrl } from "../../config/config";
import axiosHelper from "../../helpers/axios-helper.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Networks() {
    const [networkList, setNetworkList] = React.useState([]);
    const [networkListRaw, setNetworkListRaw] = React.useState([]);
    const [id, setId] = React.useState("");
    const [networkName, setNetworkName] = React.useState("");
    const [networkChainId, setNetworkChainId] = React.useState("");
    const [show, setShow] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [showDeletePopUp, setShowDeletePopUp] = React.useState(false);
    const [selectedNetwork, setSelectedNetwork] = React.useState({});
    async function fetchData() {
        const result = await axiosHelper(
            networkUrl,
            "GET",
            null,
            null
        );
        const { data } = result.data;
        setNetworkList(data);
        setNetworkListRaw(data);
    }
    React.useEffect(() => {
        fetchData()
    }, []);
    const patchValueToForm = (data) => {
        console.log(data)
        setId(data._id)
        setNetworkName(data.name);
        setNetworkChainId(data.chainId);
        setShow(true);
        setIsEdit(true);
    }

    const handleClose = () => {
        setShow(false);
        setNetworkName("");
        setNetworkChainId("");
        setId("");
        setIsEdit(true);
        setShowDeletePopUp(false);
        setSelectedNetwork({});

    };
    const handleShow = () => {
        setShow(true);
        setIsEdit(false)
    };

    const callApi = async (url, method, obj) => {
        try {
            const result = await axiosHelper(
                url,
                method,
                null,
                obj
            );
            handleClose();
            toast.success(`Network successfully ${isEdit ? 'updated' : 'added'} ! `, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            fetchData()
        } catch (error) {
            handleClose();
            toast.error("Something went wrong!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }
    }
    const upsertEntry = async () => {
        if (!networkName.length || !networkName.length) {
            // alert('please fill all mandatory fields...');
            toast.success('Please fill all mandatory fields...', {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        let obj = {
            name: networkName?.toUpperCase(), chainId: networkChainId
        }
        if (isEdit) {
            await callApi(networkUrl + '/' + id, "PUT", obj)
        } else {
            await callApi(networkUrl, "POST", obj)


        }
    }
    const deleteEntry = async () => {
        try {
            const result = await axiosHelper(
                networkUrl + '/' + selectedNetwork._id,
                'DELETE',
                null,
                null
            );
            handleClose();
            toast.success(`Network successfully delete! `, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            fetchData();
        } catch (error) {
            handleClose();
            toast.error("Something went wrong!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }
    }
    const showDeleteEntryPopup = async (networkData) => {
        setShowDeletePopUp(true);
        setSelectedNetwork(networkData);
    }
    const searchFromList = (searchStr) =>{
        const arr = [];
        for(let index=0;index<networkListRaw.length;index++){
            if(JSON.stringify(networkListRaw[index]).toLocaleLowerCase().includes(searchStr.toLocaleLowerCase())){
                arr.push(networkListRaw[index])
            }
        }
        setNetworkList(arr);
    }
    return (
        <>
            <ToastContainer />

            <div className='container-fluid'>
                <div className='row align-items-center shadow-sm p-3 bg-light rounded' >
                    <div className='col-8 bold'>Manage Networks</div>
                    <div className='col-4 text-end'>
                        <div className="row align-items-center  text-end">
                            
                            <div className="col align-items-center text-end">
                                <input type="text" className="form-control" id="search" placeholder="type to search" onChange={e=>{searchFromList(e.target.value)}}/>
                            </div>
                            <div className="col-auto  text-end">
                                <button className="btn btn-primary" onClick={handleShow}>Add Networks</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Update' : 'Add'} Network</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className="col mb-3">
                            <label htmlFor="network" className="form-label">Network Name *</label>
                            <input type="text" className="form-control" id="network" onChange={e => setNetworkName(e.target.value)} value={networkName} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col mb-3">
                            <label htmlFor="networkId" className="form-label">Network Id *</label>
                            <input type="number" className="form-control" id="networkId" onChange={e => setNetworkChainId(e.target.value)} value={networkChainId} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={upsertEntry}>{isEdit ? 'Update' : 'Add'} Network</Button>
                </Modal.Footer>
            </Modal>
              {/* delete contact modal */}
              <Modal
                show={showDeletePopUp}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Contract</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className="col mb-3">
                           <span>Are you sure to delete <b> {`${selectedNetwork.name} - [ ${selectedNetwork.chainId} ]`} </b>? </span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={deleteEntry}>Delete Network</Button>
                </Modal.Footer>
            </Modal>
            {
                networkList.length ?
                    <div className='container-fluid mt-2'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Network Name</th>
                                    <th scope="col">Network Id</th>
                                    <th scope="col">Created Date</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    networkList?.map((entry, id) => {
                                        return (<tr key={id + 1}>
                                            <td>{id + 1}</td>
                                            <td>{entry.name}</td>
                                            <td>{entry.chainId}</td>
                                            <td>{entry.createdAt}</td>
                                            <td>
                                                <button className="btn btn-primary marginRight" onClick={() => patchValueToForm(entry)} >Update</button>
                                                <button className="btn btn-danger marginleft" onClick={() => showDeleteEntryPopup(entry)}>Delete</button>
                                            </td>
                                        </tr>)
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                    (
                        <div className='container-fluid mt-3'>
                            <div className='row text-center'>
                                <div className='col'>
                                    <h4>No Data Available!!!</h4>
                                </div>
                            </div>
                        </div>
                    )
            }

        </>
    )
}


