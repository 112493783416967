import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Input } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function AlertDialog({
  autoAllocation,
  onChangeHandler,
  handleClickOpen,
  open,
  handleClose,
}) {
  return (
    <div className="randomModal">
      <Button variant="outlined" onClick={handleClickOpen}>
        Allocate Tier
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Count"}</DialogTitle>
        <CloseIcon className="closeBtn" onClick={handleClose} autoFocus />
        <DialogContent>
          <Input
            type="number"
            className="CountInput"
            onChange={onChangeHandler}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={autoAllocation} autoFocus>
            Allocate Tier
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
