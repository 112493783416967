import React from "react";
import Spinner from "./spinner.gif";
const Loader = ({ width }) => {
  if (width === undefined) {
    width = "100px";
  }

  return (
    <div className="spinner">
      <img
        src={Spinner}
        alt="Spinner"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: "auto",
          width: `${width}`,
        }}
      />
    </div>
  );
};

export default Loader;
