import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { getProjectById, whitlisted, updateUsers } from '../../config/config';
// import ProjectInfo from "./projectInfo";
import axiosHelper from '../../helpers/axios-helper.js';
import { useParams } from 'react-router-dom';
import { tierPoolIdo } from '../../helpers/web3-helpers';
import './index.css';
const columns = [
  { field: 'id', headerName: '_ID', width: 70, type: 'number' },

  {
    field: 'whitelistedAddressesforTier1',
    filterable: true,
    headerName: 'Wallet Address for Tier 1',
    width: 450,
  },
  {
    field: 'whitelistedAddressesforTier2',
    headerName: 'Wallet Address for Tier 2',
    width: 450,
  },
  {
    field: 'whitelistedAddressesforTier3',
    headerName: 'Wallet Address for Tier 3',
    width: 450,
  },
  {
    field: 'whitelistedAddressesforTier4',
    headerName: 'Wallet Address for Tier 4',
    width: 450,
  },
];

function Whitlisted() {
  const { id } = useParams();
  const [tableData, setTableData] = useState();
  const [projectAdd, setProjectAdd] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [chainId, setChainId] = useState(0);
  const [projectDetails, setProjectDetails] = useState();

  useEffect(() => {
    async function fetchWhitlistedUser() {
      let results = await axiosHelper(`${whitlisted}/${id}`, 'GET', null);
      console.log(results);
      setTableData(results.data.data);
    }
    fetchWhitlistedUser();
    async function fetchDatas() {
      let result = await axiosHelper(
        getProjectById,
        'POST',
        null,
        JSON.stringify({ id: id })
      );
      setProjectDetails({
        name: result.data.data.projectName,
        type: result.data.data.typeOfProject,
      });
      setProjectAdd(result.data.data.projectContractAddress);
      const hex = parseInt(
        result.data.data.projectNetwork.networkId,
        10
      ).toString(16);
      setChainId(hex);
    }
    fetchDatas();
    console.log(tableData);
    console.log(chainId);
  }, []);

  const web3Action = async (data, tierNum) => {
    const listOfAddresses = data?.map((user) => user.walletAddress);
    console.log('hello');
    try {
      const res = await tierPoolIdo(
        listOfAddresses,
        tierNum,
        projectAdd,
        chainId
      );
      if (res) {
        let updateObj = {};
        data.forEach(({ _id }) => {
          updateObj[_id] = {
            isApprovedByContract: 'Confirmed',
          };
        });
        await axiosHelper(updateUsers, 'PUT', null, updateObj);
      }
    } catch (e) {
      console.log(e);
      let updateObj = {};
      data.forEach(({ _id }) => {
        updateObj[_id] = {
          isApprovedByContract: 'Failed',
        };
      });
      await axiosHelper(updateUsers, 'PUT', null, updateObj);
    }

    async function fetchWhitlistedUser() {
      let results = await axiosHelper(`${whitlisted}/${id}`, 'GET', null);
      setTableData(results.data.data);
    }
    fetchWhitlistedUser();
    async function fetchDatas() {
      let result = await axiosHelper(
        getProjectById,
        'POST',
        null,
        JSON.stringify({ id: id })
      );
      setProjectDetails({
        name: result.data.data.projectName,
        type: result.data.data.typeOfProject,
      });
      setProjectAdd(result.data.data.projectContractAddress);
      const hex = parseInt(
        result.data.data.projectNetwork.networkId,
        10
      ).toString(16);
      setChainId(hex);
    }
    fetchDatas();
    console.log(data, tierNum);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  console.log(projectAdd);
  return (
    <div className='projects'>
      {projectDetails?.name !== undefined && (
        <h4 className='text-center m-0'>
          {`${projectDetails.name}(${projectDetails.type})`}{' '}
        </h4>
      )}
      <div className='launch-Ido'>
        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  <TableCell align='center' sx={{ width: '20%' }} colSpan={2}>
                    Tier
                  </TableCell>
                  <TableCell align='center' sx={{ width: '40%' }} colSpan={2}>
                    Wallet Address
                  </TableCell>
                  <TableCell align='center' sx={{ width: '20%' }} colSpan={2}>
                    send
                  </TableCell>
                  <TableCell align='center' sx={{ width: '20%' }} colSpan={2}>
                    status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData &&
                  tableData?.whitelistedAddressesforTier1?.map((data, id) => {
                    return (
                      <TableRow>
                        <TableCell align='center' colSpan={2}>
                          Community
                        </TableCell>
                        <TableCell
                          className='WhitlistedAdd'
                          align='center'
                          colSpan={2}
                        >
                          <div className='walletAddress'>
                            {data &&
                              data.map((value, index) => {
                                return (
                                  <span key={index}>{value.walletAddress}</span>
                                );
                              })}
                          </div>
                        </TableCell>
                        <TableCell align='center' colSpan={2}>
                          <button
                            className='btn btn-primary'
                            onClick={() => web3Action(data, 1)}
                          >
                            send
                          </button>
                        </TableCell>

                        {
                          <TableCell align='center' colSpan={2}>
                            {data[0].isApprovedByContract}
                          </TableCell>
                        }
                      </TableRow>
                    );
                  })}
                {tableData &&
                  tableData?.whitelistedAddressesforTier2?.map((data, id) => {
                    return (
                      <TableRow>
                        <TableCell align='center' colSpan={2}>
                          Tier 1
                        </TableCell>
                        <TableCell
                          className='WhitlistedAdd'
                          align='center'
                          colSpan={2}
                        >
                          <div className='walletAddress'>
                            {data &&
                              data.map((value, index) => {
                                return (
                                  <span key={index}>{value.walletAddress}</span>
                                );
                              })}
                          </div>
                        </TableCell>
                        <TableCell align='center' colSpan={2}>
                          <button
                            className='btn btn-primary'
                            onClick={() => web3Action(data, 2)}
                          >
                            send
                          </button>
                        </TableCell>

                        {
                          <TableCell align='center' colSpan={2}>
                            {data[0].isApprovedByContract}
                          </TableCell>
                        }
                      </TableRow>
                    );
                  })}
                {tableData &&
                  tableData?.whitelistedAddressesforTier3?.map((data, id) => {
                    return (
                      <TableRow>
                        <TableCell align='center' colSpan={2}>
                          Tier 2
                        </TableCell>
                        <TableCell
                          className='WhitlistedAdd'
                          align='center'
                          colSpan={2}
                        >
                          <div className='walletAddress'>
                            {data &&
                              data.map((value, index) => {
                                return (
                                  <span key={index}>{value.walletAddress}</span>
                                );
                              })}
                          </div>
                        </TableCell>
                        <TableCell align='center' colSpan={2}>
                          <button
                            className='btn btn-primary'
                            onClick={() => web3Action(data, 3)}
                          >
                            send
                          </button>
                        </TableCell>
                        {
                          <TableCell align='center' colSpan={2}>
                            {data[0].isApprovedByContract}
                          </TableCell>
                        }
                      </TableRow>
                    );
                  })}
                {tableData &&
                  tableData?.whitelistedAddressesforTier4?.map((data, id) => {
                    return (
                      <TableRow>
                        <TableCell align='center' colSpan={2}>
                          Tier 3
                        </TableCell>
                        <TableCell
                          className='WhitlistedAdd'
                          align='center'
                          colSpan={2}
                        >
                          <div className='walletAddress'>
                            {data &&
                              data.map((value, index) => {
                                return (
                                  <span key={index}>{value.walletAddress}</span>
                                );
                              })}
                          </div>
                        </TableCell>
                        <TableCell align='center' colSpan={2}>
                          <button
                            className='btn btn-primary'
                            onClick={() => web3Action(data, 4)}
                          >
                            send
                          </button>
                        </TableCell>
                        {
                          <TableCell align='center' colSpan={2}>
                            {data[0].isApprovedByContract}
                          </TableCell>
                        }
                      </TableRow>
                    );
                  })}
                {tableData &&
                  tableData?.whitelistedAddressesforTier5?.map((data, id) => {
                    return (
                      <TableRow>
                        <TableCell align='center' colSpan={2}>
                          Tier 4
                        </TableCell>
                        <TableCell
                          className='WhitlistedAdd'
                          align='center'
                          colSpan={2}
                        >
                          <div className='walletAddress'>
                            {data &&
                              data.map((value, index) => {
                                return (
                                  <span key={index}>{value.walletAddress}</span>
                                );
                              })}
                          </div>
                        </TableCell>
                        <TableCell align='center' colSpan={2}>
                          <button
                            className='btn btn-primary'
                            onClick={() => web3Action(data, 5)}
                          >
                            send
                          </button>
                        </TableCell>
                        {
                          <TableCell align='center' colSpan={2}>
                            {data[0].isApprovedByContract}
                          </TableCell>
                        }
                      </TableRow>
                    );
                  })}
                {tableData &&
                  tableData?.whitelistedAddressesforTier6?.map((data, id) => {
                    return (
                      <TableRow>
                        <TableCell align='center' colSpan={2}>
                          Tier 5
                        </TableCell>
                        <TableCell
                          className='WhitlistedAdd'
                          align='center'
                          colSpan={2}
                        >
                          <div className='walletAddress'>
                            {data &&
                              data.map((value, index) => {
                                return (
                                  <span key={index}>{value.walletAddress}</span>
                                );
                              })}
                          </div>
                        </TableCell>
                        <TableCell align='center' colSpan={2}>
                          <button
                            className='btn btn-primary'
                            onClick={() => web3Action(data, 6)}
                          >
                            send
                          </button>
                        </TableCell>

                        {
                          <TableCell align='center' colSpan={2}>
                            {data[0].isApprovedByContract}
                          </TableCell>
                        }
                      </TableRow>
                    );
                  })}
                {tableData &&
                  tableData?.whitelistedAddressesforTier7?.map((data, id) => {
                    return (
                      <TableRow>
                        <TableCell align='center' colSpan={2}>
                          Tier 6
                        </TableCell>
                        <TableCell
                          className='WhitlistedAdd'
                          align='center'
                          colSpan={2}
                        >
                          <div className='walletAddress'>
                            {data &&
                              data.map((value, index) => {
                                return (
                                  <span key={index}>{value.walletAddress}</span>
                                );
                              })}
                          </div>
                        </TableCell>
                        <TableCell align='center' colSpan={2}>
                          <button
                            className='btn btn-primary'
                            onClick={() => web3Action(data, 7)}
                          >
                            send
                          </button>
                        </TableCell>

                        {
                          <TableCell align='center' colSpan={2}>
                            {data[0].isApprovedByContract}
                          </TableCell>
                        }
                      </TableRow>
                    );
                  })}
                {tableData &&
                  tableData?.whitelistedAddressesforTier8?.map((data, id) => {
                    return (
                      <TableRow>
                        <TableCell align='center' colSpan={2}>
                          Tier 7
                        </TableCell>
                        <TableCell
                          className='WhitlistedAdd'
                          align='center'
                          colSpan={2}
                        >
                          <div className='walletAddress'>
                            {data &&
                              data.map((value, index) => {
                                return (
                                  <span key={index}>{value.walletAddress}</span>
                                );
                              })}
                          </div>
                        </TableCell>
                        <TableCell align='center' colSpan={2}>
                          <button
                            className='btn btn-primary'
                            onClick={() => web3Action(data, 8)}
                          >
                            send
                          </button>
                        </TableCell>

                        {
                          <TableCell align='center' colSpan={2}>
                            {data[0].isApprovedByContract}
                          </TableCell>
                        }
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            //   count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
}
export default Whitlisted;
