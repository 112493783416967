// export const BASE_URL = 'http://35.175.46.153:3000';

const getBackendUrl = (env_var) => {
  console.log('env_var ', env_var);
  if (env_var.toUpperCase().trim() === 'LOCAL') {
    return 'http://dev-admin-api.metalaunch.io';
  } else if (env_var.toUpperCase().trim() === 'DEV') {
    return 'http://dev-admin-api.metalaunch.io';
  } else if (env_var.toUpperCase().trim() === 'QA') {
    return 'http://qa-admin-api.metalaunch.io';
  } else if (env_var.toUpperCase().trim() === 'PROD') {
    return 'https://api.metalaunch.io';
  }
};
export const BASE_URL = getBackendUrl(process.env.REACT_APP_ENV);
// Auth Request
export const login = 'auth/login';
export const register = 'auth/register';
export const logout = 'auth/logout';
export const verifyToken = 'auth/verify-token';

//Post Request
export const registration = 'projects/admin/registration';
export const verifyProject = 'projects/admin/verifyProject';
export const isUpcomingDisabled = 'projects/admin/setUpcomingStatus';
export const editProject = 'projects/admin/editProject';
export const addIDOToLaunch = 'idos/admin/addLaunchedIDO';
export const getUsersById = 'users/getUsersByProjectId';
export const verifyUser = 'users/verifyUser';
export const bulkRegistration = 'users/bulkRegistration';
export const updateProjectContractAddress =
  'projects/admin/updateProjectContractAddress';
export const claimAddress = 'projects/admin/updateClaimAddress';
export const updateBulkCoingeckoId = 'projects/admin/updateBulkCoingeckoId';
export const refreshATHCoinsUrl = 'projects/admin/refresh-ath';
export const upload = 'upload';
export const projectDetails = 'project-details';
export const tokenomics = 'v2/tokenomics';
export const metalaunchScreening = 'v2/metalaunch-screening';
export const claimLaunch = 'claim-launched';

//Get Request;
export const getAllProjects = 'projects/admin/getAllProjects';
export const getAllClaim = 'claim-launched';
export const getAllClaimById = 'claim-launched/';
export const getProjectById = 'projects/admin/getProjectById';
export const getAllIDOs = 'idos/admin/getAllIDOs';
export const whitlisted = 'users/getWhitelistedUsersPerTier';
export const adminUsers = 'admin-users';
export const contract = 'contract';
export const networkUrl = 'network';
export const stakePool = 'stake-pool';
export const projectType = 'project-type';
export const saleType = 'sale-type';
// Put Request
export const updateUsers = 'users/updateUsers';
export const EditAdminUser = 'admin-users';
//update request
export const updatePass = 'admin-users/update-password';
// feature projects
export const featuredProjectUrl = 'featured-projects';

//openApi
export const asvaPrice =
  'https://api.coingecko.com/api/v3/simple/price?ids=asva&vs_currencies=usd';

export const COINGECKO_URL = 'https://api.coingecko.com/api/v3/coins/list';
