import Web3 from 'web3';
import AsvaPoolFactory from './abi/AsvaPoolFactory.json';
import ClaimFactory from './abi/ClaimFactory.json';
import TierIDOPool from './abi/TierIDOPool.json';
import LpStakingAbi from './abi/LpStakeAbi.json';
import addresses from './contracts';
import StakingDetails from './abi/stakingVaultAggregator.json';
import Claim from './abi/currencyClaim.json';
import {
  updateProjectContractAddress,
  claimAddress,
  claimLaunch,
} from '../config/config';
import axiosHelper from './axios-helper';
import { toast } from 'react-toastify';
let { ethereum } = window;
let web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');

export async function initiateConnect() {
  if (ethereum && ethereum.metamask) {
    ethereum
      .on('accountsChanged', async () => {
        fetchAccountDetails();
      })
      .on('chainChanged', () => {
        fetchAccountDetails();
      });
  }
  return fetchAccountDetails();
}
function convertDecimalToHexChainID(number) {
  // convertDecimalToHex
  if (typeof number !== 'number') number = parseInt(number);

  let hex = number.toString(16);
  // returning the chainID
  return '0x' + hex;
}
export const switchNetwork = async (network) => {
  network = convertDecimalToHexChainID(network);
  return new Promise((resolve, reject) => {
    window.ethereum
      .request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network }],
      })
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        reject(false);
      });
  });

  // console.log(status);
};
async function fetchAccountDetails() {
  return web3.eth
    .requestAccounts()
    .then(async (res) => {
      if (res.length < 1) {
        throw new Error('Connection To Metamask Failed');
      }
      const details = {
        account: res[0],
        balance: await web3.eth.getBalance(res[0]),
        network: await web3.eth.net.getNetworkType(),
        chainId: await web3.eth.getChainId(),
      };
      // console.log(details);
      return {
        error: false,
        details,
        message: 'Connection to Metamask Successful',
      };
    })
    .catch((err) => console.log(err));
}

export const getAsvaPoolFactoryAddress = (chainId) => {
  return addresses.AsvaPoolFactory[chainId];
};
export const getAsvaClaimFactoryAddress = (chainId) => {
  return addresses.ClaimFactory[chainId];
};
const createContract = (abi, address) => {
  const contract = new web3.eth.Contract(abi, address);
  return contract;
};

export async function createAsvaContract(contractOptions, chainIds) {
  await ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: `0x${chainIds}` }],
  });
  let wallet = await initiateConnect();
  const { abi } = AsvaPoolFactory;

  let contractInstance = createContract(
    abi,
    getAsvaPoolFactoryAddress(wallet?.details?.chainId)
  );
  return await contractInstance.methods
    .createPoolPublic(contractOptions)
    .send({
      from: wallet?.details?.account,
      gas: 8000000,
    })
    .on('error', (error) => {
      console.log('error', error);
    })
    .on('transactionHash', (data) => {
      console.log('transactionHash', data);
      console.log(contractOptions, chainIds);
    })
    .on('receipt', async (data) => {
      console.log('receipt', data);
      // data.events.PoolCreated.returnValues.pool  === save this newly created address in the dp for current project contract address
      if (data.status) {
        const tempData = {
          projectContractAddress: data.events.PoolCreated.returnValues.pool,
          id: parseInt(data.events.PoolCreated.returnValues.presaleDbID),
        };
        console.log('in data', tempData);
        const res = await axiosHelper(
          `${updateProjectContractAddress}`,
          'POST',
          null,
          JSON.stringify(tempData)
        );
        return res;
      }
    });
}
export async function createClaimContract(contractOptions, chainIds) {
  console.log(contractOptions.idoId);
  console.log(contractOptions.contract);
  console.log(contractOptions.token);
  console.log(contractOptions.times);
  console.log(contractOptions.percents);
  console.log(chainIds);
  await ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: `0x${chainIds}` }],
  });
  let wallet = await initiateConnect();
  const { abi } = ClaimFactory;
  let contractInstance = createContract(
    abi,
    getAsvaClaimFactoryAddress(wallet.details.chainId)
  );
  return await contractInstance.methods
    .createClaimerForPool(
      contractOptions.idoId,
      contractOptions.contract,
      contractOptions.token,
      contractOptions.times,
      contractOptions.percents

      // "1648615486245",
      // "0x8A892084e8C6Dc083c96630D793f40f1703C2627",
      // "0x341d6E99798c97B646799711EF361b030d4be661",
      // [25000, 25000, 25000, 2500],
      // [25000, 25000, 25000, 25000]
    )

    .send({
      from: wallet.details.account,
      gas: 8000000,
    })
    .on('error', (error) => {
      console.log('error', error);
    })
    .on('transactionHash', (data) => {
      console.log('transactionHash', data);
      console.log(contractOptions, chainIds);
    })
    .on('receipt', async (data) => {
      console.log('receipt', data.events.OwnershipTransferred[0].address);
      console.log('receipt', data);
      if (data.status) {
        const tempData = {
          claimAddress: data.events.OwnershipTransferred[0].address,
          id: parseInt(contractOptions.idoId),
        };
        console.log('in data', tempData);
        const res = await axiosHelper(
          `${claimAddress}`,
          'POST',
          null,
          JSON.stringify(tempData)
        );
        const claimLaunchDetails = {
          claimContractAddress: data.events.OwnershipTransferred[0].address,
          projectId: parseInt(contractOptions.idoId),
          projectContractAddress: contractOptions.contract,
          tokenContractAddress: contractOptions.token,
          claimDates: contractOptions.times,
          claimPercentage: contractOptions.percents,
        };
        console.log('in claimLaunch', claimLaunchDetails);
        const result = await axiosHelper(
          `${claimLaunch}`,
          'POST',
          null,
          JSON.stringify(claimLaunchDetails)
        );
        return res, result;
      }
    });
}
export async function changeClaimDate(
  date,
  claimIndex,
  claimContractAddress,
  chainIds
) {
  await ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: `0x${chainIds}` }],
  });
  let wallet = await initiateConnect();
  const { abi } = Claim;
  let contractInstance = createContract(abi, claimContractAddress);
  return await contractInstance.methods
    .delayClaim(claimIndex, date)
    .send({
      from: wallet.details.account,
      gas: 8000000,
    })
    .on('error', (error) => {
      console.log('error', error);
    })
    .on('transactionHash', (data) => {
      console.log('transactionHash', data);
    })
    .on('receipt', async (data) => {
      console.log('receipt', data);
    });
}
export async function releaseClaim(claimIndex, claimContractAddress, chainIds) {
  await ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: `0x${chainIds}` }],
  });
  let wallet = await initiateConnect();
  const { abi } = Claim;
  let contractInstance = createContract(abi, claimContractAddress);
  return await contractInstance.methods
    .releaseClaim(claimIndex)
    .send({
      from: wallet.details.account,
      gas: 8000000,
    })
    .on('error', (error) => {
      console.log('error', error);
    })
    .on('transactionHash', (data) => {
      console.log('transactionHash', data);
    })
    .on('receipt', async (data) => {
      console.log('receipt', data);
    });
}
export async function isClaimPause(contractAddress) {
  const { abi } = Claim;
  try {
    const contract = new web3.eth.Contract(abi, contractAddress);

    let pauseVal = await contract.methods.isPaused().call();
    return pauseVal;
  } catch (error) {
    console.log('getTokenInfo', error);
  }
}
export async function pauseClaim(bool, claimContractAddress, chainIds) {
  await ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: `0x${chainIds}` }],
  });
  let wallet = await initiateConnect();
  const { abi } = Claim;
  let contractInstance = createContract(abi, claimContractAddress);
  return await contractInstance.methods
    .pauseClaiming(bool)
    .send({
      from: wallet.details.account,
      gas: 8000000,
    })
    .on('error', (error) => {
      console.log('error', error);
    })
    .on('transactionHash', (data) => {
      console.log('transactionHash', data);
    })
    .on('receipt', async (data) => {
      console.log('receipt', data);
    });
}

export async function tierPoolIdo(
  arrWhitlistedAdd,
  tierNumber,
  projectAdd,
  chainIds
) {
  console.log(chainIds);
  console.log(tierNumber);
  await ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: `0x${chainIds}` }],
  });
  let wallet = await initiateConnect();
  const { abi } = TierIDOPool;

  let contractInstance = createContract(abi, projectAdd);
  return await contractInstance.methods
    .addToPoolWhiteList(arrWhitlistedAdd, tierNumber)
    .send({
      from: wallet?.details?.account,
      gas: 5000000,
    })
    .on('error', (error) => {
      console.log('error', error);
      return error;
    })
    .on('transactionHash', (data) => {
      console.log('transactionHash', data);
    })
    .on('receipt', async (data) => {
      console.log('receipt', data);
    });
}
// console.log(Curr);
let currAddress = '0xCe3aF02606727a773C618cb28dFD5E585f2E5113';
// console.log(currAddress);
export async function getTokenInfo(currencyAbi, currencyAdd) {
  try {
    // let contractInstance = new web3.eth.Contract(Curr, currAddress);
    // console.log(contractInstance);
    const contract = new web3.eth.Contract(currencyAbi, currencyAdd);
    const symbol = await contract.methods.symbol().call();
    const decimals = await contract.methods.decimals().call();
    // console.log(contract);
    console.log(decimals);
    return decimals;
  } catch (error) {
    console.log('getTokenInfo', error);
  }
}
export const checkWalletAddress = (address) => web3.utils.isAddress(address);

//Staking Functions

export async function getAsvaStakingDetails(walletAddress, chainIds) {
  const { abi } = StakingDetails;
  let contractAddress = '';
  let web3 = '';
  if (chainIds === '38' || chainIds === '89' || chainIds === 'a86a') {
    web3 = new Web3(
      'https://speedy-nodes-nyc.moralis.io/76c47222b0bcc264aeb78f88/bsc/mainnet'
    );
    contractAddress = '0x806C45dBa6E7069E805723A1E8fB23669A3860E2';
  } else {
    web3 = new Web3(
      'https://speedy-nodes-nyc.moralis.io/31649378acd900255d51e632/bsc/testnet'
    );
    contractAddress = '0x081e9f5b2EDF9A7E626a641c116bA2352EdfB027';
  }

  try {
    const contract = new web3.eth.Contract(abi, contractAddress);

    let stakeInfo = await contract.methods.alluserinfo(walletAddress).call();
    return stakeInfo;
  } catch (error) {
    console.log('getTokenInfo', error);
  }
}
export async function getLPStakedetails() {
  const { abi } = LpStakingAbi;
  let contractAddress = '0x583c2a4226a3b9abb87e658d1808926a73647768';
  let web3 = new Web3(
    'https://speedy-nodes-nyc.moralis.io/76c47222b0bcc264aeb78f88/bsc/mainnet'
  );
  try {
    const contract = new web3.eth.Contract(abi, contractAddress);
    let totalSupply = await contract.methods.totalSupply().call();
    let reserves = await contract.methods.getReserves().call();
    return { totalSupply, reserves };
  } catch (error) {
    console.log(error);
  }
}
